import React, { useState, useRef, useEffect } from "react";
import ReactHtmlParser from "react-html-parser";
import CanDetailCircularBar from "../CanDetailCircularBar/CanDetailCircularBar";
// import FeedBackModal from "../../../components/Common/FeedBackModal/FeedBackModal";
import CanDetailSkill from "../CanDetailSkill/CanDetailSkill";

import FeedBackModal from "../../../Common/FeedbackModal/FeedBackModal";
import APIPvtDB from "../../../../apiPvtD";
import { EmptyRecord } from "../../../../constants";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import "./CanDetailEval.css";
import { getColor } from "../../../../utils/progressbarFn";

const CanDetailEval = ({
  showFeeddBack,
  candidateData,
  candidateDetailData,
  jobDetailData,
}) => {
  const totalExperience = parseFloat(candidateDetailData?.overall_experience);
  const [canEvalTab, setCanEvalTab] = useState("overall-match");
  const [toggleFeedback, setToggleFeedback] = useState(false);
  const [analysisSkills, setAnalysisSkills] = useState(
    candidateData?.analysis_skills
  );

  const [defaultFeedbackValues, setDefaultFeedbackValues] = useState([]);

  const overAllMatchRef = useRef(null);
  const expRef = useRef(null);
  const skillRef = useRef(null);
  const eduRef = useRef(null);
  const jobSummaryRef = useRef(null);

  const scrollToRef = async (ref) => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const overAllMatchRefClick = async () => {
    await scrollToRef(overAllMatchRef);
    await scrollToRef(overAllMatchRef);
  };

  const expRefClick = async () => {
    await scrollToRef(expRef);
    await scrollToRef(expRef);
  };

  const skillRefClick = async () => {
    await scrollToRef(skillRef);
    await scrollToRef(skillRef);
  };

  const eduRefClick = async () => {
    await scrollToRef(eduRef);
    await scrollToRef(eduRef);
  };

  const jobSummaryRefClick = async () => {
    await scrollToRef(jobSummaryRef);
    await scrollToRef(jobSummaryRef);
  };

  const modelFeedbackHandler = (v) => setToggleFeedback(v);

  const handleOptionChange = (event) => {
    if (event.target.value === "dislike") {
      APIPvtDB.get(`/candidate-feedback/thumbs-down-data`).then((val) => {
        setDefaultFeedbackValues(val?.data?.data);
      });
      modelFeedbackHandler(true);
    }

    if (event.target.value === "like") {
      APIPvtDB.post(
        `/candidate-feedback/thumbs-up?application_id=${candidateDetailData?.application_id}`
      ).then((val) => {
        console.log(val.data.message);
        if (val?.data.success === true) {
          toast.success(val?.data.message, {
            autoClose: 2000,
            closeOnClick: false,
          });
        } else {
          toast.error(val?.data.message, {
            autoClose: 2000,
            closeOnClick: false,
          });
        }
      });
    }
  };
  return (
    <div className="tab-content ex1-content" id="ex1-content">
      <div
        className="tab-pane fade show active"
        id="ex1-tabs-1"
        role="tabpanel"
        aria-labelledby="ex1-tab-1"
      >
        <div className="tabs-content-jour">
          <p className="content-heading-jour">Candidate evaluation</p>
          <div className="left-right-content-jour">
            <div className="left-col-vertical">
              <a className="col-all-link-tb" onClick={overAllMatchRefClick}>
                <div
                  className={`overall-col icon-cursor ${canEvalTab === "overall-match" && "ative-all-five-col"
                    }`}
                  onClick={() => setCanEvalTab("overall-match")}
                >
                  <div className="experience-content-tabs">
                    <CanDetailCircularBar
                      colorBar={getColor(candidateDetailData?.overall_match)}
                      perValue={candidateDetailData?.overall_match}
                    />
                    <div className="heading-text-tabs">
                      <p className="heading-main-jour over-all-clr">
                        Overall AI match
                      </p>
                      <p className="content-main-jour">
                        Overall AI match based on the resume
                      </p>
                    </div>
                  </div>
                </div>
              </a>
              <a className="col-all-link-tb" onClick={expRefClick}>
                <div
                  className={`experience-col icon-cursor ${canEvalTab === "experience" && "ative-all-exper-col"
                    }`}
                  onClick={() => setCanEvalTab("experience")}
                >
                  <div className="experience-content-tabs">
                    <CanDetailCircularBar
                      colorBar={"#435B9C"}
                      perValue={candidateDetailData?.experience_score}
                    />
                    <div className="heading-text-tabs">
                      <p className="heading-main-jour">Experience</p>
                      <p className="content-main-jour">
                        {!EmptyRecord.includes(candidateDetailData?.overall_experience) &&
                          `${Number?.isInteger(totalExperience)
                            ? totalExperience?.toString()
                            : totalExperience?.toFixed(1)
                          } Years of experience`}{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </a>
              <a className="col-all-link-tb" onClick={skillRefClick}>
                <div
                  className={`skills-col icon-cursor ${canEvalTab === "Skills" && "ative-all-skill-col"
                    }`}
                  onClick={() => setCanEvalTab("Skills")}
                >
                  <div className="experience-content-tabs">
                    <CanDetailCircularBar
                      colorBar={"#008E9B"}
                      perValue={candidateDetailData?.key_skills_score}
                    />
                    <div className="heading-text-tabs">
                      <p className="heading-main-jour skill-clr">Skills</p>
                      <p className="content-main-jour">
                        {jobDetailData?.full_title  || candidateData?.currentJobTitle &&
                          `Skills matched as a ${jobDetailData?.full_title ? jobDetailData?.full_title : candidateData?.currentJobTitle}`}
                      </p>
                    </div>
                  </div>
                </div>
              </a>
              <a className="col-all-link-tb" onClick={eduRefClick}>
                <div
                  className={`education-col icon-cursor ${canEvalTab === "education" && "ative-all-education-col"
                    }`}
                  onClick={() => setCanEvalTab("education")}
                >
                  <div className="experience-content-tabs">
                    <CanDetailCircularBar
                      colorBar={"#04BFDA"}
                      perValue={candidateDetailData?.education_score}
                    />
                    <div className="heading-text-tabs">
                      <p className="heading-main-jour edu-clr">Education</p>
                      <p className="content-main-jour">
                        {!EmptyRecord.includes(candidateDetailData?.last_degree) &&
                          `${candidateDetailData?.last_degree}`}
                      </p>
                    </div>
                  </div>
                </div>
              </a>
            </div>

            <div className="right-content-col-jour">
              {canEvalTab === "overall-match" && (
                <div className="" ref={overAllMatchRef}>
                  <p className="right-col-heading">Overall AI match</p>

                  {candidateDetailData?.recommendation !== null && (
                    <>
                      <p className="right-col-sub-heading">
                        Overall assessment
                      </p>
                      <p className="right-col-jour-sub-content">
                        {candidateDetailData?.recommendation}
                      </p>
                    </>
                  )}

                  {candidateDetailData?.key_accomplishments !== null && (
                    <>
                      <p className="right-col-sub-heading">
                        Key accomplishments
                      </p>
                      <p className="right-col-jour-sub-content">
                        {candidateDetailData?.key_accomplishments}
                      </p>
                    </>
                  )}

                  {candidateDetailData?.job_progression !== null && (
                    <>
                      <p className="right-col-sub-heading">Job progression</p>
                      <p className="right-col-jour-sub-content">
                        {candidateDetailData?.job_progression}
                      </p>
                    </>
                  )}
                </div>
              )}

              {canEvalTab === "experience" && (
                <div className="" ref={expRef}>
                  <p className="right-col-heading experience-heading-color">
                    Experience
                  </p>
                  {candidateDetailData?.relevant_experience !== null && (
                    <p className="right-col-jour-sub-content">
                      {candidateDetailData?.relevant_experience}
                    </p>
                  )}
                </div>
              )}

              {canEvalTab === "Skills" && (
                <div className="" ref={skillRef}>
                  <p className="right-col-heading skill-heading-color">
                    Skills
                  </p>
                  {candidateDetailData?.skill_assessment !== null && (
                    <>
                      <p className="right-col-sub-heading">Skills assessment</p>
                      <p className="right-col-jour-sub-content">
                        {candidateDetailData?.skill_assessment}
                      </p>
                    </>
                  )}
                  {analysisSkills &&
                    analysisSkills?.technical_skills?.length > 0 && (
                      <CanDetailSkill
                        parentID="accordionFlushExample1"
                        mainHeading="Technical skills"
                        targetId="flush-collapseOne"
                        enableProficiencyLevel={true}
                        skillData={analysisSkills?.technical_skills}
                      />
                    )}

                  {analysisSkills &&
                    analysisSkills?.industry_specific_skills?.length > 0 && (
                      <CanDetailSkill
                        parentID="accordionFlushExample3"
                        mainHeading="Industry specific skills"
                        targetId="flush-collapseThree"
                        enableProficiencyLevel={true}
                        skillData={analysisSkills?.industry_specific_skills}
                      />
                    )}

                  {analysisSkills &&
                    analysisSkills?.soft_skills?.length > 0 && (
                      <CanDetailSkill
                        parentID="accordionFlushExample2"
                        mainHeading="Soft skills"
                        targetId="flush-collapseTwo"
                        enableProficiencyLevel={false}
                        skillData={analysisSkills?.soft_skills}
                      />
                    )}
                </div>
              )}
              {canEvalTab === "education" && (
                <div className="" ref={eduRef}>
                  <p className="right-col-heading education-heading-color">
                    Education
                  </p>

                  {candidateDetailData?.education_assessment !== null && (
                    <p className="right-col-jour-sub-content">
                      {candidateDetailData?.education_assessment}
                    </p>
                  )}
                </div>
              )}

              {showFeeddBack && (
                <div className="feedback-resume">
                  <h3>Give us your feedback</h3>
                  <div className="feedback-options">
                    <div className="radio-container-eval">
                      <input
                        defaultChecked={
                          candidateDetailData?.is_thumbs_up
                            ? candidateDetailData?.is_thumbs_up
                            : false
                        }
                        type="radio"
                        id="radio1"
                        name="radios"
                        value="like"
                        className="hidden-radio"
                        onChange={handleOptionChange}
                      />
                      <label for="radio1" className="radio-label">
                        <img src="/image/thumbs.png" alt="Image 1" />
                      </label>
                    </div>

                    <div className="radio-container-eval">
                      <input
                        defaultChecked={
                          candidateDetailData?.is_thumbs_down
                            ? candidateDetailData?.is_thumbs_down
                            : false
                        }
                        type="radio"
                        id="radio2"
                        name="radios"
                        value="dislike"
                        className="hidden-radio"
                        onChange={handleOptionChange}
                      />
                      <label for="radio2" className="radio-label">
                        <img
                          className="img-dislike"
                          src="/image/thumbs.png"
                          alt="Image 2"
                        />
                      </label>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {toggleFeedback && (
        <FeedBackModal
          modelHandler={modelFeedbackHandler}
          defaultValues={defaultFeedbackValues}
          canDetail={candidateDetailData}
        />
      )}
    </div>
  );
};

export default CanDetailEval;
