import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux';
import OutsideClickHandler from "react-outside-click-handler";
import { useFormik } from "formik";
import * as Yup from "yup";
import capitalizeFirstLetter from "../../../utils/capitalizeFirstLetter";
import API from "../../../api";
import { useSelector } from "react-redux";
import { BigPlayButton, Player } from 'video-react';
import { formatSalary } from "../../../utils/formatSalary";
import formatFileSize from '../../../utils/formatFileSize';
import { nameRegex, numericWithCommaAndDecimal } from '../../../constants';
import { getJobTypesDataAsync } from '../../../redux/slices/JobSearchSlice';

const ProfileSection = ({ submitHandler, loader, baseInfo, jobTitles, tags, salaryRange, workPreferences }) => {
  const dispatch = useDispatch();
  const [editable, setEditable] = useState(false);

  const { user } = useSelector((state) => state.auth);
  const [selectSkillTags, setSelectSkillTags] = useState([]);
  const [allSkillTagsHide, setAllSkillTagsHide] = useState(true);
  const [allWorkPreferenceTagsHide, setAllWorkPreferenceTagsHide] = useState(true);
  const [currencyOption, setCurrencyOption] = useState([]);
  const [salaryRangeOption, setSalaryRangeOption] = useState([]);
  const [allWorkPreference, setAllWorkPreference] = useState([]);
  const [selectWorkPreferenceTags, setSelectWorkPreferenceTags] = useState([]);
  const [allJobTitlesHide, setAllJobTitlesHide] = useState(true);
  const workPreferenceArray = [];
  const [allJobTitles, setAllTobTitles] = useState([]);
  const [allSkillTags, setAllSkillTags] = useState([]);
  const [selectJobTitles, setSelectJobTitles] = useState([]);

  useEffect(() => {
    if (baseInfo?.workPreferences?.length > 0) {
      setSelectWorkPreferenceTags(baseInfo?.workPreferences)
    }
    setSelectJobTitles(jobTitles);
    setSelectSkillTags(tags);
    getWorkPreferences();
    getJobDropDownData();

    formik.setValues({
      firstName: baseInfo?.first_name || "",
      lastName: baseInfo?.last_name || "",
      countryId: baseInfo?.countryId || null,
      country: baseInfo?.country || "",
      cityId: baseInfo?.cityId || null,
      city: baseInfo?.city || "",
      stateId: baseInfo?.stateId || null,
      state: baseInfo?.state || "",
      email: baseInfo?.email || "",
      phoneNo: baseInfo?.phone || "",
      linkedinURL: baseInfo?.linkedin || "",
      aboutSelf: baseInfo?.bio || "",
      interested: baseInfo?.my_interests || "",
      selectedVideoName: baseInfo?.selectedVideoName || "",
      fileSize: baseInfo?.fileSize || null,
      salaryExpectation: baseInfo?.expected_salary || 0,
      userWorkPreferences: baseInfo?.workPreferences,
      currency: baseInfo?.currency || "",
      currencyId: baseInfo?.currencyId || null,
      resume: baseInfo?.file,
      salaryRange: baseInfo?.salary_range ? baseInfo?.salary_range : "year",
    });
  }, [baseInfo]);

  const getJobDropDownData = () => {
    // if (user !== null) {}
    const currencies = [{
      id: 1,
      country
        :
        null,
      flag_link
        :
        "https://demo.workbridgetalent.ca/uploads/flags/macedonia.png",
      symbol
        :
        "Ден",
      title
        :
        "MKD"
    }]
    const salaryRange = [
      {
        label
          :
          "Year",
        page
          :
          "",
        value
          :
          "year"
      },
      {
        label
          :
          "Month",
        page
          :
          "",
        value
          :
          "month"
      },
      {
        label
          :
          "Hour",
        page
          :
          "",
        value
          :
          "hour"
      }
    ]




    setSalaryRangeOption(salaryRange);

  }
  const getWorkPreferences = async () => {
    try {
      dispatch(getJobTypesDataAsync())
        .then((response) => {
          const res = response?.payload;
          if (Array.isArray(res)) {
            setAllWorkPreference(res); 
          }  
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const isFileFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFileErrorMessage = (name) => {
    return (
      isFileFieldValid(name) && (
        <p className="file-error-msg">{formik.errors[name]}</p>
      )
    );
  };

  const handleVideoChange = async (event) => {
    console.log("event", event);
    console.log("handleVideoChange");
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        formik.setFieldValue("selectedVideoName", file.name);  
        formik.setFieldValue("fileSize", file.size);  
        formik.setFieldValue("introVideo", file);
      };
      reader.readAsDataURL(file);
    } else {   
      formik.setFieldValue("selectedVideoName", "");  
      formik.setFieldValue("fileSize", null);  
      formik.setFieldValue("introVideo", null);
    }
  };

  const formik = useFormik({
    initialValues: {
      file: null,
      firstName: "",
      lastName: "",
      country: "",
      countryId: null,
      state: "",
      stateId: null,
      city: "",
      cityId: null,
      email: "",
      phoneNo: "",
      linkedinURL: "",
      aboutSelf: "",
      interested: "",
      resume: "",
      introVideo: null,
      selectedVideoName: "",
      fileSize: null,
      jobTitle: "",
      skillTags: "",
      salaryRange: "year",
      salaryExpectation: "",
      userWorkPreferences: [],
      currency: "",
      currencyId: null,
      workPreference: "",
    },
    /*validationscheme*/
    validationSchema: Yup.object({
      firstName: Yup.string()
        .matches(nameRegex, "First name should be an alphabet")
        .max(50, "First name cannot exceed 50 characters")
        .required("Please enter first name"),
      lastName: Yup.string()
        .matches(nameRegex, "Last name should be an alphabet")
        .max(50, "Last name cannot exceed 50 characters")
        .required("Please enter last name"),
      country: Yup.string().required("Please select the country"),
      state: Yup.string(),
      city: Yup.string(),
      phoneNo: Yup.string(),
      files: Yup.mixed()
        .test(
          "fileType",
          "File can be a JPEG or PNG document",
          (value) => !value || ["image/png", "image/jpeg"].includes(value?.type)
        )
        .test(
          "fileSize",
          "The file size should not exceed 10 MB",
          (value) => !value || value?.size <= 10 * 1024 * 1024
        ),

      introVideo: Yup.mixed()
        .test(
          "fileType",
          "Unsupported file format",
          (value) =>
            !value ||
            [
              "video/mp4",
              "video/webm",
              "video/x-matroska",
              "video/3gpp",
            ].includes(value?.type)
        )
        .test(
          "fileSize",
          "The file size should not exceed 25 MB",
          (value) => !value || value?.size <= 25 * 1024 * 1024
        ),            
      jobTitle: Yup.mixed().test(
        "jobTitle",
        "Please select a maximum of 5 job titles",
        (value) => !!(Array.isArray(selectJobTitles) && selectJobTitles?.length <= 5)
      ),
      salaryExpectation: Yup.string()
        .matches(numericWithCommaAndDecimal, "Please enter a numeric value.")
        .max(12, "salary expectation cannot exceed 12 characters")
        .typeError("Please enter a numeric value.")
        .test({
          name: "validNumber",
          message: "Please enter a numeric value between 0 and 9.",
          test: (value) => {
            if (value) {
              const numValue = parseFloat(String(value).replace(/,/g, ''));
              return numValue >= 0;
            }
            return true;
          },
        }),
    }),

    onSubmit: async (data, { resetForm, setErrors }) => {
     // setEditable(!editable)
      const selectJobTitlesNames = selectJobTitles?.map((item) => item.id);
      const selectSkillTagsNames = selectSkillTags.map((item) => item?.name);
      const selectWorkPreferenceNames = selectWorkPreferenceTags.map((item) => item.id);


      // user["currency_id"] = data.currencyId
      submitHandler(data, user, selectJobTitlesNames, selectSkillTagsNames, selectWorkPreferenceNames,() => setEditable(!editable));
    },
  });

  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <p className="error-msg">{formik.errors[name]}</p>
      )
    );
  };


  const isSkillTagsFieldValid = () => {
      return (
        (formik.touched["skillTags"] || formik.submitCount > 0) && 
        (selectSkillTags?.length < 5 || selectSkillTags?.length > 10)
      );
    };

  const isJobTitleFieldValid = () =>
    !!(
      formik.touched["jobTitle"] &&
      formik.errors["jobTitle"] &&
      selectJobTitles?.length > 5
    );


  const getSkillTagsErrorMessage = () => {
    return (
      isSkillTagsFieldValid() && (
        <p className="error-msg">{formik.errors["skillTags"]}</p>
      )
    );
  };

  const getJobTitleErrorMessage = () => {
    return (
      isJobTitleFieldValid() && (
        <p className="error-msg">{formik.errors["jobTitle"]}</p>
      )
    );
  };

  const handleFormKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };

  const onCurrencyClick = async (id, name) => {
    await formik.setFieldValue("currencyId", id);
    await formik.setFieldValue("currency", name);
  };

  const onSalaryRangeClick = async (name) => {
    console.log(name)
    await formik.setFieldValue("salaryRange", name);
  };

  const onWorkPreferenceTagsClick = async (curentSkillTags) => {
    const isIdExists = selectWorkPreferenceTags?.some(
      (item) => item.id === curentSkillTags?.id
    );
    if (isIdExists) return;
    workPreferenceArray.push([...selectWorkPreferenceTags, curentSkillTags])
    console.log(workPreferenceArray)
    setSelectWorkPreferenceTags([...selectWorkPreferenceTags, curentSkillTags]);
    setAllWorkPreferenceTagsHide(true);
    await formik.setFieldValue("userWorkPreferences", "");
  };

  const onWorkPreferenceTagsCancel = async (curentSkillTags) => {
    const updatedItems = selectWorkPreferenceTags.filter(
      (item) => item.id !== curentSkillTags?.id
    );
    setSelectWorkPreferenceTags(updatedItems);
  };

  const getSearchSuggestion = async (searchValue, searchType) => {
    try {
      let params = ``;
      if (searchValue !== "") {
        params = `?query_key=${searchValue}&type=${searchType}`;
      }
      await API.get(`/job-board/get-search-suggestions${params}`)
        .then((response) => {
          if (response?.status === 200) {
            let res = response?.data;
            if (searchType === "jobTitle") {
              if (res?.job_titles?.length > 0) {
                setAllTobTitles(res?.job_titles);
                setAllJobTitlesHide(false);
              } else {
                setAllTobTitles([]);
                setAllJobTitlesHide(false);
              }
            }

            if (searchType === "jobTag") {
              if (res?.job_tags?.length > 0) {
                setAllSkillTags(res?.job_tags);
                setAllSkillTagsHide(false);
              } else {
                setAllSkillTags([]);
                setAllSkillTagsHide(false);
              }
            }
          }
        })
        .catch((error) => {
          if (error) {
            console.log(error);
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  const checkJobTitleError = async () => {
    if (selectJobTitles?.length >= 5) {
      formik?.setFieldError("jobTitle", "Please select a maximum of 5 job titles");
      getJobTitleErrorMessage()
    }
    else {
      const newErrors = { ...formik.errors };
      delete newErrors?.jobTitle;
      formik.setErrors(newErrors);
      getJobTitleErrorMessage()
    }
  }

  const onJobTitileChange = async (value) => {
    await formik.setFieldValue("jobTitle", value);
    getSearchSuggestion(encodeURIComponent(value), "jobTitle")
  };

  const onJobTitileClick = async (curentJobTitles) => {
    const isIdExists = selectJobTitles?.some(
      (item) => item.id === curentJobTitles?.id
    );
    if (isIdExists) return;
    setSelectJobTitles([...selectJobTitles, curentJobTitles]);
    setAllJobTitlesHide(true);
    await formik.setFieldValue("jobTitle", "");
    await checkJobTitleError();
  };

  const highlightMatchingText = (option, inputValue) => {
    const escapeRegex = (string) => string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
    const lowercaseInputValue = inputValue?.toLowerCase();
    const escapedInputValue = escapeRegex(lowercaseInputValue); // Escape special characters
    const regex = new RegExp(`(${escapedInputValue})`, "gi"); // Create the regex
    const parts = option?.split(regex);
  
    return (
      <span>
        {parts.map((part, index) =>
          regex.test(part) ? (
            <strong key={index} className="highlightMatchingText">
              {part}
            </strong>
          ) : (
            part
          )
        )}
      </span>
    );
  };
    
  

  const onJobTitileCancel = async (curentJobTitles) => {
    const updatedItems = selectJobTitles.filter(
      (item) => item.id !== curentJobTitles?.id
    );
    setSelectJobTitles(updatedItems);
    await checkJobTitleError();
  };

  const onSkillTagsChange = async (value) => {
    await formik.setFieldValue("skillTags", value);
    getSearchSuggestion(encodeURIComponent(value), "jobTag");
  };

  const onSkillTagsClick = async (currentSkillTag) => {
    const newSkill = { id: Math?.random(), name: currentSkillTag };

    const isNameExists = selectSkillTags?.some(
      (item) => item?.name?.toLowerCase() === newSkill?.name?.toLowerCase()
    );
    setAllSkillTagsHide(true);
    formik.setFieldValue("skillTags", "");
    if (isNameExists) return; // Prevent duplicates
    setSelectSkillTags([...selectSkillTags, newSkill]);    
  };

  const onSkillTagsCancel = async (currentIndex) => {
    const updatedItems = selectSkillTags?.filter(
      (item, index) =>
        index !== currentIndex
    );
    setSelectSkillTags(updatedItems);
  };

  const handleSkillTagsKeyDown = (e) => {
    if (e?.key === "Enter" && e?.currentTarget?.value?.trim() !== "") {
      onSkillTagsClick(e?.currentTarget?.value?.trim()); // Add the custom tag
      e.preventDefault();
    }
  };

  return (
    <div className='profile-edit-body'>
      {!editable && (
        <button className="btn btn-transparent edit-btn-profile" type="button" onClick={() => setEditable(!editable)}>
          <span className="visibility-hidden opacity-0 position-absolute">Edit</span>
          <img src="/image/pen.png" alt="" />
        </button>
      )}
      <div className='row'>
        {
          editable ? <>
            <div className='col-12'>
              <form onSubmit={formik.handleSubmit}  onKeyDown={handleFormKeyDown} >
                <div className='row'>
                  <div className='col-md-8'>
                    <OutsideClickHandler
                      onOutsideClick={() => {
                        setAllSkillTagsHide(true);
                        setAllWorkPreferenceTagsHide(true);
                      }}
                    >
                      <div className="need-to-key">
                        <div className="signup-salary f-feild cand-form">
                          <label for="fsalary" className='text-primary'>Minimum salary expectation</label>
                          <br />
                          <div className="salary-range-start-ends position-relative">
                            <div className="row">
                              <div className="col-md-9 col-8">
                                <div className={`merge-feild-1 max-w-100 ${isFormFieldValid("salaryExpectation") && "error-input"}`}>
                                  <div className={`salary-f-job ${isFormFieldValid("currency") && "error-input"}`}>
                                    <div className="salary-start">
                                      <div
                                        id="fsalary-country"
                                        className="company-form-dropdownBox-f"
                                        data-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                      >
                                        {formik?.values?.currency
                                          ? formik?.values?.currency
                                          : ""}
                                      </div>

                                      <div className="list-search-dropdown-country-job">

                                        <ul className="list-job-title-country">
                                          {currencyOption?.map((prod) => (
                                            <li
                                              onClick={() =>
                                                onCurrencyClick(
                                                  prod?.id,
                                                  prod?.title
                                                )
                                              }
                                            >
                                              <img
                                                src={
                                                  prod?.flag_link
                                                    ? prod?.flag_link
                                                    : "/image/CA.png"
                                                }
                                                alt=""
                                              />{" "}
                                              {`${prod?.title}`}


                                            </li>
                                          ))}
                                        </ul>

                                      </div>
                                    </div>
                                  </div>
                                  <label for='salary-start-range' className="visibility-hidden opacity-0 position-absolute">candidate-name-field</label>
                                  <input
                                    className="form-font-f"
                                    type="text"
                                    name="salaryExpectation"
                                    id="salary-start-range"
                                    value={formik?.values?.salaryExpectation}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                  />
                                </div>
                              </div>
                              <div className="col-md-3 col-4">
                                <div className="merge-feild-4 max-w-100">
                                  <div
                                    className={`${isFormFieldValid("salaryRange") && "error-input"
                                      }`}
                                  >
                                    <div className="Year">
                                      <div
                                        id="fsalary-year"
                                        className="company-form-dropdownBox-f"
                                        data-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                      >
                                        {formik?.values?.salaryRange
                                          ? capitalizeFirstLetter(
                                            formik?.values?.salaryRange
                                          )
                                          : "Year"}
                                      </div>

                                      {salaryRangeOption?.length > 0 && (
                                        <div className="candidate-search-dropdown-year-job">
                                          <ul className="candidate-job-title-year-job">
                                            {salaryRangeOption.map((prod) => (
                                              <li
                                                onClick={() =>
                                                  onSalaryRangeClick(prod?.value)
                                                }
                                              >
                                                {" "}
                                                {`${capitalizeFirstLetter(
                                                  prod?.value
                                                )}`}
                                              </li>
                                            ))}
                                          </ul>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {getFormErrorMessage("salaryExpectation")}                
                        </div>

                        <div className={`signup-skill-tags f-feild tags-tittles`}>
                          <label for="fjobtitles7" className='text-primary'>Work preference</label>
                          <input
                            className="form-font-f cursor-pointer"
                            type="text"
                            name="skillTags"
                            value={formik.values.workPreference}
                            onFocus={() => setAllWorkPreferenceTagsHide(false)}
                            placeholder="Choose work preference"
                            id='fjobtitles7'
                            readOnly
                          />

                          {!allWorkPreferenceTagsHide ? (
                            <div
                              className={`job-tags-titles-dropdown`}
                            >
                              <ul className="dorpdown-list-location">
                                {allWorkPreference?.map((j, i) => (
                                  <li
                                    key={i}
                                    onClick={() => {
                                      onWorkPreferenceTagsClick(j);
                                    }}
                                  >
                                    {" "}
                                    {j?.name}
                                  </li>
                                ))}
                              </ul>
                            </div>
                          ) : <></>}
                          {selectWorkPreferenceTags?.length > 0 && (
                            <ul class="tags-titles-list">
                              {selectWorkPreferenceTags.map((j, i) => (
                                <li key={i}>
                                  {j?.name}
                                  <span onClick={() => onWorkPreferenceTagsCancel(j)}>
                                    <img
                                      src="../image/cross.png"
                                      alt=""
                                      onClick={() => {

                                      }}
                                    />
                                  </span>
                                </li>
                              ))}
                            </ul>
                          )}

                          {/* <p className="error-msg hide-error ">Enter skill tags</p> */}
                        </div>
                      </div>
                    </OutsideClickHandler>
                    <div className="preferences-fields-profile">
                      <OutsideClickHandler
                        onOutsideClick={() => {
                          setAllJobTitlesHide(true);
                        }}
                      >
                        <div
                          className={`signup-job-titles f-feild tags-tittles ${isJobTitleFieldValid() && "error-input"
                            }`}
                        >
                          <label for="fjobtitles1" className='text-primary'>Job titles I’m interested in</label>
                          <input
                            className="form-font-f"
                            type="text"
                            id='fjobtitles1'
                            name="jobTitle"
                            value={formik.values.jobTitle}
                            onChange={(e) => onJobTitileChange(e.currentTarget.value)}
                            placeholder="Job titles i’m interested in"
                          />
                          {getJobTitleErrorMessage()}
                          {allJobTitles?.length > 0 && (
                            <div
                              className={`job-tags-titles-dropdown ${allJobTitlesHide && "job-dropdown-hide"
                                }`}
                            >
                              <ul className="dorpdown-list-location">
                                {allJobTitles.map((j, i) => (
                                  <li
                                    key={i}
                                    onClick={() => {
                                      onJobTitileClick(j);
                                    }}
                                  >
                                    {formik?.values?.jobTitle?.length > 0 ? (
                                  <>
                                      {j?.name 
                                        ? highlightMatchingText(j?.name, formik?.values?.jobTitle) 
                                        : ""}
                                    </>
                                  ) : (
                                    j?.name || ""
                                  )}
                                  </li>
                                ))}
                              </ul>
                            </div>
                          )}

                          {selectJobTitles?.length > 0 && (
                            <ul class="tags-titles-list">
                              {selectJobTitles.map((j, i) => (
                                <li key={i}>
                                  {j?.name}
                                  <span onClick={() => onJobTitileCancel(j)}>
                                    <img src="../image/cross.png" alt="" />
                                  </span>
                                </li>
                              ))}
                            </ul>
                          )}
                          {/* <p className="error-msg hide-error">Enter job titles </p> */}
                        </div>
                      </OutsideClickHandler>
                      <OutsideClickHandler
                        onOutsideClick={() => {
                          setAllSkillTagsHide(true);
                        }}
                      >
                        <div className={`signup-skill-tags f-feild tags-tittles`}>
                          {/* <div className={`signup-skill-tags f-feild tags-tittles`}> */}
                          <label for="fjobtitles2" className='text-primary'>Tech tags</label>
                          <input
                            className="form-font-f"
                            type="text"
                            name="skillTags"
                            value={formik.values.skillTags}
                            onChange={(e) => {
                              onSkillTagsChange(e.currentTarget.value);
                              formik.handleChange(e);
                            }}
                            onKeyDown={handleSkillTagsKeyDown}
                            id='fjobtitles2'
                            onBlur={formik.handleBlur}
                            placeholder="Tech tags"
                          />                  

                          {allSkillTags?.length > 0 && (
                            <div
                              className={`job-tags-titles-dropdown ${allSkillTagsHide && "job-dropdown-hide"
                                }`}
                            >
                              <ul className="dorpdown-list-location">
                                {allSkillTags.map((j, i) => (
                                  <li
                                    key={i}
                                    onClick={() => {
                                      onSkillTagsClick(j?.name);
                                    }}
                                  >
                                    {" "}
                                    {j?.name}
                                  </li>
                                ))}
                              </ul>
                            </div>
                          )}

                          {selectSkillTags?.length > 0 && (
                            <ul class="tags-titles-list">
                              {selectSkillTags.map((j, i) => (
                                <li key={i}>
                                  {j?.name}
                                  <span onClick={() => onSkillTagsCancel(i)}>                              
                                    <img className="icon-cursor" src="../image/cross.png" alt="" />
                                  </span>
                                </li>
                              ))}
                            </ul>
                          )}

                          {/* <p className="error-msg hide-error ">Enter skill tags</p> */}
                        </div>
                      </OutsideClickHandler>
                    </div>

                    <div className="s-amenity">
                      <h3 className='text-primary'>Introduce yourself</h3>
                      <div className="upload-intro-vedio">
                        <label id="candidate-img">
                          <img src="/image/upload-1.png" alt="pic" className="upload-profile-img" />
                          <label for='file-input-2' className="visibility-hidden opacity-0 position-absolute">candidate-name-field</label>
                          <input
                            id="file-input-2"
                            type="file"
                            name="introVideo"                     
                            onChange={(e) => handleVideoChange(e)}
                          />
                        <p className="candidate-logo-file">{formik.values.selectedVideoName ? "Replace": "Upload here"}</p>  
                        {formik.values.selectedVideoName ? 
                            <></>
                            :   
                          <p className="file-size-candidate">
                            Format: .mp4, .webm, .mkv, .3gp{" "}
                          </p>
                        }                                                                
                          <p className='file-name-candidate'>{formik.values.selectedVideoName}</p> 
                          {formik.values.fileSize ?  
                               <p className="file-size-selected-candidate">File size: {formatFileSize(formik.values.fileSize)}</p>   
                            :  <p className="file-size-candidate">File size max: 25MB</p>   
                            }                                            
                          {getFileErrorMessage("introVideo")}
                        </label>
                      </div>
                         {loader && (
                         <div className='upload-can-pro-msg'>                        
                          <span className="load-heading">
                             Profile data is being updated....
                          </span>
                        </div>
                       )}  
                    </div>
                 
                  </div>
                  <div className='col-12'>
                    <div className='edit-footer justify-content-end'>
                      <div className='action-edit'>
                        <button 
                        type='button'
                        className="btn btn-black" 
                          onClick={(e) => {
                            e.preventDefault();
                            setEditable(!editable)
                          }
                          }
                        >
                          Cancel
                        </button>
                        <button className="btn btn-outline-black" type="submit">
                        {loader ? (
                          <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          />
                      ) : (
                        "Save"
                      )}</button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </> : <>
            <div className="col-md-8">
              <div className="s-amenity">
                <h3 className='text-primary'>Minimum salary expectations</h3>

                {baseInfo?.expected_salary && baseInfo?.expected_salary !== 0 ?
                  <p className="candidate-info-text">
                    {baseInfo?.currency} {" "}
                    {formatSalary(
                      baseInfo?.expected_salary,
                      baseInfo?.currency,
                      baseInfo?.salary_range,
                      baseInfo?.salary_range
                    )}
                  </p>
                  : <p className="candidate-info-text opacity-50">Salary expectations</p>
                }
              </div>
              <div className="s-amenity">
                <h3 className='text-primary'>Work preference</h3>
                {workPreferences?.length !== 0 ?
                  <div className="tags-tittles">
                    <ul className="tags-titles-list">
                      {workPreferences?.map((j, i) => (
                        <li>{j?.name}</li>
                      ))}
                    </ul>
                  </div>
                  :
                  <p className="candidate-info-text opacity-50">Work preference</p>
                }
              </div>
              <div className="s-amenity">
                <h3 className='text-primary'>Job titles I’m interested in</h3>
                {jobTitles?.length > 0 ? (
                  <div className="tags-tittles">
                    <ul className="tags-titles-list">
                      {jobTitles.map((j, i) => (
                        <li>{j?.name}</li>
                      ))}
                    </ul>
                  </div>
                )
                  :
                  <p className="candidate-info-text opacity-50">Add job titles</p>
                }
              </div>
              <div className="s-amenity">
                <h3 className='text-primary'>Tech tags</h3>
                {tags?.length > 0 ? (
                  <div className="tags-tittles">
                    <ul className="tags-titles-list">
                      {tags.map((j, i) => (
                        <li>{j?.name}</li>
                      ))}
                    </ul>
                  </div>
                )
                  :
                  <p className="candidate-info-text opacity-50">Add tech tags</p>
                }
              </div>
              <div className="s-amenity">
                <h3 className='text-primary'>Introduce yourself</h3>
                {baseInfo?.intro_video_link ? <Player src={baseInfo?.intro_video_link}>
                  <BigPlayButton position="center" />
                </Player> : <img src="/image/noVideo.png" className='img-fluid' />}
              </div>
            </div>
          </>
        }
      </div>
    </div>
  )
}

export default ProfileSection