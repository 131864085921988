import { useState, Fragment, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import OutsideClickHandler from "react-outside-click-handler";
import capitalizeFirstLetter from "../../../utils/capitalizeFirstLetter";
import CapitalizeFirstLetter from "../../../utils/capitalizeFirstLetter";
import getDays, { calculateDaysAgoUTC } from "../../../utils/getDays";
import getCurrentFormattedDate from "../../../utils/getCurrentFormattedDate";
import { setResumeUrl } from "../../../redux/slices/candidateDashboardSlice";
import {
  removeJobCommentAsync,
  setCandidateId,
  setJobId,
} from "../../../redux/slices/commentModalSlice";
import {
  setCandidateDetailId,
  setCandidateDetailResumeId,
  setCandidateDetailTab,
  setjobDetailId,
} from "../../../redux/slices/candidateDetailSlice";
import { CANDIDATE_STATUSES, IMPRESSION_TYPES } from "../../../constants";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import { getColor } from "../../../utils/progressbarFn";
import "react-circular-progressbar/dist/styles.css";
import "./ApplicantsTable.css";
import { markAsReadInboxDataAsync, setInboxUser, startChatDataAsync } from "../../../redux/slices/InboxSlice";
import { setInboxCount } from "../../../redux/slices/AuthSlice";
import { generateSignedURLDataAsync } from "../../../redux/slices/myResumeSlice";

const ApplicantsTable = ({
  showCheckBox,
  selectedCandidateId,
  index,
  item,
  candidateStatusesList,
  onFvtClick,
  modelHandler,
  modelCHandler,
  onFirstImpressionClick,
  onCandidateStatusChangeHandler,
  onResumeClickHandler,
  handleAddNewCommentHandler,
  handleRemovedComment,
  selectedCandidateIdHandler,
  deleteCanListModelHandler,
  deleteCanAllListModelHandler,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user, inboxCount } = useSelector((state) => state.auth);
  const { listingDropDown } = useSelector((state) => state.candidateListing);

  const [toggleCommentBox, setToggleCommentBox] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [showBadCandidate, setShowBadCandidate] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [showNeedToThinkCandidate, setShowNeedToThinkCandidate] =
    useState(false);
  const [showGoodCandidate, setShowGoodCandidate] = useState(false);

  const [toggleDeleteMenu, setToggleDeleteMenu] = useState(false);

  const [commentText, setCommentText] = useState("");

  /* React Circular Progress Bar*/
  const OverAllMatch =
    item?.overall_match !== undefined && item?.overall_match !== null
      ? item?.overall_match
      : 0;
  const progressValue = parseInt(OverAllMatch, 10);
  const totalExperience = parseFloat(item?.overall_experience);

  const handleCommentTextChange = (e) => {
    const value = e.target.value;
    setCommentText(value);
  };

  const handleMouseOver = (impressionId) => {
    if (item?.status_id === CANDIDATE_STATUSES.NEW_APPLICANT) {
      setShowWarning(true);
      return;
    }
    switch (impressionId) {
      case IMPRESSION_TYPES.NOT_A_GOOD_FIT:
        setShowBadCandidate(true);
        break;
      case IMPRESSION_TYPES.NEED_TO_THINK:
        setShowNeedToThinkCandidate(true);
        break;
      case IMPRESSION_TYPES.GOOD_CANDIDATE:
        setShowGoodCandidate(true);
        break;
      default:
        break;
    }
  };

  const handleMouseOut = () => {
    setShowWarning(false);
    setShowBadCandidate(false);
    setShowGoodCandidate(false);
    setShowNeedToThinkCandidate(false);
  };

  const toggleCommentBoxHandler = (toggled) => {
    if (toggled) {
      setToggleCommentBox(true);
    } else {
      setToggleCommentBox(false);
    }
  };

  const handleInbox = (conversation, conversationData, item) => {
    if (conversationData) {
      if (conversation) {
        dispatch(markAsReadInboxDataAsync({ conversation_id: conversationData?.id }))
          .then((response) => {
            const res = response?.payload;
            if (res?.success === true) {
              const  currentInboxCount = inboxCount; 
              if(currentInboxCount > 0){
                dispatch(setInboxCount(currentInboxCount - 1));
              }          
            }
            dispatch(setInboxUser(conversationData));
            navigate("/employer-inbox");
          })
          .catch((error) => {
            console.log(error);
          });
      }        
      else {
        dispatch(setInboxUser(conversationData));
        navigate("/employer-inbox");
      }
    }
    else if (conversation === null) {
      dispatch(startChatDataAsync({ job_id: item?.job_id, candidate_id: item?.account_id }))
      .then((response) => {
        const res = response?.payload;
        if (res?.success === true) {       
          dispatch(setInboxUser({}));               
          navigate("/employer-inbox");
        }
        else {
          dispatch(setInboxUser({}));               
          navigate("/employer-inbox");
        }
      })
      .catch((error) => {
        console.log(error);
      });
    }    
  };

  const removeCommentClickHandler = (commentId, candidateId, jobId) => {
    dispatch(
      removeJobCommentAsync({
        commentId: commentId,
        candidateId: candidateId,
        jobId: jobId,
      })
    )
      .then((response) => {
        const res = response?.payload;
        if (res?.result === true) {
          handleRemovedComment(
            commentId,
            res?.lastComment[res?.lastComment?.length - 1] ?? ""
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handlePreviewPDF = async (item) => {
    if (user?.id) {
      const {account_id, application_id, resume_id } = item;
      dispatch(
        generateSignedURLDataAsync({
          candidate_id: account_id,
          application_id: application_id,
          resume_id: resume_id,
          file_type: "pdf"
        })
      )
        .then(async (response) => {
          const res = response?.payload;
          if (res?.result === true) {
            const { url } = res;
            if (url !== null) {
              const pdfUrl = url;
              window.open(pdfUrl, "_blank");
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const showSubMenu = () => {
    if (showMenu) {
      setShowMenu(false);
    } else {
      setShowMenu(true);
    }
  };

  return (
    <Fragment key={item?.id}>
      {showCheckBox && listingDropDown?.value === "ALL" && (
        <div className="lr checkbox">
          <input
            type="checkbox"
            name=""
            class="td-tr-chk"
            checked={
              selectedCandidateId?.includes(item?.account_id) ? true : false
            }
            onClick={() => selectedCandidateIdHandler(item?.account_id)}
          />
        </div>
      )}
      <div className="lr full-name">
        {showCheckBox && listingDropDown?.value === "ALL" && (
          <input
            type="checkbox"
            name=""
            class="td-tr-chk"
            checked={
              selectedCandidateId?.includes(item?.account_id) ? true : false
            }
            onClick={() => selectedCandidateIdHandler(item?.account_id)}
          />
        )}
        {`${capitalizeFirstLetter(item?.first_name)}  ${item?.last_name}`}

        <div className="name-comment-box">
          <div className="name-add-box">
            <div className="comment-owner-info">
              <img src="/image/comment-owner.png" alt="" />
              <p className="name-comment-owner">{`${capitalizeFirstLetter(
                item?.first_name
              )}  ${item?.last_name}`}</p>
            </div>
            <div className="name-info-box">
              <p>
                Experienced Python developer with 7 years of experience, adept
                at utilizing libraries and frameworks to build efficient and
                scalable applications. Strong problem-solving skills and
                attention to detail, delivering high-quality software solutions
                on time.
              </p>
            </div>
          </div>
        </div>
        <div className="prog-bar-cir d-md-none d-inline">
          <div
             className="progress-bar-container-m">
            <CircularProgressbar
              minValue="0"
              strokeWidth={12}
              value={progressValue}
              text={`${progressValue}%`}
              counterClockwise={false}
              styles={buildStyles({
                strokeLinecap: "round",
                pathTransitionDuration: 0.5,
                pathColor: getColor(progressValue),
                textColor: getColor(progressValue),
                textSize: "24px",
                trailColor: "#d6d6d6",
              })}
            />
          </div>
        </div>
      </div>
      <div
        className="lr j-title cursor-pointer"
        onClick={() => {}}
      >
        <span className="d-md-none d-inline">Job Applied To:</span>
        {item?.full_title}
      </div>
      <div className="lr experience">
        <span className="d-md-none d-inline">Experience:</span>
        {`${Number?.isInteger(totalExperience)
          ? totalExperience?.toString()
          : totalExperience?.toFixed(1)
          } Years`}
      </div>
      <div className="lr cand-status">
        <span className="d-md-none d-block mb-2">Candidate Status:</span>
        <div className="job-fillter-table-data m-0 w-100">
          <div className="j-company-fillter-table-data w-100">
            <div
              id="dropdown-boxId2"
              className="company-form-dropdownBox1-tabeldate"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <span>
                {item?.status_id === null
                  ? "Candidate status"
                  : CapitalizeFirstLetter(item?.status)}
              </span>
            </div>
            <div className="list-search-dropdown-company">
              <ul className="list-table-title-cand">
                {candidateStatusesList?.slice(1)?.map((candidateStatus) => (
                  <li
                    key={candidateStatus?.id}
                    onClick={() =>
                      onCandidateStatusChangeHandler(
                        item?.account_id,
                        item?.job_id,
                        candidateStatus?.id,
                        item?.status_id
                      )
                    }
                  >
                    {candidateStatus?.status}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="lr match">
        <div className="prog-bar-cir">
          <div className="progress-bar-container">
            <CircularProgressbar
              minValue="0"
              strokeWidth={12}
              value={progressValue}
              text={`${progressValue}%`}
              counterClockwise={false}
              styles={buildStyles({
                strokeLinecap: "round",
                pathTransitionDuration: 0.5,
                pathColor: getColor(progressValue),
                textColor: getColor(progressValue),
                textSize: "24px",
                trailColor: "#d6d6d6",
              })}
            />
          </div>

          {/* <div className="progress-table">
              <div className="progress-bar-outer">
                <div className="progress-bar-inner">
                  <div className="progress-value">100%</div>
                </div>
              </div>
            </div> */}
        </div>
      </div>
      <div className="lr impression">
        <div className="cv-cions-review p-0">
          <img
            className={showWarning === true ? null : "icon-cursor"}
            src={
              item?.impression_id === IMPRESSION_TYPES.NOT_A_GOOD_FIT
                ? "/image/bad-cv.png"
                : "/image/bad-cv-icon.png"
            }
            alt=""
            onClick={() =>
              onFirstImpressionClick(
                item?.account_id,
                item?.job_id,
                IMPRESSION_TYPES.NOT_A_GOOD_FIT,
                item?.status_id
              )
            }
            onMouseOver={() => handleMouseOver(IMPRESSION_TYPES.NOT_A_GOOD_FIT)}
            onMouseOut={() => handleMouseOut()}
          />

          <img
            className={showWarning === true ? null : "icon-cursor"}
            src={
              item?.impression_id === IMPRESSION_TYPES.NEED_TO_THINK
                ? "/image/think-need.png"
                : "/image/normal.cv.png"
            }
            alt=""
            onClick={() =>
              onFirstImpressionClick(
                item?.account_id,
                item?.job_id,
                IMPRESSION_TYPES.NEED_TO_THINK,
                item?.status_id
              )
            }
            onMouseOver={() => handleMouseOver(IMPRESSION_TYPES.NEED_TO_THINK)}
            onMouseOut={() => handleMouseOut()}
          />
          <img
            className={showWarning === true ? null : "icon-cursor"}
            src={
              item?.impression_id === IMPRESSION_TYPES.GOOD_CANDIDATE
                ? "/image/good-cv.png"
                : "/image/good-cv-icon.png"
            }
            alt=""
            onClick={() =>
              onFirstImpressionClick(
                item?.account_id,
                item?.job_id,
                IMPRESSION_TYPES.GOOD_CANDIDATE,
                item?.status_id
              )
            }
            onMouseOver={() => handleMouseOver(IMPRESSION_TYPES.GOOD_CANDIDATE)}
            onMouseOut={() => handleMouseOut()}
          />

          <img
            className="icon-cursor"
            src={
              parseInt(item?.is_favorite) === 0
                ? "/image/cv-favt.png"
                : "/image/favourite-fillicon.png"
            }
            alt=""
            onClick={() => onFvtClick(item?.account_id, item?.job_id)}
          />
          {showGoodCandidate === true ? (
            <div className="good-cv-box">
              <p>Good Candidate</p>
              <img src="/image/review-good-cv.png" alt="" />
            </div>
          ) : null}

          {showNeedToThinkCandidate === true ? (
            <div className="thinkneed-cv-box">
              <p>Need to Think</p>
              <img src="/image/think-cv-need.png" alt="" />
            </div>
          ) : null}

          {showBadCandidate ? (
            <div className="badreview-cv-box">
              <p>Not a Good Fit</p>
              <img src="/image/bad-cv-review.png" alt="" />
            </div>
          ) : null}

          {showWarning === true ? (
            <div className="attention-box-table">
              <div className="attendtion-heading">
                <div className="icon-atten">
                  <img src="/image/attention.png" alt="" />
                </div>
                <div className="attention-text-table">
                  <p className="text-atten-box-table">
                    Please Update Status First
                  </p>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
      <div className="lr date-app">
        <p className="d-md-none d-inline mr-1">Applied date:</p>
        {getCurrentFormattedDate(item?.created_at)}
      </div>
      <div className="lr action">
        <button className="btn d-md-none d-inline" onClick={showSubMenu}>
          <img src="/image/ellipses.png" alt="éllipses" />
        </button>
        <div className={showMenu ? "cv-cions active" : "cv-cions"}>
          <div
            className="img-table-tooltip"
            onClick={() => {
              // modelHandler(true);
              // dispatch(setResumeUrl(item?.latest_resume?.resume_link));
              handlePreviewPDF(item);
              onResumeClickHandler(
                item?.account_id,
                item?.job_id,
                item?.status_id
              );
            }}
          >
            <img src="/image/table-card.png" alt="" class="show-tooltip" />
            <div className="tooltip-table">View Resume</div>
          </div>
          {/* )} */}

          <div
            className="img-table-tooltip"
            onClick={() => {
              dispatch(setCandidateDetailId(item?.account_id));
              dispatch(setCandidateDetailResumeId(item?.resume_id));
              dispatch(setjobDetailId(item?.hashed_id));
              dispatch(setCandidateDetailTab("candidate-evaluation"));
              navigate("/employer-candidate-detail");
            }}
          >
            <img src="/image/table-man.png" alt="" class="show-tooltip" />
            <div className="tooltip-table">AI Analysis and Detail</div>
          </div>
          {/* <div className="img-table-tooltip">
            <img src="/image/table-activity.png" alt="" class="show-tooltip" />
            <div className="tooltip-table">Schedule Activity</div>
          </div> */}

          <div
            className="img-table-tooltip"
            //onClick={() => toggleCommentBoxHandler(true)}

            onClick={() => handleInbox(item?.conversation, item?.conversationData, item)}
          >
            {item?.conversation !== null && item?.conversation > 0 ? <span className="notify-badge"><p>{item?.conversation}</p></span> : ""}

           

            <img src="/image/table-comm.png" alt="" class="show-tooltip" />
              <div className="tooltip-table">Messages</div>
          </div>

          {/* <div className="img-table-tooltip">
              <img src="/image/table-list.png" alt="" class="show-tooltip" />
              <div className="tooltip-table">List</div>
            </div> */}

            <div className="img-table-tooltip">    
          {showCheckBox === true && listingDropDown?.value === "MY_LIST" && (
            <div className="img-table-dot">
              <img
                src="/image/ellipses.png"
                alt=""
                className="show-tooltip"
                onClick={() => setToggleDeleteMenu(!toggleDeleteMenu)}
              />
              {toggleDeleteMenu && (
                <OutsideClickHandler
                  onOutsideClick={() => {
                    setToggleDeleteMenu(false);
                  }}
                >
                  <div className="delet-list-menu">
                    <ul className="list-item-delet">
                      <li
                        onClick={() => {
                          setToggleDeleteMenu(false);
                          deleteCanListModelHandler(true, item?.account_id);
                        }}
                      >
                        Delete from this list
                        <img src="/image/role-delet.png" alt="" />
                      </li>
                      <li
                        onClick={() => {
                          setToggleDeleteMenu(false);
                          deleteCanAllListModelHandler(true, item?.account_id);
                        }}
                      >
                        Delete from all lists
                        <img src="/image/role-delet.png" alt="" />
                      </li>
                    </ul>
                  </div>
                </OutsideClickHandler>
              )}
            </div>
          )}
           <div className="tooltip-table">List actions</div>
            </div>

        </div>
      </div>
    </Fragment>
  );
};

export default ApplicantsTable;
