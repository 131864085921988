import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import API from "../../../api";
import PhoneNumber from "../../Common/PhoneNumber/PhoneNumber";
import {
  getCitiesAsync,
  getEmpCountriesAsync,
  getStateAsync,
} from "../../../redux/slices/AuthSlice";
import { linkedInUrlRegex, nameRegex, urlRegex, USER_TYPE, websiteUrlRegex } from "../../../constants";
import OutsideClickHandler from "react-outside-click-handler";

const EmpProfileForm = ({
  loader,
  baseInfo,
  companyInfo,
  companyLogo,
  maskedPlaceHolderP,
  maskedPhoneNumberP,
  statesCount,
  industryOption,
  companySizeOption,
  editHandler,
  companyLogoModelHandler,
  submitHandler,
  deleteImageLogo,
}) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const [selectedImageName, setSelectedImageName] = useState("");
  const [countryOptions, setCountryOptions] = useState([]);
  const [stateOptions, setStateOptions] = useState([]);
  const [citiesOptions, setCitiesOptions] = useState([]);
  const [locationHide, setLocationHide] = useState(true);

  const [stateDDHide, setStateDDHide] = useState(true);
  const [stateSearch, setStateSearch] = useState("");

  const [cityDDHide, setCityDDHide] = useState(true);
  const [citySearch, setCitySearch] = useState("");

  const [maskedPhoneNumber, setMaskedPhoneNumber] = useState("");
  const [maskedPlaceHolder, setMaskedPlaceHolder] = useState("");

  let defaultAvatar = "/image/avtar-company.png";
  user?.user_type === USER_TYPE.COMPANY_USER &&
    (defaultAvatar = "/image/company-default-avatar.png");

  const formik = useFormik({
    initialValues: {
      files: null,
      companyName: "",
      firstName: "",
      lastName: "",
      country: "",
      countryId: null,
      state: "",
      stateId: null,
      city: "",
      cityId: null,
      websiteUrl: "",
      email: "",
      phoneNo: "",
      linkedinURL: "",
      companyInfo: "",
      industry: "",
      industryId: null,
      companySize: "",
      companySizeId: null,
    },
    /*validationscheme*/
    validationSchema: Yup.object({
      firstName: Yup.string()
        .matches(nameRegex, "First name should be an alphabet")
        .max(50, "First name cannot exceed 50 characters")
        .required("Please enter first name"),
      lastName: Yup.string()
        .matches(nameRegex, "Last name should be an alphabet")
        .max(50, "Last name cannot exceed 50 characters")
        .required("Please enter last name"),
      country: Yup.string().required("Please select the country"),
      websiteUrl: Yup.string().matches(
        websiteUrlRegex,
        "Please enter a valid website url"
      ),
      linkedinURL: Yup.string().matches(
        linkedInUrlRegex,
        "Please enter a valid linkedin url"
      ),
      phoneNo: Yup.string().required("Please enter phone number"),
      companyInfo: Yup.string(),
      files: Yup.mixed()
        .test(
          "fileType",
          "File can be a JPEG or PNG document",
          (value) => !value || ["image/png", "image/jpeg"].includes(value?.type)
        )
        .test(
          "fileSize",
          "The file size should not exceed 10 MB",
          (value) => !value || value?.size <= 10 * 1024 * 1024
        ),
      industry: Yup.string(),
      companySize: Yup.string(),
    }),

    onSubmit: async (data, { resetForm, setErrors }) => {
      console.log(data)
      submitHandler(data);
    },
  });

  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <p className="error-msg">{formik.errors[name]}</p>
      )
    );
  };

  const isFileFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFileErrorMessage = (name) => {
    return (
      isFileFieldValid(name) && (
        <p className="file-error-msg">{formik.errors[name]}</p>
      )
    );
  };

  const handleFormKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };

  const handleImageChange = async (event) => {
    companyLogoModelHandler(true);
  };

  const countryHandler = async (item) => {
    const { id, name, masked_phone_number, placeholder, states_count } = item;

    await formik.setFieldValue("country", name);
    await formik.setFieldValue("countryId", id);

    await formik.setFieldValue("state", "");
    await formik.setFieldValue("stateId", null);

    await formik.setFieldValue("city", "");
    await formik.setFieldValue("cityId", null);

    setMaskedPhoneNumber(masked_phone_number);
    setMaskedPlaceHolder(placeholder);
    setLocationHide(true);
    setCitiesOptions([]);
    setStateOptions([]);

    if (states_count === 0) {
      getCitiesData(id, null);
    } else {
      getStatusData(id);
    }
  };

  const stateHandler = async (id, name) => {
    setStateDDHide(true);
    setStateSearch(name);

    await formik.setFieldValue("state", name);
    await formik.setFieldValue("stateId", id);
    await formik.setFieldValue("city", "");
    await formik.setFieldValue("cityId", null);
    getCitiesData(null, id);
  };

  const cityHandler = async (id, name) => {
    setCityDDHide(true);
    setCitySearch(name);

    await formik.setFieldValue("city", name);
    await formik.setFieldValue("cityId", id);
  };

  const industryHandler = async (item) => {
    const { id, name } = item;
    await formik.setFieldValue("industry", name);
    await formik.setFieldValue("industryId", id);
  };

  const companySizeHandler = async (item) => {
    const { id, name } = item;
    await formik.setFieldValue("companySize", name);
    await formik.setFieldValue("companySizeId", id);
  };

  /* State search */

  const filteredStateItem = stateOptions?.filter((item) =>
    item?.name?.toLowerCase().includes(stateSearch.toLowerCase())
  );

  const searchStateLocation = (e) => {
    setStateSearch(e.target.value);
  };

  /********************/

  /* City search */

  const filteredCityItem = citiesOptions?.filter((item) =>
    item?.name?.toLowerCase().includes(citySearch.toLowerCase())
  );

  const searchCityLocation = (e) => {
    setCitySearch(e.target.value);
  };

  /********************/

  const getStatusData = async (id) => {
    dispatch(
      getStateAsync({
        id: id,
      })
    )
      .then((response) => {
        const res = response?.payload;
        setStateOptions(res?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getCitiesData = async (id1, id2) => {
    dispatch(
      getCitiesAsync({
        country_id: id1,
        state_id: id2,
      })
    )
      .then((response) => {
        const res = response?.payload;
        setCitiesOptions(res);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    console.log(companyLogo)
    if (companyLogo !== null) {
      formik.setFieldValue("files", companyLogo);
      setSelectedImageName(companyLogo?.name);
    }
  }, [companyLogo]);

  useEffect(() => {
    if (baseInfo?.countryId) {
      if (statesCount === 0) getCitiesData(baseInfo?.countryId, null);
      else {
        getStatusData(baseInfo?.countryId);
        getCitiesData(null, baseInfo?.countryId);
      }
    }
    setMaskedPlaceHolder(maskedPlaceHolderP);
    setMaskedPhoneNumber(maskedPhoneNumberP);

    formik.setValues({
      companyName: companyInfo?.name || "",
      countryId: baseInfo?.countryId || null,
      country: baseInfo?.countryName || "",
      cityId: baseInfo?.cityId || null,
      city: baseInfo?.city || "",
      stateId: baseInfo?.stateId || null,
      state: baseInfo?.state || "",
      industry: companyInfo?.industry || "",
      industryId: companyInfo?.industryId || null,
      companySize: companyInfo?.companySize || "",
      companySizeId: companyInfo?.companySizeId || null,
      websiteUrl: companyInfo?.website || "",
      email: baseInfo?.email || "",
      phoneNo: baseInfo?.phone || "",
      linkedinURL: companyInfo?.linkedin || "",
      companyInfo: companyInfo?.description || "",
      firstName: baseInfo?.firstName,
      lastName: baseInfo?.lastName,
    });
  }, []);

  const deleteImage = () => {
    // companyLogo = '/image/company-default-avatar.png'
    // companyLogoHandler('')
    // formik.setFieldValue("files", defaultAvatar);
    deleteImageLogo()
    // formik.setFieldValue("files", '/image/company-default-avatar.png');
    // setSelectedImageName(companyLogo?.name);
  }

  return (
    <div className="my-profile-row">
      <div className="candidtae-profile-image d-md-block d-none">
        <div className="new-profile-image-st profile-pic-desktop">
          {console.log(companyLogo, companyInfo)}
          <img
            src={
              companyLogo !== null
                ? URL?.createObjectURL(companyLogo)
                : companyInfo?.logo === null || companyInfo?.logo === undefined
                ? defaultAvatar
                : companyInfo?.logo
            }
            alt=""
            className="profile-opac"
          />
          <div className="addProfile-icon icon-cursor">
            <img src="/image/addProfile.png" alt="" onClick={handleImageChange} />
          </div>
        </div>
        <div className="mt-3 text-center">
          <p className="text-danger cursor-pointer" onClick={deleteImage}>Remove Image</p>
        </div>
      </div>
      <span className="profile-img-mbl mt-4">
        <div className="candidtae-profile-image">
          <div className="new-profile-image-st">
            <img
              src={
                companyLogo !== null
                  ? URL?.createObjectURL(companyLogo)
                  : companyInfo?.logo === null || companyInfo?.logo === undefined
                  ? "/image/avtar-company.png"
                  : companyInfo?.logo
              }
              alt=""
              className="profile-opac"
            />
            <div className="addProfile-icon icon-cursor">
              <img
                src="/image/addProfile.png"
                alt=""
                onClick={handleImageChange}
              />
            </div>
          </div>
          <div className="mt-3 text-center">
            <p className="text-danger cursor-pointer" onClick={deleteImage}>Remove Image</p>
          </div>
        </div>
      </span>
      <div className="divider-left-profile"> </div>
      <div className="candidate-information max-mob-100">
        <div className="candidate-about-me">
          <div className="candidate-profile-form">
            <h5 className="ms-4">About company</h5>
            <form onSubmit={formik.handleSubmit} onKeyDown={handleFormKeyDown}>
              <div className="form-block-width">
                <div className="candidate-name-field-profile-f profile-field-f">
                  <div className="employr-f-profile password-icon bg-pass-f">
                    <input
                      className="form-font-f"
                      type="text"
                      name=""
                      id="Company-name-employe-profile"
                      placeholder="Company name"
                      value={formik.values.companyName}
                    />
                    <div className="icon-hide">
                      <img src="/image/locked-field.png" alt="" />
                      <span className="tooltiptext-profile">
                        Contact admin support
                      </span>
                    </div>
                  </div>
                </div>

                <div className={`candidate-name-field-profile profile-field-f`}>
                  <div
                    class=""
                    className={`first-name-profile ${
                      isFormFieldValid("firstName") && "error-input"
                    }`}
                  >
                    <input
                      className="form-font-f"
                      type="text"
                      id="candidate-name-field"
                      placeholder="First name"
                      name="firstName"
                      value={formik.values.firstName}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {getFormErrorMessage("firstName")}
                  </div>
                  <div
                    className={`first-name-profile ${
                      isFormFieldValid("lastName") && "error-input"
                    }`}
                  >
                    <input
                      className="form-font-f"
                      type="text"
                      id="candidate-name-field"
                      placeholder="Last name"
                      name="lastName"
                      value={formik.values.lastName}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {getFormErrorMessage("lastName")}
                  </div>
                </div>

                <div
                  className={`profile-field-f signup-passowrd-job location-profile-list disable-field ${
                    isFormFieldValid("country") && "error-input"
                  }`}
                >
                  <div className="industry-feild-frm bg-pass-f">
                    <div className="password-icon-logo">
                      <img
                        src="/image/profile-location.png"
                        className="location-icon"
                        alt=""
                      />
                    </div>
                    <div
                      id="dropdown-boxId-2"
                      className="company-form-dropdownBox-profile"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                      onClick={() => setLocationHide(!locationHide)}
                    >
                      {" "}
                      {formik?.values?.country === ""
                        ? "location"
                        : formik?.values?.country}
                    </div>
                    <div className="icon-hide">
                      {isFormFieldValid("country") ? (
                        <img src="/image/warning.png" alt="" />
                      ) : (
                        <img src="/image/form-d-f.png" alt="" />
                      )}
                    </div>
                  </div>
                  {getFormErrorMessage("country")}
                </div>

                <div className="candidate-username-signup-form">
                  <div
                    className={`profile-field-f signup-passowrd-job location-profile-list first-name ${
                      (isFormFieldValid("state") && "error-input") ||
                      (stateOptions?.length === 0 && "disable-field")
                    }`}
                  >
                    <OutsideClickHandler
                      onOutsideClick={() => {
                        setStateDDHide(true);
                      }}
                    >
                      <div className="industry-feild-frm bg-pass-f location-f-new">
                        <div className="password-icon-logo">
                          <img
                            src="/image/profile-location.png"
                            className="location-icon"
                            alt=""
                          />
                        </div>
                        <div className="profile-location-feild">
                          <div
                            id="dropdown-boxId2"
                            className="profile-form-dropdownBox1"
                            onClick={() => setStateDDHide(!stateDDHide)}
                          >
                            <div className="text-filter-profile">
                              <p className="text-filter-select">
                                {" "}
                                {formik?.values?.state === ""
                                  ? "Province/State"
                                  : formik?.values?.state}
                              </p>

                              <div className="icon-hide-2">
                                {isFormFieldValid("state") ? (
                                  <img src="/image/warning.png" alt="" />
                                ) : (
                                  <img src="/image/form-d-f.png" alt="" />
                                )}
                              </div>
                            </div>
                          </div>

                          <div
                            className={`admin-status-dropdown-profile ${
                              !stateDDHide && stateOptions?.length > 0
                                ? "open"
                                : ""
                            }`}
                          >
                            <div className="searchbox-invite">
                            <label for='job-search-field-10' className="visibility-hidden opacity-0 position-absolute">switch</label>
                              <input
                                name="searchLocation"
                                value={stateSearch}
                                onChange={(e) => searchStateLocation(e)}
                                id="job-search-field-10"
                                className="location-search-field"
                                placeholder="Province/State"
                                autocomplete="off"
                              />
                            </div>

                            {stateOptions?.length > 0 &&
                              !filteredStateItem.length && (
                                <>
                                  <ul className="dorpdown-list-location">
                                    {stateOptions?.map((j, i) => (
                                      <li
                                        className="icon-cursor"
                                        onClick={() =>
                                          stateHandler(j?.id, j?.name)
                                        }
                                      >
                                        <img
                                          src="/image/location-j-place.png"
                                          alt=""
                                        />
                                        {j?.name}
                                      </li>
                                    ))}
                                  </ul>
                                </>
                              )}

                            {filteredStateItem?.length > 0 && (
                              <>
                                <ul className="dorpdown-list-location">
                                  {filteredStateItem.map((j, i) => (
                                    <li
                                      className="icon-cursor"
                                      onClick={() =>
                                        stateHandler(j?.id, j?.name)
                                      }
                                    >
                                      <img
                                        src="/image/location-j-place.png"
                                        alt=""
                                      />
                                      {j?.name}
                                    </li>
                                  ))}
                                </ul>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                      {getFormErrorMessage("state")}
                    </OutsideClickHandler>
                  </div>
                  <div
                    className={`profile-field-f signup-passowrd-job location-profile-list first-name ${
                      (isFormFieldValid("city") && "error-input") ||
                      (citiesOptions?.length === 0 && "disable-field")
                    }`}
                  >
                    <OutsideClickHandler
                      onOutsideClick={() => {
                        setCityDDHide(true);
                      }}
                    >
                      <div className="industry-feild-frm bg-pass-f">
                        <div className="password-icon-logo">
                          <img
                            src="/image/profile-location.png"
                            className="location-icon"
                            alt=""
                          />
                        </div>
                        <div className="profile-location-feild">
                          <div
                            id="dropdown-boxId2"
                            className="profile-form-dropdownBox1"
                            onClick={() => setCityDDHide(!cityDDHide)}
                          >
                            <div className="text-filter-profile">
                              <p className="text-filter-select">
                                {" "}
                                {formik?.values?.city === ""
                                  ? "City"
                                  : formik?.values?.city}
                              </p>

                              <div className="icon-hide-2">
                                {isFormFieldValid("city") ? (
                                  <img src="/image/warning.png" alt="" />
                                ) : (
                                  <img src="/image/form-d-f.png" alt="" />
                                )}
                              </div>
                            </div>
                          </div>
                          <div
                            className={`admin-status-dropdown-profile ${
                              !cityDDHide && citiesOptions?.length > 0
                                ? "open"
                                : ""
                            }`}
                          >
                            <div className="searchbox-invite">
                            <label for='job-search-field-11' className="visibility-hidden opacity-0 position-absolute">switch</label>
                              <input
                                name="searchLocation"
                                value={citySearch}
                                onChange={(e) => searchCityLocation(e)}
                                id="job-search-field-11"
                                className="location-search-field"
                                placeholder="City"
                                autocomplete="off"
                              />
                            </div>

                            {citiesOptions?.length > 0 &&
                              !filteredCityItem.length && (
                                <>
                                  <ul className="dorpdown-list-location">
                                    {citiesOptions?.map((j, i) => (
                                      <li
                                        className="icon-cursor"
                                        onClick={() =>
                                          cityHandler(j?.id, j?.name)
                                        }
                                      >
                                        <img
                                          src="/image/location-j-place.png"
                                          alt=""
                                        />
                                        {j?.name}
                                      </li>
                                    ))}
                                  </ul>
                                </>
                              )}

                            {filteredCityItem?.length > 0 && (
                              <>
                                <ul className="dorpdown-list-location">
                                  {filteredCityItem.map((j, i) => (
                                    <li
                                      className="icon-cursor"
                                      onClick={() =>
                                        cityHandler(j?.id, j?.name)
                                      }
                                    >
                                      <img
                                        src="/image/location-j-place.png"
                                        alt=""
                                      />
                                      {j?.name}
                                    </li>
                                  ))}
                                </ul>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                      {getFormErrorMessage("city")}
                    </OutsideClickHandler>
                  </div>
                </div>
                <div
                  className={`profile-field-f ${
                    isFormFieldValid("phoneNo") && "error-input"
                  }`}
                >
                  <div
                    className={`country-code-profile phone-f-profile  ${
                      isFormFieldValid("phoneNo") && "error-input"
                    }`}
                  >
                    <div className="location-f-icon bg-pass-f">
                      <div className="password-icon-logo">
                        <img
                          src="/image/profile-phone.png"
                          className="profile-icon"
                          alt=""
                        />
                      </div>
                      <PhoneNumber
                        className="form-font-f"
                        id="profile-call"
                        type="tel"
                        name="phoneNo"
                        value={formik.values.phoneNo}
                        maskedPlaceHolder={maskedPlaceHolder}
                        maskedPhoneNumber={maskedPhoneNumber}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      <div className="icon-hide">
                        {isFormFieldValid("phoneNo") && (
                          <img src="/image/warning.png" alt="" />
                        )}
                      </div>
                    </div>
                  </div>
                  {getFormErrorMessage("phoneNo")}
                </div>
                <div
                  className={`url-employee-profile-f profile-field-f ${
                    (isFormFieldValid("websiteUrl") && "error-input") ||
                    (user?.user_type === USER_TYPE.COMPANY_USER &&
                      "disable-field")
                  }`}
                >
                  <div className="location-f-icon bg-pass-f">
                    <div className="password-icon-logo">
                      <img
                        src="/image/profile-website.png"
                        className="profile-icon"
                        alt=""
                      />
                    </div>
                    <input
                      className="form-font-f"
                      id="profile-url-employee"
                      placeholder="Company website link"
                      name="websiteUrl"
                      value={formik.values.websiteUrl}
                      onChange={
                        user?.user_type !== USER_TYPE.COMPANY_USER
                          ? formik.handleChange
                          : () => {}
                      }
                      onBlur={formik.handleBlur}
                    />
                    {isFormFieldValid("websiteUrl") && (
                      <img src="/image/warning.png" alt="" />
                    )}
                  </div>

                  {getFormErrorMessage("websiteUrl")}
                </div>
                <div className={`email-candidate-profile-f profile-field-f`}>
                  <div className="location-f-icon bg-pass-f">
                    <div className="password-icon-logo">
                      <img src="/image/profile-email.png" alt="" />
                    </div>
                    <input
                      className="form-font-f"
                      id="profile-email-candidate"
                      placeholder="E-mail"
                      name="email"
                      value={formik.values.email}
                    />
                    <div className="icon-hide">
                      <img src="/image/locked-field.png" alt="" />
                      <span className="tooltiptext-profile">
                        Contact admin support
                      </span>
                    </div>
                  </div>
                </div>

                <div
                  className={`candidate-link-profile profile-field-f 
                    ${isFormFieldValid("linkedinURL") && "error-input"}`}
                >
                  <div className="location-f-icon bg-pass-f">
                    <div className="password-icon-logo">
                      <img
                        src="/image/profile-linked.png"
                        className="profile-icon"
                        alt=""
                      />
                    </div>
                    <input
                      className="form-font-f"
                      type="text"
                      id="social-link"
                      placeholder="Enter your linkedIn profile here"
                      name="linkedinURL"
                      value={formik.values.linkedinURL}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {isFormFieldValid("linkedinURL") && (
                      <img src="/image/warning.png" alt="" />
                    )}
                  </div>
                  {getFormErrorMessage("linkedinURL")}
                </div>
                <div
                  className={`profile-field-f signup-passowrd-job location-profile-list ${
                    (isFormFieldValid("industry") && "error-input") ||
                    (user?.user_type === USER_TYPE.COMPANY_USER &&
                      "disable-field")
                  }`}
                >
                  <div className="industry-feild-frm bg-pass-f">
                    <div className="password-icon-logo">
                      <img
                        src="/image/building-profile.png"
                        className="location-icon"
                        alt=""
                      />
                    </div>
                    <div
                      id="dropdown-boxId-2"
                      className="company-form-dropdownBox-profile"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      {" "}
                      {formik?.values?.industry === ""
                        ? "Industry"
                        : formik?.values?.industry}
                    </div>
                    <div className="icon-hide">
                      {isFormFieldValid("industry") ? (
                        <img src="/image/warning.png" alt="" />
                      ) : (
                        <img src="/image/form-d-f.png" alt="" />
                      )}
                    </div>
                    {industryOption?.length > 0 &&
                      user?.user_type !== USER_TYPE.COMPANY_USER && (
                        <div
                          id="company-dropList-t"
                          className={`dropdown-form-f company-drop-list-t
                          `}
                        >
                          <ul>
                            {industryOption?.map((j, i) => (
                              <li
                                className="icon-cursor-f"
                                key={i}
                                onClick={() => {
                                  industryHandler(j);
                                }}
                              >
                                <img
                                  src="/image/profile-location.png"
                                  className="location-icon-drop"
                                  alt=""
                                />{" "}
                                {j?.name}
                              </li>
                            ))}
                          </ul>
                        </div>
                      )}
                  </div>
                  {getFormErrorMessage("industry")}
                </div>
                <div
                  className={`profile-field-f signup-passowrd-job location-profile-list ${
                    (isFormFieldValid("companySize") && "error-input") ||
                    (user?.user_type === USER_TYPE.COMPANY_USER &&
                      "disable-field")
                  }`}
                >
                  <div className="industry-feild-frm bg-pass-f">
                    <div className="password-icon-logo">
                      <img
                        src="/image/team-profile.png"
                        className="location-icon"
                        alt=""
                      />
                    </div>
                    <div
                      id="dropdown-boxId-2"
                      className="company-form-dropdownBox-profile"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      {" "}
                      {formik?.values?.companySize === ""
                        ? "Company size"
                        : formik?.values?.companySize}
                    </div>
                    <div className="icon-hide">
                      {isFormFieldValid("companySize") ? (
                        <img src="/image/warning.png" alt="" />
                      ) : (
                        <img src="/image/form-d-f.png" alt="" />
                      )}
                    </div>
                    {companySizeOption?.length > 0 &&
                      user?.user_type !== USER_TYPE.COMPANY_USER && (
                        <div
                          id="company-dropList-t"
                          className={`dropdown-form-f company-drop-list-t
                          `}
                        >
                          <ul>
                            {companySizeOption?.map((j, i) => (
                              <li
                                className="icon-cursor-f"
                                key={i}
                                onClick={() => {
                                  companySizeHandler(j);
                                }}
                              >
                                <img
                                  src="/image/profile-location.png"
                                  className="location-icon-drop"
                                  alt=""
                                />{" "}
                                {j?.name}
                              </li>
                            ))}
                          </ul>
                        </div>
                      )}
                  </div>
                  {getFormErrorMessage("companySize")}
                </div>
              </div>
              <div className="form-block-width">
                <div className="row">
                  <div className="col-md-6 order-md-0 order-1">
                    <button
                      type="button"
                      className="cancel-btn-profile w-100"
                      onClick={() => editHandler(false)}
                    >
                      Cancel
                    </button>
                  </div>
                  <div className="col-md-6 order-md-1 order-0">
                    <button
                      className="save-btn-profilefill w-100 mb-md-0 mb-3"
                      type="submit"
                    >
                      {loader ? (
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        />
                      ) : (
                        "Save"
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmpProfileForm;
