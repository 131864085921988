import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import OutsideClickHandler from "react-outside-click-handler";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import "./UploadImageModel.css";

const UploadImageModel = ({ companyLogoHandler, companyLogoModelHandler }) => {
  const dispatch = useDispatch();
  const [selectedImageName, setSelectedImageName] = useState("");
  const [image, setImage] = useState("");
  const [croppedBlob, setCroppedBlob] = useState(null); // Store the cropped blob

  const cropperRef = useRef();

  const formik = useFormik({
    initialValues: {
      files: null,
    },
    /*validationscheme*/
    validationSchema: Yup.object({
      files: Yup.mixed()
        .required("Please upload a company logo")
        .test(
          "fileType",
          "Please select a valid file format such as .jpeg or .png",
          (value) => !value || ["image/png", "image/jpeg"].includes(value?.type)
        )
        .test(
          "fileSize",
          "The file size should not exceed 10 MB",
          (value) => !value || value?.size <= 10 * 1024 * 1024
        ),
    }),
    onSubmit: async (data, { resetForm }) => {
      getCropData();
    },
  });

  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <p className="candidate-file-res-suberror">{formik.errors[name]}</p>
      )
    );
  };

  const handleImageChange = async (event) => {
    event.preventDefault();
    let file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedImageName(file?.name);
        formik.setFieldValue("files", file);
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      setSelectedImageName("");
      formik.setFieldValue("files", null);
      setImage("");
    }
  };

  const resizeImage = (imageDataUrl, maxWidth, maxHeight) => {
    return new Promise((resolve) => {
      const img = new Image();
      img.src = imageDataUrl;
      img.onload = () => {
        const canvas = document.createElement("canvas");
        let width = img.width;
        let height = img.height;

        if (width > maxWidth) {
          height = (maxWidth / width) * height;
          width = maxWidth;
        }

        if (height > maxHeight) {
          width = (maxHeight / height) * width;
          height = maxHeight;
        }

        canvas.width = width;
        canvas.height = height;

        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, width, height);

        canvas.toBlob((blob) => {
          resolve(blob);
        }, "image/jpeg");
      };
    });
  };

  const getCropData = async () => {
    const cropper = cropperRef.current.cropper;
    if (cropper) {
      cropper.getCroppedCanvas().toBlob(async (blob) => {
        const resizedBlob = await resizeImage(
          URL.createObjectURL(blob),
          300,
          200
        );

        // Check if formik.values.files is not undefined
        if (formik.values.files) {
          const originalFile = formik.values.files;
          console.log("originalFile", originalFile);
          const croppedFile = new File([resizedBlob], originalFile?.name, {
            type: originalFile?.type,
          });

          formik.setFieldValue("files", croppedFile);
          companyLogoHandler(croppedFile);
          companyLogoModelHandler(false);
        }
      }, "image/jpeg");
    }
  };
  return (
    <>
      <section className="model-popup-employee">
        <div className="popup-bg-employee">
          <OutsideClickHandler
            onOutsideClick={() => {
              companyLogoModelHandler(false);
            }}
          >
            <div className="popup-content-employee">
              <div
                className="close-img"
                onClick={() => companyLogoModelHandler(false)}
              >
                <img src="/image/cut-sm.png" alt="" />
              </div>
              <div className="employee-model">
                <p className="model-employee-title">Upload Image</p>
              </div>
              <form onSubmit={formik.handleSubmit}>
                <div className="model-box-poly">
                  <p className="employee-model-content">Accepted Formats</p>
                  <p className="image-type-size">
                    Formats should be .jpeg, .png, .img
                  </p>
                  <div className="employee-img-box">
                    {formik?.errors?.files === undefined && image !== "" ? (
                      <Cropper
                        ref={cropperRef}
                        style={{height: "280px", width: "100%"}}                                
                        zoomable={0.1}
                        initialAspectRatio={1}
                        preview=".img-preview"
                        src={image}
                        viewMode={1}
                        background={false}
                        responsive={true}
                        autoCropArea={1}
                        checkOrientation={false}
                        guides={true}
                        movable={true}
                        cropBoxResizable={false}
                      />
                    ) : (
                      <div className="employee-img-data">
                        <label id="candidate-img">
                          <img src="/image/Upload-icon-poly.png" alt="" />
                          <p className="img-cap">
                            Maximum file size should not exceed 10 MB, & 180px x
                            180px{" "}
                          </p>

                          <p className="brow-btn icon-cursor">Browse File</p>
                          <input
                            id="file-input"
                            type="file"
                            name="files"
                            placeholder="Choose file"
                            onChange={handleImageChange}
                          />
                          {/* <p className="popup-text-model-img">
                          {selectedImageName !== "" ? selectedImageName : ""}                          
                        </p> */}
                        </label>
                        <p className="text-danger mt-2">{formik?.errors?.files}</p>                 
                      </div>
                    )}
                  </div>
                  <div className="employee-img-btns">
                    <a>
                      <button
                        className="cancel-img-btn"
                        onClick={() => companyLogoModelHandler(false)}
                      >
                        Cancel
                      </button>
                    </a>
                    <a className="uplod-btn">
                      <label id="upload-img">
                        <img src="/image/upload-btn.png" alt="" />
                        <button class="img-e-upload" type="submit">
                          Upload
                        </button>
                        {/* <input
                        id="file-input"
                        type="file"
                        name="files"
                        placeholder="Choose file"
                        onChange={handleImageChange}
                      /> */}
                      </label>
                    </a>
                  </div>
                </div>
              </form>
            </div>
          </OutsideClickHandler>
        </div>
      </section>
    </>
  );
};

export default UploadImageModel;
