import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import OutsideClickHandler from "react-outside-click-handler";
import { toast } from "react-toastify";
import { regenerateResumeAIAsync } from "../../../redux/slices/verifyResumeSlice";
import "./ResumeExpModel.css";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { scriptOnlyHTMLTagRegex } from "../../../constants";

const modules = {
  toolbar: [
    ["bold", "italic", "underline"],
    [
      // { list: "unordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false,
  },
};

const formats = ["list"];

// Define a handler for the custom formats to make them readonly
const customFormats = {
  header: { readonly: true },
  list: { readonly: true },
  link: { readonly: true },
};

const ResumeExpModel = ({
  selectedExp,
  modelHandler,
  updateExpByIdHandler,
}) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);

  const [spinner, setSpinner] = useState(false);
  const [genRole, setGenRole] = useState("");
  const [promptInput, setPromptInput] = useState("");

  const [leftCollopse, setLeftCollopse] = useState(true);
  const [rightCollopse, setRightCollopse] = useState(true);

  const formik = useFormik({
    initialValues: {
      company_name: "",
      job_title: "",
      city: "",
      country: "",
      start_date: {
        month: "",
        year: "",
      },
      end_date: {
        month: "",
        year: "",
      },
      roles_n_responsibilities: "",
      years_of_experience: "",
      is_present: false,
      is_correct: false,
      is_remote: false,
      achievements: [],
    },
    /*validationscheme*/
    validationSchema: Yup.object({
    roles_n_responsibilities: Yup.string()
        .matches(scriptOnlyHTMLTagRegex, "Please enter valid roles and responsibilities")         
    }),

    onSubmit: async (data, { resetForm, setErrors }) => {
      updateExpByIdHandler(data);
      modelHandler(false);
    },
  });

  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <p className="error-msg">{formik.errors[name]}</p>
      )
    );
  };

  const isFormNFieldValid = (name) => {
    const [parent, child] = name.split(".");
    return !!(
      formik.touched[parent]?.[child] && formik.errors[parent]?.[child]
    );
  };

  const getFormNErrorMessage = (name) => {
    const [parent, child] = name.split(".");
    return (
      isFormNFieldValid(name) && (
        <p className="error-msg">{formik.errors[parent]?.[child]}</p>
      )
    );
  };

  const promptInputChangeHandler = (e) => {
    const value = e?.target?.value;
    setPromptInput(value);
  };

  const replaceTxtHandler = async () => {
    if (genRole !== "") {
      await formik.setFieldValue(
        "roles_n_responsibilities",
        typeof genRole === "string" ? genRole : ""
      );
    }
  };

  const regenerateResumeAI = async (selectedExp) => {
    const { job_title, roles_n_responsibilities } = selectedExp;
    if (user?.id !== null) {
      setSpinner(true);
      setGenRole("");
      dispatch(
        regenerateResumeAIAsync({
          userId: user?.id,
          jobTitle: job_title,
          sectionName: "experience",
          sectionValue: roles_n_responsibilities,
          promptInput: promptInput,
          aiGen: genRole
        })
      )
        .then(async (response) => {
          setSpinner(false);
          const res = response?.payload;
          const { value } = res?.response;

          if (res?.result === true) {
            const updateRole = typeof value === "string" ? value : "";

            setGenRole(updateRole);
          } else {
            setSpinner(false);
            toast.error(res?.message?.error, {
              autoClose: 2000,
              closeOnClick: false,
            });
          }
        })
        .catch((error) => {
          setSpinner(false);
          console.log(error);
        });
    }
  };

  const getSelectedExpData = async () => {
    await formik.setFieldValue("company_name", selectedExp?.company_name || "");
    await formik.setFieldValue("job_title", selectedExp?.job_title || "");
    await formik.setFieldValue("city", selectedExp?.city || "");
    await formik.setFieldValue("country", selectedExp?.country || "");
    await formik.setFieldValue(
      "start_date",
      selectedExp?.start_date || { month: "", year: "" }
    );
    await formik.setFieldValue(
      "end_date",
      selectedExp?.end_date || { month: "", year: "" }
    );

    await formik.setFieldValue(
      "roles_n_responsibilities",
      typeof selectedExp?.roles_n_responsibilities === "string"
        ? selectedExp?.roles_n_responsibilities
        : ""
    );

    await formik.setFieldValue("is_present", selectedExp?.is_present || false);
    await formik.setFieldValue("is_correct", selectedExp?.is_correct || false);

    await formik.setFieldValue("is_remote", selectedExp?.is_remote || false);
    await formik.setFieldValue(
      "years_of_experience",
      selectedExp?.years_of_experience || ""
    );
    await formik.setFieldValue("achievements", selectedExp?.achievements || []);
  };

  useEffect(() => {
    getSelectedExpData();
    regenerateResumeAI(selectedExp);
  }, []);

  return (
    <section className="model-popup model-popup-3 ">
      <div className="upload-resume-model-jbmdl">
        <div className="model-jd-upld-scroll">
          <OutsideClickHandler
            onOutsideClick={() => {
              modelHandler(false);
            }}
          >
            <div className="popup-resume-jbmdl">
              <div
                className="close-resmodle-file"
                onClick={() => modelHandler(false)}
              >
                <img src="/image/cut-sm.png " alt=" " />
              </div>
              <div className="send-correct-resume">
                <img src="/image/WORKBRIDGE.png" alt=" " />
              </div>
            </div>

            <form onSubmit={formik.handleSubmit}>
              <div className="all-text-jbmdl">
                <div className="two-col-jbmdl">
                  <div className="col-left-jbmdl">
                    <div
                      className="img-text-jbmdl icon-cursor"
                      onClick={() => setLeftCollopse(!leftCollopse)}
                    >
                      <img src="./image/orginaltext-jb.png" alt="" />
                      <div className="text-jb-right">
                        <p className="org-text-right">
                          {selectedExp?.company_name}{" "}
                          {selectedExp?.job_title !== "" &&
                            `(${selectedExp?.job_title})`}
                        </p>
                        <p className="jb-desc-mdl">
                          Original Text (Description of Responsibilities)
                        </p>
                      </div>
                    </div>
                    <div
                      className={`textedditor-jbmdl-exp ${
                        !leftCollopse && "hide-box-left"
                      }`}
                    >
                      <ReactQuill
                        theme="snow"
                        className={`${isFormFieldValid(
                          "txt-editr-jbmdl"
                        )} && "error-input"`}
                        placeholder="Type here"
                        value={formik?.values?.roles_n_responsibilities ?? ""}
                        name="roles_n_responsibilities"
                        modules={modules}
                        formats={formats}
                        onChange={(data) => {
                          formik.setFieldValue(
                            "roles_n_responsibilities",
                            data
                          );
                          formik.handleChange("roles_n_responsibilities");
                        }}
                        onFocus={() => {
                          if (formik?.values?.roles_n_responsibilities === "") {
                            formik.setFieldValue(
                              "roles_n_responsibilities",
                              `<ul><li>${formik?.values?.roles_n_responsibilities}</li></ul>`
                            );
                          }
                        }}
                        onBlur={() => {
                          formik.handleBlur("roles_n_responsibilities");
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-right-jbmdl">
                    <div
                      className="img-text-jbmdl-right icon-cursor"
                      onClick={() => setRightCollopse(!rightCollopse)}
                    >
                      <img src="/image/ai-text-jb.png" alt="" />
                      <div className="text-jb-right-r">
                        <p className="org-text-right-r">
                          Recommendations Generated by AI
                        </p>
                        <p className="jb-desc-mdl">
                          Description of Responsibilities.
                        </p>
                      </div>
                    </div>
                    <div className="toottip-job-mdl">
                      <img src="/image/ai-jb-mdl.png" alt="" />
                      <div className="tooltip-outer-box">
                        <p className="tootl-tip-text-jbmdl">
                          Copy individual bullet points from the AI section or
                          select Replace Original Text to update.{" "}
                        </p>
                      </div>
                    </div>
                    <div
                      className={`textedditor-jbmdl-exp ${
                        !rightCollopse && "hide-box-right"
                      }`}
                    >
                      <ReactQuill
                        theme="snow"
                        className={`
                      "txt-editr-jbmdl"
                    `}
                        placeholder="Type here"
                        value={genRole ?? ""}
                        name="genRole"
                        modules={modules}
                        formats={formats}
                        readOnly={true}
                      />
                    </div>
                  </div>
                </div>
                <div className="two-btn-jbmdl">
                  <button
                    type="text"
                    className="replace-res"
                    onClick={(e) => {
                      e.preventDefault();
                      replaceTxtHandler();
                    }}
                  >
                    Replace Original Text
                    {/* <img src="/image/repl-text-res.png" alt="" /> */}
                  </button>
                  <button className="regenerate-res" type="submit">
                    Save
                  </button>
                </div>
                <div className="text-user-f">
                  <textarea
                    id=""
                    cols="30"
                    rows="1"
                    className="user-inp-jbmdl"
                    name="promptInput"
                    type="text"
                    placeholder="Type your custom prompt here..."
                    value={promptInput}
                    onChange={promptInputChangeHandler}
                  />
                  <button
                    type="text"
                    className="res-img-btn"
                    onClick={(e) => {
                      e.preventDefault();
                      regenerateResumeAI(formik?.values);
                    }}
                  >
                    {spinner ? (
                      <span
                        className="spinner-border spinner-border-md text-white"
                        role="status"
                        aria-hidden="true"
                      />
                    ) : (
                      <img src="/image/reg-white.png" alt="" />
                    )}
                  </button>
                </div>
              </div>
            </form>
          </OutsideClickHandler>
        </div>
      </div>
    </section>
  );
};

export default ResumeExpModel;
