import OutsideClickHandler from "react-outside-click-handler";
import "./AddCanListModel.css";

const AddCanListModel = ({
  loader,
  myLists,
  selectCandidateMyListsId,
  modelHandler,
  selectCandidateMyListsIdHandler,
  candidateListSearchHandler,
  addCandidateToListHandler,
}) => {
  return (
    <>
      <div className="modal-body">
      <div className="mt-0 heading-list">
            <h3>Add to list</h3>
            {" "}
            <img src="/image/table-list.png" alt="" />
          </div>  
        <div className="search-box-list">
          <img src="/image/job-search.png" alt="" />
          <input
            type="text"
            name=""
            className="form-font-f"
            id="jon-search-box"
            placeholder="Search"
            onChange={(e) => candidateListSearchHandler(e)}
          />
        </div>
        <div className="list-radio-add">
          {myLists?.length > 0 &&
            myLists?.map((item, index) => (
              <div className="radio-list-name">
                <label for="" className="list-lab-clr">
                  {" "}
                  <input
                    type="radio"
                    name=""
                    id=""
                    className="list-select-b"
                    checked={
                      item?.id === selectCandidateMyListsId ? true : false
                    }
                    onClick={() =>
                      selectCandidateMyListsIdHandler(item?.id)
                    }
                  />
                  {item?.name}
                </label>
                {/* <img src="/image/table-list.png" alt="" /> */}
              </div>
            ))}
        </div>
      </div>
      <div className="modal-footer">
        <button
          className="btn btn-black"
          type="button"
          onClick={() => modelHandler(false)}
        >
          Cancel
        </button>
        <button
          className="btn btn-outline-black"
          type="submit"
          onClick={() => addCandidateToListHandler()}
        >
          {loader ? (
            <span
              className="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            />
          ) : (
            "Save"
          )}
        </button>
      </div>
    </>
  );
};

export default AddCanListModel;
