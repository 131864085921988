import { useEffect, useState } from "react";
import ReactHtmlParser from "react-html-parser";

const JobDetailSec = ({ label, description }) => {

  return (
    <>
      {description && (
        <div className="job-heading-detail-sec">
          <h5>{label}</h5>
          <div className="view-job-detail-space-2">
          {ReactHtmlParser(description)}
          </div>
        </div>
      )}
    </>
  );
};

export default JobDetailSec;
