import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";
import NFormatter, { NJobFormatter } from "../../../utils/NFormatter";
import { setSelectJobId } from "../../../redux/slices/JobDetailSlice";
import { setSelectCompanyId } from "../../../redux/slices/companyDetailSlice";
import "./JobRecord.css";
import { formatSalary } from "../../../utils/formatSalary";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import { getColor } from "../../../utils/progressbarFn";
import { markAsReadInboxDataAsync, setInboxUser } from "../../../redux/slices/InboxSlice";
import { setInboxCount } from "../../../redux/slices/AuthSlice";
import OutsideClickHandler from "react-outside-click-handler";

const JobRecordLeft = ({
  hideCompanyName,
  activeSwitch,
  radioBtn,
  jobCounter,
  index,
  JobData,
  onChangeJobStatus,
  addMemberinJob,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [switchBtn, setSwitchBtn] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [statusToggle, setStatusToggle] = useState(false);
  const { user, inboxCount } = useSelector((state) => state.auth);
  const onSwitchChange = () => {
    setSwitchBtn(!switchBtn);
    onChangeJobStatus(JobData?.id);
  };

  const onDetailJobHandler = (id) => {
    const slug = JobData?.slug;
    const hashId = JobData?.hashed_id;
    if (slug && hashId) {
      navigate(`/jobs/${slug}/?currentJobId=${hashId}`);
    }
  };

  const onDetailCompanyHandler = (hashed_id) => {
    if (hashed_id) {
      navigate(`/company-detail/?currentCompanyId=${hashed_id}`);
    }
  };

  const addMemberToJob = (jodId) => {
    addMemberinJob(jodId);
  };

  const handleInbox = (conversation , conversationData) => {
    if (conversationData) {
      if (conversation) {
        dispatch(markAsReadInboxDataAsync({ conversation_id: conversationData?.id }))
          .then((response) => {
            const res = response?.payload;
            if (res?.success === true) {
              const  currentInboxCount = inboxCount; 
              if(currentInboxCount > 0){
                dispatch(setInboxCount(currentInboxCount - 1));
              }           
            }
            dispatch(setInboxUser(conversationData));
            navigate("/candidate-inbox");
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        dispatch(setInboxUser(conversationData));
        navigate("/candidate-inbox");
      }
    }
  };

  useEffect(() => {
    setSwitchBtn(radioBtn);
    // console.log(user);
  }, []);

  if (!JobData) return null;

  return (
    <>
      <div className="job-requriments">
        <div className="job-details">
          <div
            className={`job-title justify-content-between align-items-center flex-nowrap`}
          >
            <div className="d-md-flex gap-2 align-items-center flex-row">
              {JobData?.full_title || JobData?.name ? (
                <>
                  <a
                    className="title-job-link promoted-btn-not"
                    onClick={() => onDetailJobHandler(JobData?.id)}
                  >
                    <h3>
                      {JobData?.full_title ? JobData?.full_title : JobData?.name}
                    </h3>
                  </a>
                  {JobData?.is_promoted === 1 && (
                    <button className=" promoted-job-btn">Promoted * </button>
                  )}
                </>
              ) : ''}
            </div>
            <div className="other-options d-md-none d-flex">
              {user?.user_type === "candidate" ? (
                <div className="dd-listitem-inbox-msg position-relative">
                <button className="btn p-0" onClick={() => {setShowMenu(!showMenu)}}>
                  <img
                    src="/image/ellipses.png"
                    alt="éllipses"
                    className=""
                  />
                </button>
                <div
                  className={showMenu ? "cv-cions active" : "cv-cions"}
                >
                  <OutsideClickHandler
                    onOutsideClick={() => {
                      setShowMenu(false); setStatusToggle(false)
                    }}
                  >
                    <ul>
                      <li className={`text-nowrap ${
                          JobData?.hiringStatus?.length -1 &&  "d-none"
                        }`} onClick={() => setStatusToggle(!statusToggle)}>
                        Hiring status
                        <img src="/image/icon-status.png" alt="icon-status" />
                      </li>
                      <li className={`${
                        JobData?.conversation === null && "chat-op"
                        }`} onClick={() => handleInbox(JobData?.conversation, JobData?.conversationData)}>
                        Inbox
                        <img src="/image/chat-icon.png" alt='chat-icon' />
                        {JobData?.conversation !== null && JobData?.conversation > 0 && (
                          <span>{JobData?.conversation}</span>
                        )}
                      </li>
                    </ul>
                  </OutsideClickHandler>
                </div>                  
                </div>
              ) : (
                ""
              )}
              <div
                onClick={() => setStatusToggle(!statusToggle)}
              >
                {
                  statusToggle && (<div className="status-box">
                      <div className="hiring-status-box">
                        <h3>Hiring status</h3>
                        <div className="status-content">
                          <img src="./image/icon-nodes.png" alt="chat" />
                          <p>{JobData?.hiringStatus?.name}</p>
                        </div>
                      </div>
                  </div>)
                }
              </div>
              {/* onClick={addRemoveCand} */}
              {activeSwitch ? (
                <>
                  <img
                    src="/image/add-member.png"
                    alt=""
                    onClick={() => addMemberToJob(JobData?.id)}
                  />
                  <div
                    className={
                      "loction-switch-col d-md-none d-block p-0" +
                      (JobData?.promoted ? "mt-1" : "")
                    }
                  >
                    <label className="switch" for={`${JobData?.full_title.replace(/\s+/g, '_')}-${JobData?.id}-mob`}>
                      <input
                        type="checkbox"
                        checked={switchBtn}
                        id={`${JobData?.full_title.replace(/\s+/g, '_')}-${JobData?.id}-mob`}
                        onChange={() => onSwitchChange()}
                      />
                      <span className="visibility-hidden opacity-0 position-absolute">switch</span>
                      <span className="slider"></span>
                    </label>
                  </div>
                </>
              ) : (
                ""
              )}
            </div>
          </div>

          {hideCompanyName ? (
            <></>
          ) : (
            <div className="job-services-anchor">
              <a
                onClick={() =>
                  onDetailCompanyHandler(JobData?.company?.hashed_id)
                }
              >
                <img                  
                      src={
                        JobData?.company?.logo ?
                           JobData?.company?.logo                      
                            : '/image/avtar-company.png'                        
                      }
                  alt="fitenss"
                  className="d-md-block d-none"
                />
                <p className="job-companyName">{JobData?.company?.name}</p>
              </a>
            </div>
          )}
        </div>
        <div className="job-info">
          <div className="job-time d-md-flex d-none">
            <div className="d-flex">
              <p>
                {JobData?.salary_from && (
                  <span class="me-5">
                    {" "}
                    {JobData?.salary_from &&
                      JobData?.salary_from !== null &&
                      "Salary "}
                    {JobData?.salary_from && JobData?.salary_from !== null
                      ? JobData?.currency[0]?.title
                        ? ` ${JobData?.currency[0]?.title}`
                        : JobData?.currency?.title
                        ? JobData?.currency?.title
                        : JobData?.currency?.name
                        ? JobData?.currency?.name
                        : ""
                      : ""}
                    {JobData?.salary_from &&
                      ` ${
                        JobData?.currency[0]?.title
                          ? formatSalary(
                              JobData?.salary_from,
                              JobData?.currency[0]?.title,
                              "",
                              false
                            )
                          : JobData?.currency?.title
                          ? formatSalary(
                              JobData?.salary_from,
                              JobData?.currency?.title,
                              "",
                              false
                            )
                          : formatSalary(
                              JobData?.salary_from,
                              JobData?.currency?.name,
                              "",
                              false
                            )
                      }`}{" "}
                    {JobData?.salary_to &&
                      ` - ${
                        JobData?.currency[0]?.title
                          ? formatSalary(
                              JobData?.salary_to,
                              JobData?.currency[0]?.title,
                              JobData?.salary_range,
                              true
                            )
                          : JobData?.currency?.title
                          ? formatSalary(
                              JobData?.salary_to,
                              JobData?.currency?.title,
                              JobData?.salary_range,
                              true
                            )
                          : formatSalary(
                              JobData?.salary_to,
                              JobData?.currency?.title,
                              JobData?.salary_range,
                              true
                            )
                      }`}
                  </span>
                )}
              </p>

              <p className="d-inline-block">
                {JobData?.jobType?.length > 0 && JobData?.jobType[0]?.name}
              </p>
            </div>
            {jobCounter && (
              <div className="application-dash">
                <a>
                  <img src="/image/application-dash.png" alt="fitenss" />
                  <p>
                    {JobData?.applicants_count > 0
                      ? `${JobData?.applicants_count} Applicant${
                          JobData?.applicants_count > 1 ? "s" : ""
                        }`
                      : "0 Applicant"}
                  </p>
                </a>
              </div>
            )}
          </div>
          <div
            className={`job-stats-container d-flex d-md-none ${
              JobData?.salary_from ? "gap-1" : ""
            }`}
          >
            {JobData?.salary_from ? (
              <>
                <button className="purple-tags">
                  {" "}
                  {JobData?.salary_from &&
                    JobData?.salary_from !== null &&
                    "Salary "}
                  {JobData?.salary_from && JobData?.salary_from !== null
                    ? JobData?.currency[0]?.title
                      ? ` ${JobData?.currency[0]?.title}`
                      : JobData?.currency?.title
                      ? JobData?.currency?.title
                      : JobData?.currency?.name
                      ? JobData?.currency?.name
                      : ""
                    : ""}
                  {JobData?.salary_from &&
                    ` ${
                      JobData?.currency[0]?.title
                        ? formatSalary(
                            JobData?.salary_from,
                            JobData?.currency[0]?.title,
                            "",
                            false
                          )
                        : JobData?.currency?.title
                        ? formatSalary(
                            JobData?.salary_from,
                            JobData?.currency?.title,
                            "",
                            false
                          )
                        : formatSalary(
                            JobData?.salary_from,
                            JobData?.currency?.name,
                            "",
                            false
                          )
                    }`}{" "}
                  {JobData?.salary_to &&
                    ` - ${
                      JobData?.currency[0]?.title
                        ? formatSalary(
                            JobData?.salary_to,
                            JobData?.currency[0]?.title,
                            JobData?.salary_range,
                            true
                          )
                        : JobData?.currency?.title
                        ? formatSalary(
                            JobData?.salary_to,
                            JobData?.currency?.title,
                            JobData?.salary_range,
                            true
                          )
                        : formatSalary(
                            JobData?.salary_to,
                            JobData?.currency?.title,
                            JobData?.salary_range,
                            true
                          )
                    }`}
                </button>{" "}
                <span className="w-100"></span>
              </>
            ) : (
              ""
            )}
            {JobData?.jobCities ? (
              <button className="purple-tags">
                <p className="location-head">Location</p>
                <img src="/image/location-blue.png" alt="Location" />
                <p>
                  {JobData?.jobCities?.length === 1
                    ? JobData?.jobCities[0]?.name
                    : "Multiple Cities"}
                </p>
              </button>
            ) : (
              ""
            )}
            {JobData?.jobType ? (
              <button className="purple-tags">
                {JobData?.jobType?.length > 0 && JobData?.jobType[0]?.name}
              </button>
            ) : (
              ""
            )}
          </div>

          <div className="job-discription job-description-mg">
            {" "}
            {JobData?.description?.length >= 250 ? (
              <>
                {ReactHtmlParser(JobData?.description?.substr(0, 250) + "...")}
              </>
            ) : (
              <>{ReactHtmlParser(JobData?.description)}</>
            )}{" "}
          </div>

          {jobCounter && (
            <div className="application-dash application-dash-hide">
              <a>
                <img src="/image/application-dash.png" alt="fitenss" />
                <p>
                  {JobData?.applicants_count > 0
                    ? `${JobData?.applicants_count} Applicant${
                        JobData?.applicants_count > 1 ? "s" : ""
                      }`
                    : "0 Applicant"}
                </p>
              </a>
            </div>
          )}
        </div>
        <div className="job-skill-location">
          <div className="job-skills">
            {JobData?.tags?.length > 0 &&
              JobData?.tags?.map((t, i) => {
                if (i < 5) {
                  return (
                    <button key={i} className="museo-font tag">
                      {t?.name}
                    </button>
                  );
                }
              })}
          </div>

          {/* <div className="job-location-new"> */}
          {activeSwitch ? (
            JobData?.jobCities?.length !== 0 ? (
              <div className="job-location-switch">
                <div className="location-job-table">
                  <p>Location</p>
                  <img src="/image/Location.png" alt="Location" />
                  <p className="job-city">
                    {JobData?.jobCities?.length === 1
                      ? JobData?.jobCities[0]?.name
                      : "Multiple Cities"}
                  </p>
                </div>

                <div className="loction-switch-col">
                  <label className="switch" for={`${JobData?.full_title.replace(/\s+/g, '-')}-${JobData?.id}-2`}>
                    <input
                      type="checkbox"
                      id={`${JobData?.full_title.replace(/\s+/g, '-')}-${JobData?.id}-2`}
                      checked={switchBtn}
                      onChange={() => onSwitchChange()}
                    />
                    <span className="slider"></span>
                    <span className="visibility-hidden opacity-0 position-absolute">switch</span>
                  </label>
                </div>
              </div>
            ) : (
              <div className="loction-switch-col">
                <label className="switch" for='switch-3'>
                  <input
                    type="checkbox"
                    checked={switchBtn}
                    onChange={() => onSwitchChange()}
                    id='switch-3'
                  />
                  <span className="slider"></span>
                </label>
              </div>
            )
          ) : (
            JobData?.jobCities?.length !== 0 && (
              <div className="job-location">
                <p>Location</p>
                <img src="/image/Location.png" alt="Location" />
                <p className="job-city ">
                  {JobData?.jobCities?.length === 1
                    ? JobData?.jobCities[0]?.name
                    : "Multiple Cities"}
                </p>
              </div>
            )
          )}
          {/* </div> */}
        </div>
      </div>
    </>
  );
};

export default JobRecordLeft;
