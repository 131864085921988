import React, { useState, useEffect } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import { useDispatch } from "react-redux";
import { setProgressModel } from "../../../redux/slices/JobDecisionSlice";
import { closeDivModal } from "../../../redux/slices/JobPostZoeModalSlice";
import "./PostJobProgressModel.css";

const PostJobProgressModel = ({ handleNoButton }) => {
  const dispatch = useDispatch();

  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      if (progress < 100) {
        setProgress((prevProgress) => prevProgress + 1);
      } else {
        clearInterval(interval);
        dispatch(setProgressModel(false));
      }
    }, 250); // Update every 300 milliseconds for a total duration of 30 seconds

    return () => {
      clearInterval(interval);
    };
  }, [progress]);

  return (
    <div className="modal-body">

      <div className="generating-decs text-start px-3 pb-md-4 pb-3">
        <p className="mb-2">PolyCareers AI feature</p>
        <p className="job-decs-sub-heading">Just a moment please, writing your job description.</p>
        <div className="text-center">
          <img className="my-md-5 my-2 zeo-img-size" src="/image/job-decs-img.gif" alt="" />
        </div>
        <p>A quick dance while you wait for PolyCareers AI to create your ideal job description.</p>
      </div>      
    </div>
  );
};

export default PostJobProgressModel;
