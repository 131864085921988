export const BASE_API = `https://qa.polycareers.com/backend/public/api/v1`;

export const BASE_API_ADMIN = `https://qa.polycareers.com/backend/public/api/v1/admin/v1`;


export const BASE_API_DYNAMIC = `https://${window.location.host}/backend/public/api/v1`;

export const BASE_API_ADMIN_DYNAMIC = `https://${window.location.host}/backend/public/api/v1/admin/v1`;




