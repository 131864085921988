import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import TablePagination from "../../../../components/Common/Admin/TablePagination/TablePagination";
import ShowRows from "../../../../components/Common/Admin/ShowRows/ShowRows";
import TableError from "../../../../components/Common/Admin/TabelError/TableError";
import {
  getBulkResumeReqDetailDataAsync,
  lowFitResumeAnalysisAsync,
  setResumeCandidateId,
  setResumeCandidateTab,
} from "../../../../redux/slices/bulkResumeCanDetailSlice";
import "./index.css";
import getCurrentFormattedDate from "../../../../utils/getCurrentFormattedDate";
import {
  getColor,
  getProgressBarColorClass,
  getProgressBarWidth,
  getProgressTxtColor,
} from "../../../../utils/progressbarFn";
import {
  BULK_REQ_DETAIL_SORT,
  EmptyRecord,
  PARSING_STATUS,
  SORT_DIRECTION,
} from "../../../../constants";
import GuageChart from "../../JobPosted/PreScreeningResult/GuageChart";
import { toast } from "react-toastify";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";

const BulkResumeReqDetail = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { processId } = useSelector((state) => state.bulkResumeCanDetail);
  const [loader, setLoader] = useState(false);
  const [msg, setMsg] = useState("");
  const [chartData, setChartData] = useState({
    labels: ["Wrong", "Correct"],
    datasets: [
      {
        label: "Overall Result",
        data: ["100", "0"], // Default 0% filled
        borderWidth: 5,
        backgroundColor: ["#ECECEC", "#009FB5"], // Progress bar colors
        cutout: "75%", // This creates the gauge/doughnut shape
        circumference: 240, // Makes it a half-circle
        rotation: -120, // Starts at the top
      },
    ],
  });
  const [processDetails, setProcessDetails] = useState({});
  const [bulkResumeData, setBulkResumeData] = useState([]);
  const [search, setSearch] = useState("");
  const [sortBy, setSortBy] = useState("");
  const [sortDirection, setSortDirection] = useState("");

  const [resumeStatus, setResumeStatus] = useState([]);
  const [processStatus, setProcessStatus] = useState([]);

  const [selectedResumeStatus, setSelectedResumeStatus] = useState({
    label: "",
    value: null,
  });

  const [selectedProcessStatus, setSelectedProcessStatus] = useState({
    label: "",
    value: null,
  });

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [perPage, setPerPage] = useState(10);

  const navHandler = () => {
    navigate("/bulk-resume-analysis");
  };

  const onSearchChange = (value) => {
    setSearch(value);
  };

  const onResumeStatusClick = (label, value) => {
    setSelectedResumeStatus({
      label: label,
      value: value,
    });
  };

  const onResumeStatusClean = () => {
    setSelectedResumeStatus({
      label: "",
      value: null,
    });
  };

  const onProcessStatusClick = (label, value) => {
    setSelectedProcessStatus({
      label: label,
      value: value,
    });
  };

  const onProcessStatusClean = () => {
    setSelectedProcessStatus({
      label: "",
      value: null,
    });
  };

  const onPageChange = (page) => {
    if (page >= 1 && page <= totalPages) setCurrentPage(page);
  };

  const onChangePerPageHandler = (value) => {
    setPerPage(value);
  };

  const onSortByHandler = (value) => {
    if (sortBy === value) {
      setSortDirection((prevDirection) =>
        prevDirection === SORT_DIRECTION.ASC
          ? SORT_DIRECTION.DESC
          : SORT_DIRECTION.ASC
      );
    } else {
      setSortDirection(SORT_DIRECTION.DESC);
    }
    setSortBy(value);
  };

  const calculateProgressValue = (item) => {
    if (item?.overall_match !== undefined && item?.overall_match !== null) {
      return parseInt(item.overall_match, 10);
    }
    return 0;
  };

  /*****************************/

  const onAnalyzeClick = async (item) => {
    if (processId !== null) {
      try {
        dispatch(
          lowFitResumeAnalysisAsync({
            process_id: item?.process_id,
            candidate_id: item?.candidate_id,
            file_path: item?.file_path,
          })
        )
          .then(async (response) => {
            const res = response?.payload;

            if (res?.success === true) {
              getReqDetailData();
              toast.success(res?.message?.info, {
                autoClose: 2000,
                closeOnClick: false,
              });
            } else {
              toast.warning(res?.message?.error, {
                autoClose: 2000,
                closeOnClick: false,
              });
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } catch (error) {
        console.log(error);
      }
    }
  };

  const getReqDetailData = async () => {
    if (processId !== null) {
      try {
        dispatch(
          getBulkResumeReqDetailDataAsync({
            process_id: processId,
            search: search,
            status: selectedResumeStatus?.value,
            processing_status: selectedProcessStatus?.value,
            currentPage: currentPage,
            perPage: perPage,
            sortBy: sortBy,
            sortDirection: sortDirection,
          })
        )
          .then(async (response) => {
            const res = response?.payload;

            if (res?.success === true) {
              const { process_details, resumes, dropdowns, last_page } =
                res?.data;
              const { resumeFailedPercent, resumeSuccessPercent } =
                process_details;
              setProcessDetails(process_details);
              setBulkResumeData(resumes);
              setResumeStatus(dropdowns?.status_data);
              setProcessStatus(dropdowns?.processing_status_data);
              setTotalPages(last_page);

              if (resumeFailedPercent || resumeSuccessPercent) {
                setChartData({
                  labels: ["Wrong", "Correct"],
                  datasets: [
                    {
                      label: "Overall Result",
                      data: [
                        resumeFailedPercent ? resumeFailedPercent : 0,
                        resumeSuccessPercent ? resumeSuccessPercent : 0,
                      ], // 65% filled, 35% empty
                      borderWidth: 5,
                      backgroundColor: ["#ECECEC", "#009FB5"], // Progress bar colors
                      cutout: "75%", // This creates the gauge/doughnut shape
                      circumference: 240, // Makes it a half-circle
                      rotation: -120, // Starts at the top
                    },
                  ],
                });
              }

              if (
                resumes?.length === 0 &&
                search === "" &&
                selectedResumeStatus?.value === null &&
                selectedProcessStatus?.value === null
              )
                setMsg("Polycareers AI is processing your request.");
              else if (
                resumes?.length === 0 &&
                (search !== "" ||
                  selectedResumeStatus?.value !== null ||
                  selectedProcessStatus?.value !== null)
              )
                setMsg("Please make another selection. No record found.");
              else {
                if (resumes?.length !== 0) setMsg("");
              }
            }

            setLoader(false);
          })
          .catch((error) => {
            console.log(error);
          });
      } catch (error) {
        setLoader(false);
        console.log(error);
      }
    }
  };

  useEffect(() => {
    getReqDetailData();
  }, [
    selectedResumeStatus,
    selectedProcessStatus,
    search,
    currentPage,
    perPage,
    sortBy,
    sortDirection,
  ]);

  useEffect(() => {
    setLoader(true);
    getReqDetailData();
    setLoader(false);
  }, []);

  const options = {
    responsive: true,
    plugins: {
      datalabels: {
        color: "black",
        anchor: "end",
        align: "top",
        display: function (context) {
          return context.dataset.data[context.dataIndex] > 15;
        },
        font: {
          weight: "600",
          size: 28,
        },
      },
      legend: {
        display: false,
        position: "bottom",
        align: "center",
        labels: {
          padding: 30,
          color: "#14151a",
          usePointStyle: true,
          pointStyleWidth: 20,
          font: {
            size: 13,
            weight: 200,
          },
        },
      },
      tooltip: {
        enabled: false,
      },
    },
    rotation: -90, // Start angle for the chart (half-circle)
    circumference: 180, // Makes it a gauge (half-circle)
    position: "relative",
  };
  return (
    <div classNameName="bg-clr-admin">
      <div className="page-space bg-clr-admin">
        <div className="form-heading-prom">
          <img
            src="/image/form-back-arrow.png"
            alt="back arrow"
            onClick={() => navHandler()}
          />
          <h2>Request detail</h2>
        </div>

        {loader ? (
          <div className="loader-div">
            <span
              className="spinner-border spinner-border"
              role="status"
              aria-hidden="true"
            />
          </div>
        ) : (
          <>
            <div className="res-file-added">
              <div className="file-res-admin">
                <img src="/image/file-bg-icon.png" alt="" />
                <div className="name-job-res-admin">
                  <p className="file-name-res">
                    {" "}
                    {processDetails?.job_title ? processDetails?.job_title : ""}
                  </p>
                  <p className="job-title-admin">
                    {" "}
                    {processDetails?.file_name ? processDetails?.file_name : ""}
                  </p>
                </div>
              </div>
              <div className="date-created-admin">
                Date created:{" "}
                <span>
                  {" "}
                  {processDetails?.date_created
                    ? getCurrentFormattedDate(processDetails?.date_created)
                    : ""}
                </span>
              </div>
            </div>
            <div className="two-card-admin-request">
              <div className="card-left-request">
                <div className="top-part-img-row">
                  <img src="/image/res-admin.png" alt="" />
                  <p className="name-image-left">Good fit resume analyzed </p>
                </div>
                <div className="progress-circle-status">
                  <div className="progress-bar-res-admin">
                    {processDetails && chartData?.datasets ? (
                      <div className="chart-container">
                        <GuageChart data={chartData} options={options} />
                      </div>
                    ) : (
                      ""
                    )}

                    <div className="test-percent">
                      <img src="/image/res-bg-admin.png" alt="testResult" />
                      <p className="res-progress-socre">
                        {processDetails?.resumeSuccessPercent
                          ? `${Math.floor(
                              processDetails?.resumeSuccessPercent
                            )}%`
                          : "0%"}
                      </p>
                    </div>
                  </div>
                  <div className="resum-status-admin-list">
                    <ul className="res-process-resukt-list">
                      <li>
                        <span className="black-status-list"></span>
                        <div className="status-res-list-admin">
                          <p className="left-list-res-admin">Total Resumes</p>
                          <p className="res-number-b">
                            {" "}
                            {processDetails?.no_of_resumes
                              ? processDetails?.no_of_resumes
                              : "0"}
                          </p>
                        </div>
                      </li>
                      <li>
                        <span className="green-status-list"></span>
                        <div className="status-res-list-admin">
                          <p className="left-list-res-admin">
                            Processing status
                          </p>
                          <p
                            className={`name-image-left-below-w ${getProgressTxtColor(
                              processDetails?.process_status?.toLowerCase()
                            )}`}
                          >
                            {" "}
                            {processDetails?.process_status
                              ? processDetails?.process_status
                              : ""}
                          </p>
                        </div>
                      </li>
                      <li>
                        <span className="green-status-list"></span>
                        <div className="status-res-list-admin">
                          <p className="left-list-res-admin">Resume analyzed</p>
                          <p className="res-number-green">
                            {" "}
                            {processDetails?.completed_resumes
                              ? processDetails?.completed_resumes
                              : "0"}
                          </p>
                        </div>
                      </li>
                      <li>
                        <span className="red-status-list"></span>
                        <div className="status-res-list-admin">
                          <p className="left-list-res-admin">Not readable</p>
                          <p className="res-number-red">
                            {" "}
                            {processDetails?.error_resumes
                              ? processDetails?.error_resumes
                              : "0"}
                          </p>
                        </div>
                      </li>
                      <li>
                        <span className="oringe-status-list"></span>
                        <div className="status-res-list-admin">
                          <p className="left-list-res-admin">Low fit resumes</p>
                          <p className="res-number-org">
                            {" "}
                            {processDetails?.low_fit_count
                              ? processDetails?.low_fit_count
                              : "0"}
                          </p>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <div>
                  <p className="card-bulk-request-detail">
                    Resumes with low-fit scores (below 50%) are flagged as 'Not
                    a Fit,' and no detailed AI analysis is provided. If you
                    still want to analyze these resumes, you can sort and
                    process them separately for further review.
                  </p>
                </div>
              </div>
              <div className="card-right-request">
                <div className="top-part-img-row">
                  <img src="/image/time-admin.png" alt="" />
                  <p className="name-image-left">Total time</p>
                </div>
                <div className="progress-circle-status">
                  <div>
                    <img src="/image/time-admin-bg.png" alt="" />
                  </div>
                  <div className="resum-status-admin-list">
                    <ul className="res-process-resukt-list">
                      <li>
                        <span className="ble-right-status-list"></span>
                        <div className="status-res-list-admin">
                          <p className="left-list-res-admin">
                            Process start time
                          </p>
                          <p className="res-right-b">
                            {" "}
                            {processDetails?.process_start_time
                              ? processDetails?.process_start_time
                              : ""}
                          </p>
                        </div>
                      </li>
                      <li>
                        <span className="ble-right-status-list"></span>
                        <div className="status-res-list-admin">
                          <p className="left-list-res-admin">
                            Process end time
                          </p>
                          <p className="res-right-b">
                            {" "}
                            {processDetails?.process_status?.toLowerCase() ===
                              PARSING_STATUS.COMPLETED &&
                            processDetails?.process_ended_at ? (
                              processDetails?.process_ended_at
                            ) : (
                              <div className="dash-td-por" />
                            )}
                          </p>
                        </div>
                      </li>
                      <li>
                        <span className="ble-right-status-list"></span>
                        <div className="status-res-list-admin">
                          <p className="left-list-res-admin">
                            Total process time
                          </p>
                          <p className="res-right-b">
                            {" "}
                            {processDetails?.process_status?.toLowerCase() ===
                              PARSING_STATUS.COMPLETED &&
                            processDetails?.total_process_minutes ? (
                              processDetails?.total_process_minutes
                            ) : (
                              <div className="dash-td-por" />
                            )}
                          </p>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <div>
                  <p className="card-bulk-request-detail">
                    The estimated process time does not include the time spent
                    to identify the low fit resume. Only resume that will be
                    analyzed are included in the estimated process time.
                  </p>
                </div>
              </div>
            </div>

            <div className="filter-search-bar">
              <div className="two-drop-role">
                <div className="col-left-adminfilter">
                  <p className="filter-label-admin">Resume status</p>
                  <div className="admin-status-filter">
                    <div
                      id="dropdown-boxId2"
                      className="company-form-dropdownBox1"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <div className="text-filter-admin">
                        <p
                          className={` ${
                            selectedResumeStatus?.label !== "" &&
                            "text-filter-select"
                          }`}
                        >
                          {" "}
                          {selectedResumeStatus?.label === ""
                            ? "Resume status"
                            : selectedResumeStatus?.label}
                        </p>
                        {selectedResumeStatus?.label === "" ? (
                          <img src="/image/admin-d.png" alt="" />
                        ) : (
                          <div
                            id="dropdown-boxId2"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            <img
                              src="/image/cross-port.png"
                              alt=""
                              onClick={() => onResumeStatusClean()}
                            />
                          </div>
                        )}
                      </div>
                    </div>

                    {resumeStatus?.length > 0 && (
                      <div className="promotion-status-dropdown">
                        <ul className="list-job-title-company">
                          {resumeStatus?.map((item, index) => (
                            <li
                              key={index}
                              onClick={() => {
                                onResumeStatusClick(item?.label, item?.value);
                              }}
                            >
                              {item?.label}
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-left-adminfilter">
                  <p className="filter-label-admin">Process status</p>
                  <div className="admin-status-filter">
                    <div
                      id="dropdown-boxId2"
                      className="company-form-dropdownBox1"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <div className="text-filter-admin">
                        <p
                          className={` ${
                            selectedProcessStatus?.label !== "" &&
                            "text-filter-select"
                          }`}
                        >
                          {" "}
                          {selectedProcessStatus?.label === ""
                            ? "Process status"
                            : selectedProcessStatus?.label}
                        </p>
                        {selectedProcessStatus?.label === "" ? (
                          <img src="/image/admin-d.png" alt="" />
                        ) : (
                          <div
                            id="dropdown-boxId2"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            <img
                              src="/image/cross-port.png"
                              alt=""
                              onClick={() => onProcessStatusClean()}
                            />
                          </div>
                        )}
                      </div>
                    </div>

                    {processStatus?.length > 0 && (
                      <div className="promotion-status-dropdown">
                        <ul className="list-job-title-company">
                          {processStatus?.map((item, index) => (
                            <li
                              key={index}
                              onClick={() => {
                                onProcessStatusClick(item?.label, item?.value);
                              }}
                            >
                              {item?.label}
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="col-right-searcgadmin">
                <div className="search-admin">
                  <img src="/image/job-search.png" alt="" />
                  <input
                    type="text"
                    name="search"
                    id="jon-search-box"
                    placeholder="Search"
                    className="form-font-f"
                    onChange={(e) => {
                      onSearchChange(e?.target?.value);
                    }}
                  />
                </div>
              </div>
            </div>
            <ShowRows
              perPage={perPage}
              onChangePerPageHandler={onChangePerPageHandler}
            />

            <div className="department-table-imge">
              <div className="col-user-table">
                <div className="table-responsive">
                  <table className="depart-table-left">
                    <thead className="tb-heading-user">
                      <tr>
                        <th scope="col">
                          <div className="th-admin-heading">
                            Resume filename
                          </div>
                        </th>
                        <th scope="col">
                          <div className="th-admin-heading">
                            Candidate name{" "}
                            <img
                              className="arrow-icon icon-cursor"
                              src={
                                sortBy ===
                                  BULK_REQ_DETAIL_SORT.CANDIDATE_NAME &&
                                sortDirection === SORT_DIRECTION.DESC
                                  ? "/image/bars-arrow-down.png"
                                  : "/image/bars-arrow-up.png"
                              }
                              alt=""
                              onClick={() =>
                                onSortByHandler(
                                  BULK_REQ_DETAIL_SORT.CANDIDATE_NAME
                                )
                              }
                            />
                          </div>
                        </th>
                        <th scope="col">
                          <div className="th-admin-heading">Resume status </div>
                        </th>
                        <th scope="col">
                          <div className="th-admin-heading">
                            Overall match{" "}
                            <img
                              className="arrow-icon icon-cursor"
                              src={
                                sortBy ===
                                  BULK_REQ_DETAIL_SORT.OVERALL_MATCH &&
                                sortDirection === SORT_DIRECTION.DESC
                                  ? "/image/bars-arrow-down.png"
                                  : "/image/bars-arrow-up.png"
                              }
                              alt=""
                              onClick={() =>
                                onSortByHandler(
                                  BULK_REQ_DETAIL_SORT.OVERALL_MATCH
                                )
                              }
                            />
                          </div>
                        </th>
                        <th scope="col">
                          <div className="th-admin-heading">
                            Process status{" "}
                            <img
                              className="arrow-icon icon-cursor"
                              src={
                                sortBy ===
                                  BULK_REQ_DETAIL_SORT.PROCESS_STATUS &&
                                sortDirection === SORT_DIRECTION.DESC
                                  ? "/image/bars-arrow-down.png"
                                  : "/image/bars-arrow-up.png"
                              }
                              alt=""
                              onClick={() =>
                                onSortByHandler(
                                  BULK_REQ_DETAIL_SORT.PROCESS_STATUS
                                )
                              }
                            />
                          </div>
                        </th>
                        <th scope="col">
                          <div className="th-admin-heading">
                            Date processed{" "}
                            <img
                              className="arrow-icon icon-cursor"
                              src={
                                sortBy ===
                                  BULK_REQ_DETAIL_SORT.DATE_PROCESSED &&
                                sortDirection === SORT_DIRECTION.DESC
                                  ? "/image/bars-arrow-down.png"
                                  : "/image/bars-arrow-up.png"
                              }
                              alt=""
                              onClick={() =>
                                onSortByHandler(
                                  BULK_REQ_DETAIL_SORT.DATE_PROCESSED
                                )
                              }
                            />
                          </div>
                        </th>

                        <th scope="col" className="th-size-seven">
                          <div className="th-admin-heading">Start process</div>
                        </th>

                        <th scope="col" className="th-size-seven">
                          <div className="th-admin-heading">Actions</div>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="tb-user">
                      {bulkResumeData?.length > 0 &&
                        bulkResumeData?.map((item, index) => {
                          const progressValue = calculateProgressValue(item);
                          return (
                            <tr>
                              <td className="">{item?.resume_filename}</td>
                              <td>
                                {EmptyRecord.includes(item?.candidate_name) ? (
                                  <div className="dash-td-por" />
                                ) : (
                                  item?.candidate_name
                                )}
                              </td>
                              <td className="">
                                {item?.process_status && item?.process_status}
                              </td>
                              <td className="lr match">
                                <div className="prog-bar-cir">
                                  <div className="progress-circle-req-detail"
                                  >
                                    <CircularProgressbar
                                      minValue="0"
                                      strokeWidth={12}
                                      value={progressValue}
                                      text={`${progressValue}%`}
                                      counterClockwise={false}
                                      styles={buildStyles({
                                        strokeLinecap: "round",
                                        pathTransitionDuration: 0.5,
                                        pathColor: getColor(progressValue),
                                        textColor: getColor(progressValue),
                                        textSize: "24px",
                                        trailColor: "#d6d6d6",
                                      })}
                                    />
                                  </div>
                                </div>
                              </td>
                              <td>
                                {item?.processing_status !== null &&
                                  item?.processing_status}
                                <div className="progress">
                                  <div
                                    className={`progress-bar progress-bar-color ${getProgressBarColorClass(
                                      item?.processing_status?.toLowerCase()
                                    )}`}
                                    role="progressbar"
                                    style={{
                                      width: getProgressBarWidth(
                                        item?.processing_status?.toLowerCase()
                                      ),
                                    }}
                                    aria-valuenow={parseInt(
                                      getProgressBarWidth(
                                        item?.processing_status?.toLowerCase()
                                      )
                                    )}
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                  ></div>
                                </div>
                              </td>
                              <td>
                                {getCurrentFormattedDate(item?.date_processed)}
                              </td>
                              <td>
                                {item?.process_status?.toLowerCase() ===
                                PARSING_STATUS.LOWFIT ? (
                                  <button
                                    className="btn btn-analyze btn-black"
                                    onClick={() => onAnalyzeClick(item)}
                                  >
                                    Analyze
                                  </button>
                                ) : (
                                  <></>
                                )}
                              </td>
                              <td>
                                <div className="four-resume-img">
                                  <div className="img-text-tooltip">
                                    <img
                                      src="/image/resume-bulk-admin.png"
                                      alt=""
                                      className={`show-tooltip icon-cursor ${
                                        item?.processing_status?.toLowerCase() !==
                                          PARSING_STATUS.COMPLETED &&
                                        "bulk-resume-disable-icon"
                                      }`}
                                      onClick={() => {
                                        if (
                                          item?.candidate_id !== null &&
                                          item?.processing_status?.toLowerCase() ===
                                            PARSING_STATUS.COMPLETED
                                        ) {
                                          dispatch(
                                            setResumeCandidateId(
                                              item?.candidate_id
                                            )
                                          );
                                          dispatch(
                                            setResumeCandidateTab("resume")
                                          );
                                          navigate("/bulk-resume-ai-analysis");
                                        }
                                      }}
                                    />
                                    <div className="tooltip-jd-resume">
                                      Resume
                                    </div>
                                  </div>
                                  <div className="img-text-tooltip">
                                    <img
                                      src="/image/ai-admin.png"
                                      alt=""
                                      className={`show-tooltip2 icon-cursor ${
                                        item?.processing_status?.toLowerCase() !==
                                          PARSING_STATUS.COMPLETED &&
                                        "bulk-resume-disable-icon"
                                      }`}
                                      onClick={() => {
                                        if (
                                          item?.candidate_id !== null &&
                                          item?.processing_status?.toLowerCase() ===
                                            PARSING_STATUS.COMPLETED
                                        ) {
                                          dispatch(
                                            setResumeCandidateId(
                                              item?.candidate_id
                                            )
                                          );
                                          dispatch(
                                            setResumeCandidateTab(
                                              "candidate-evaluation"
                                            )
                                          );
                                          navigate("/bulk-resume-ai-analysis");
                                        }
                                      }}
                                    />
                                    <div className="tooltip-request-analysis-res">
                                      AI analysis
                                    </div>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
                {bulkResumeData?.length > 0 && (
                  <TablePagination
                    totalPages={totalPages}
                    currentPage={currentPage}
                    onPageChange={onPageChange}
                  />
                )}
                <TableError msg={msg} />
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default BulkResumeReqDetail;
