import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import CanProfileForm from "../../../components/Candidate/Profile/CanProfileForm/CanProfileForm";
import CanProfileView from "../../../components/Candidate/Profile/CanProfileView/CanProfileView";
import FileUploadModel from "../../../components/Common/FileUploadModel/FileUploadModel";
import WorldConnect from "../../../components/Common/WorldConnect/WorldConnect";
import UploadImageModel from "../../../components/Common/UploadImageModel/UploadImageModel";
import ContentModal from "../../../components/Common/ContentModal/ContentModal";
import ChooseResumeModel from "../../../components/Common/ChooseResumeModel/ChooseResumeModel";
import ResumeProgressModel from "../../../components/Common/ResumeProgressModel/ResumeProgressModel";

import { setResumeUrl } from "../../../redux/slices/candidateDashboardSlice";
import {
  getCountriesAsync,
  setLoginUserCountry,
  setLoginUserName,
  setUserResumes,
  setUserSalary,
} from "../../../redux/slices/AuthSlice";
import { getResumeForApplyingOnJob } from "../../../redux/slices/candidateDetailSlice";
import ProfileSection from "../../../components/Candidate/Profile/ProfileSection";
import ProfileAmenity from "../../../components/Candidate/Profile/ProfileAmenity";
import API from "../../../api";
import APIPvtDB from "../../../apiPvtD";
import Button from "react-bootstrap/Button";
import { EmptyRecord, RESUME_MODE } from "../../../constants";
import { setResumeFormMode } from "../../../redux/slices/verifyResumeSlice";
import "./index.css";

const CanProfile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [countryOptions, setCountryOptions] = useState();
  const { user, resumes } = useSelector((state) => state.auth);

  const [loader, setLoader] = useState(false);
  const [loaderAI, setLoaderAI] = useState(false);

  const [errorMsg, setErrorMsg] = useState("");
  const [editable, setEditable] = useState(false);
  const [toggleResumeModel, setToggleResumeModel] = useState(false);
  const [toggleCustomResumeModel, setToggleCustomResumeModel] = useState(false);
  const [jobTitles, setTobTitles] = useState([]);
  const [workPreferences, setWorkPreferences] = useState([]);
  const [salaryRange, setSalaryRange] = useState("");
  const [tags, setTags] = useState([]);
  const [customResume, setCustomResume] = useState([]);
  const [selectedImageName, setSelectedImageName] = useState("");

  const [companyLogoModel, setCompanyLogoModel] = useState(false);
  const [companyLogo, setCompanyLogo] = useState(null);

  const [maskedPhoneNumber, setMaskedPhoneNumber] = useState("");
  const [maskedPlaceHolder, setMaskedPlaceHolder] = useState("");

  const [statesCount, setStatesCount] = useState(0);

  const [baseInfo, setBaseInfo] = useState({
    first_name: "",
    last_name: "",
    email: "",
    country: "",
    countryId: null,
    state: "",
    stateId: null,
    city: "",
    cityId: null,
    currency: "",
    currencyId: null,
    phone: "",
    linkedin: null,
    file: null,
    avatar: null,
    bio: null,
    my_interests: null,
    my_motivations: null,
    not_interested: null,
    intro_video_link: null,
    selectedVideoName: "",
    fileSize: null,
    latest_resume: null,
    percentage: null
  });

  /* Event Handler*/

  const modelResumeHandler = (v) => setToggleResumeModel(v);
  const modelCustomResumeHandler = (v) => setToggleCustomResumeModel(v);

  const token = localStorage.getItem("token");

  const editHandler = (editValue) => {
    setEditable(editValue);
    window?.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const goBack = () => {
    if (editable) {
      editHandler(false);
    } else {
      navigate(-1);
    }
  };

  const companyLogoModelHandler = (value) => {
    setCompanyLogoModel(value);
  };

  const companyLogoHandler = (file) => {
    setCompanyLogo(file);
  };

  const loaderAIHandler = (toggleModel) => {
    setLoaderAI(toggleModel);
  };

  const onCreateResumeHandler = () => {
    if (resumes?.length >= 5) {
      toast.warning(
        "The maximum number of resume allowed in the system is 5. Please delete an existing resume to continue.",
        {
          autoClose: 2000,
          closeOnClick: false,
        }
      );
      return;
    } else {
      dispatch(setResumeFormMode(RESUME_MODE.CREATE));
      navigate("/candidate-resume");
    }
  };

  const onUploadResumeHandler = () => {
    if (resumes?.length >= 5) {
      toast.warning(
        "The maximum number of resume allowed in the system is 5. Please delete an existing resume to continue.",
        {
          autoClose: 2000,
          closeOnClick: false,
        }
      );
      return;
    } else {
      modelResumeHandler(true);
    }
  };

  const changePasswordHandler = () => {
    navigate("/new-password");
  };

  const submitHandler = async (
    data,
    user,
    selectJobTitlesNames,
    selectSkillTagsNames,
    selectWorkPreferenceNames,
    onSuccess
  ) => {
    const {
      firstName,
      lastName,
      countryId,
      stateId,
      cityId,
      phoneNo,
      linkedinURL,
      aboutSelf,
      interested,
      files,
      introVideo,
      selectedVideoName,
      fileSize,
      salaryExpectation,
      salaryRange,
      salaryStart,
      currencyId,
    } = data;

    console.log(data);

    try {
      const formData = new FormData();
      formData.append("id", user?.id);
      formData.append("first_name", firstName?.trim());
      formData.append("last_name", lastName?.trim());
      formData.append("phone", phoneNo);
      formData.append("country_id", countryId);
      stateId !== null && formData.append("state_id", stateId);
      cityId !== null && formData.append("city_id", cityId);
      formData.append("linkedin", linkedinURL);

      !EmptyRecord.includes(salaryRange) &&
      formData.append("salary_range", salaryRange);
      formData.append("expected_salary", salaryExpectation);
      formData.append("currency_id", currencyId);
      aboutSelf !== "" && formData.append("bio", aboutSelf);
      interested !== "" && formData.append("my_interests", interested);
      files !== null && files !== undefined && formData.append("avatar", files);
      
      introVideo !== null &&
        introVideo !== undefined &&
        formData.append("intro_video", introVideo);
      
      selectedVideoName !== "" &&
        selectedVideoName !== undefined &&      
        formData.append("fileName", selectedVideoName);

      fileSize !== null &&
        fileSize !== undefined &&
        formData.append("fileSize", fileSize);

      selectJobTitlesNames !== null &&
        selectJobTitlesNames.forEach((val) => {
          // console.log(val)

          formData.append("job_title[]", val);
        });

      selectSkillTagsNames !== null &&
        selectSkillTagsNames.forEach((val) => {
          formData.append("tech_tags[]", val);
        });

      selectWorkPreferenceNames !== null &&
        selectWorkPreferenceNames.forEach((val) => {
          formData.append("work_preferences[]", val);
        });

      setLoader(true);

      await API.post("/auth/update-candidate-profile", formData)
        .then((response) => {
          if (response?.status === 200 || response?.status === 201) {
            const res = response?.data;
            if (res?.result === true) {
              dispatch(setLoginUserName(firstName?.trim()));
              dispatch(setUserSalary({
                expected_salary: salaryExpectation ? salaryExpectation : "0.00",
                salary_range: !EmptyRecord.includes(salaryRange) ? salaryRange: null,
              }));
              getUserProfile();
              editHandler(false);              
              toast.success(res?.message?.success, {
                autoClose: 2000,
                closeOnClick: false,
              });
            }        
            else if((res?.result === false)){
              const errors = res?.message;
              const firstErrorKey = Object?.keys(errors)[0];
              const firstErrorMessage = errors[firstErrorKey][0];
              toast.error(firstErrorMessage, {
                autoClose: 2000,
                closeOnClick: false,
              });
            }       
            setLoader(false);           
          }
          if (onSuccess) onSuccess(); 
        })
        .catch((error) => {
          if (error) {
            const { response } = error;
            const { data } = response;
            toast.error(data?.message, {
              autoClose: 2000,
              closeOnClick: false,
            });
            setErrorMsg(data?.message);
            setTimeout(() => {
              setErrorMsg("");
            }, 5000);
            console.log(error);
          }
          setLoader(false);
        });
    } catch (error) {
      setLoader(false);
      console.log(error);
    }
  };

  const resumeFileHandler = async (file, selectedImageName) => {
    // setBaseInfo({ ...baseInfo, file: file });
    setSelectedImageName(selectedImageName);
    onUpdateResume(file);
  };

  const getResumes = async (token) => {
    if(token){
      dispatch(
        getResumeForApplyingOnJob({token: token})
      )
        .then(async (response) => {
          const res = response?.payload?.data;
          dispatch(setUserResumes(res));
        })
        .catch((error) => {
          console.log(error);
        });
      }
  };

  const onUpdateResume = async (file) => {
    if (user?.id) {
      try {
        setLoaderAI(true);
        const formData = new FormData();
        formData.append("candidate_id", user?.id);
        formData.append("resume", file);

        await API.post(`/auth/update-resume`, formData)
          .then((response) => {
            if (response?.status === 200 || response?.status === 201) {
              const res = response?.data;
              const token = localStorage.getItem("token");

              if (res?.result === true) {
                modelResumeHandler(false);
                toast.success(res?.message?.success, {
                  autoClose: 2000,
                  closeOnClick: false,
                });
                getResumes(token);
                navigate("/my-resumes");
                setLoaderAI(false);
              } else {
                setLoaderAI(false);
                toast.error(res?.message?.error, {
                  autoClose: 2000,
                  closeOnClick: false,
                });
              }            
            } else {
              setLoaderAI(false);
              toast.error(response?.message?.error, {
                autoClose: 2000,
                closeOnClick: false,
              });
            }
          })
          .catch((error) => {
            const { response } = error;
            const { data } = response;
            toast.error(data?.error, {
              autoClose: 2000,
              closeOnClick: false,
            });
            setLoaderAI(false);
            modelResumeHandler(false);
          });
      } catch (error) {
        setLoaderAI(false);
        modelResumeHandler(false);
        console.log(error);
      }
    }
  };

  const onDeleteCustomResume = async (customResumeId) => {
    if (user?.id) {
      try {
        const formData = new FormData();
        formData.append("resume_id", customResumeId);
        await API.post(`/auth/delete-resume`, formData)
          .then(async (response) => {
            if (response?.status === 200 || response?.status === 201) {
              const res = response?.data;
              if (res?.result === true) {
                let successMsg = res?.message?.success;

                const updatedCustomResume = customResume?.filter(
                  (item, index) => item?.id !== customResumeId
                );

                if (successMsg) {
                  toast.success(successMsg, {
                    autoClose: 2000,
                    closeOnClick: false,
                  });
                }

                setCustomResume(updatedCustomResume);
                await getResumes(token);
              }
            }
          })
          .catch((error) => {});
      } catch (error) {
        console.log(error);
      }
    }
  };

  const onSubmitAboutSelf = async (value) => {
    console.log(value)
    if (user?.id) {
      try {
        const formData = new FormData();
        formData.append("id", user?.id);
        formData.append("bio", value);

        await API.post(`/auth/update-about-me`, formData)
          .then((response) => {
            if (response?.status === 200 || response?.status === 201) {
              const result = response?.data?.result;
              if (result === true) {
                getUserProfile();
              }
            }
          })
          .catch((error) => {
            if (error) {
              console.log(error);
            }
          });
      } catch (error) {
        console.log(error);
      }
    }
  };

  const onSubmitInterested = async (value) => {
    if (user?.id) {
      try {
        const formData = new FormData();
        formData.append("id", user?.id);
        formData.append("my_interests", value);

        await API.post(`/auth/update-my-interests`, formData)
          .then((response) => {
            if (response?.status === 200 || response?.status === 201) {
              const result = response?.data?.result;
              if (result === true) {
                getUserProfile();
              }
            }
          })
          .catch((error) => {
            if (error) {
              console.log(error);
            }
          });
      } catch (error) {
        console.log(error);
      }
    }
  };

  const onSubmitNotInterested = async (value) => {
    if (user?.id) {
      try {
        const formData = new FormData();
        formData.append("id", user?.id);
        formData.append("not_interested", value);

        await API.post(`/auth/update-not-interested`, formData)
          .then((response) => {
            if (response?.status === 200 || response?.status === 201) {
              const result = response?.data?.result;
              if (result === true) {
                getUserProfile();
              }
            }
          })
          .catch((error) => {
            if (error) {
              console.log(error);
            }
          });
      } catch (error) {
        console.log(error);
      }
    }
  };
  const onSubmitMotivation = async (value) => {
    if (user?.id) {
      try {
        const formData = new FormData();
        formData.append("id", user?.id);
        formData.append("my_motivations", value);

        await API.post(`/auth/update-my-motivations`, formData)
          .then((response) => {
            if (response?.status === 200 || response?.status === 201) {
              const result = response?.data?.result;
              if (result === true) {
                getUserProfile();
              }
            }
          })
          .catch((error) => {
            if (error) {
              console.log(error);
            }
          });
      } catch (error) {
        console.log(error);
      }
    }
  };

  const onSubmitIntroVideo = async (value) => {
    if (user?.id) {
      try {
        const formData = new FormData();
        formData.append("id", user?.id);
        value !== null &&
          value !== undefined &&
          formData.append("intro_video", value);

        await API.post(`/auth/update-intro-video`, formData)
          .then((response) => {
            if (response?.status === 200 || response?.status === 201) {
              const result = response?.data?.result;
              if (result === true) {
                getUserProfile();
              }
            }
          })
          .catch((error) => {
            if (error) {
              console.log(error);
            }
          });
      } catch (error) {
        console.log(error);
      }
    }
  };

  const getUserProfile = async () => {
    if (user?.id) {
      try {
        await APIPvtDB.get(`/auth/get-profile`)
          .then((response) => {
            if (response?.status === 200) {
              let res = response?.data;
              const { latest_resume, city, state, userCountry } = res;
              const {
                currency,
                masked_phone_number,
                placeholder,
                states_count,
              } = userCountry;

              setBaseInfo({
                first_name: res?.first_name,
                last_name: res?.last_name,
                email: res?.email,
                phone: res?.phone,
                countryId: res?.userCountry?.id,
                country: res?.userCountry?.name,
                state: state ? state?.name : "",
                stateId: state ? state?.id : null,
                city: city ? city?.name : "",
                cityId: city ? city?.id : null,
                currency: currency ? currency?.title : "",
                currencyId: currency ? currency?.id : null,
                linkedin: res?.linkedin,
                file: res?.resume,
                avatar: res?.avatar,
                bio: res?.bio,
                expected_salary: res?.expected_salary,
                salary_range: res?.salary_range,
                my_motivations: res?.my_motivations,
                my_interests: res?.my_interests,
                not_interested: res?.not_interested,
                intro_video_link: res?.intro_video_link,
                selectedVideoName: res?.intro_video_file_name,
                fileSize: res?.intro_video_file_size,
                workPreferences: res?.userWorkPreferences,
                latest_resume: latest_resume?.resume_link,
                percentage: res?.percentage
              });

              dispatch(
                setLoginUserCountry({
                  id: userCountry?.id,
                  name: userCountry?.name,
                })
              );
              setMaskedPlaceHolder(placeholder);
              setMaskedPhoneNumber(masked_phone_number);
              setStatesCount(states_count);


              dispatch(setResumeUrl(latest_resume?.resume_link));

              if (res?.job_title?.length > 0) {
                setTobTitles(res?.job_title);
              } else {
                setTobTitles([]);
              }

              if (res?.userWorkPreferences?.length > 0) {
                setWorkPreferences(res?.userWorkPreferences);
              } else {
                setWorkPreferences([]);
              }

              if (res?.salary_range) {
                setSalaryRange(res?.salary_range);
              } else {
                setSalaryRange("");
              }

              if (res?.tech_tags?.length > 0) {
                setTags(res?.tech_tags);
              } else {
                setTags([]);
              }
              if (res?.custom_resume?.length > 0) {
                setCustomResume(res?.custom_resume);
              } else {
                setCustomResume([]);
              }
            }
          })
          .catch((error) => {
            if (error) {
              console.log(error);
            }
          });
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
     getUserProfile();
    if(token){
      getResumes(token);
    }

  }, []);

  useEffect(() => {
    async function getCountries() {
      try {
        dispatch(getCountriesAsync({ country_id: null }))
          .then((response) => {
            const res = response?.payload;
            setCountryOptions(res);
          })
          .catch((error) => {
            console.log(error);
          });
      } catch (error) {
        console.log(error);
      }
    }

    getCountries();
  }, []);

  const sectionEdit = (type, data) => {
    console.log(type, data)
    if (type === "goals") {
      onSubmitAboutSelf(data);
    }
    if (type === "interest") {
      onSubmitInterested(data);
    }
    if (type === "motivation") {
      onSubmitMotivation(data);
    }
    if (type === "not_intrested") {
      onSubmitNotInterested(data);
    }
  };

  for (const key in baseInfo) {
    if (
      !(
        baseInfo[key] !== null &&
        baseInfo[key] !== undefined &&
        baseInfo[key] !== ""
      )
    ) {
    } else {
      return (
        <>
          <section className="profile-edit pt-0">
            <div className="container container-site py-2">
              <Button
                variant="link"
                className="back-to-home add top-0 position-relative"
                onClick={() => goBack()}
              >
                <span className="visibility-hidden opacity-0 position-absolute">Go back</span>
                <img src="/image/form-back-arrow.png" alt="" />
              </Button>
            </div>
            <div className="job-heading">
              <div className="container-site">
                <h2>My profile</h2>
              </div>
            </div>
          </section>
          <section className="candidate-profile-sec">
            <div className="container container-site">
              <div className="space-inner-row">
                <div className="profile-card">
                  {editable === false && (
                    <button
                      type="button"
                      className="btn btn-transparent edit-btn-profile"
                      onClick={(e) => {
                        e.preventDefault();
                        editHandler(true)
                      }
                      }
                    >
                      <span className="visibility-hidden opacity-0 position-absolute">Edit</span>
                      <img src="/image/pen.png" alt="" />
                    </button>
                  )}

                  <div className="row">
                    <div className="col-md-9">
                      {editable ? (
                        <CanProfileForm
                          loader={loader}
                          baseInfo={baseInfo}
                          jobTitles={jobTitles}
                          tags={tags}
                          editable={editable}
                          companyLogo={companyLogo}
                          maskedPlaceHolderP={maskedPlaceHolder}
                          maskedPhoneNumberP={maskedPhoneNumber}
                          statesCount={statesCount}
                          editHandler={editHandler}
                          companyLogoModelHandler={companyLogoModelHandler}
                          submitHandler={submitHandler}
                        />
                      ) : (
                        <CanProfileView
                          baseInfo={baseInfo}
                          jobTitles={jobTitles}
                          workPreferences={workPreferences}
                          salaryRange={salaryRange}
                          tags={tags}
                          countryOptions={countryOptions}
                          onSubmitAboutSelf={onSubmitAboutSelf}
                          onSubmitInterested={onSubmitInterested}
                          onSubmitIntroVideo={onSubmitIntroVideo}
                        />
                      )}
                    </div>
                    <div className="col-md-3 d-md-flex d-none">
                      {!editable ? (
                        <div className="d-flex gap-2 flex-wrap pt-5 profile-action-btn">
                          <button
                            className="btn btn-outline-black w-100 p-0 btn-height-profile"                        
                            onClick={() => onCreateResumeHandler()}
                          >
                            Create resume
                          </button>
                          <button
                            className="btn btn-outline-black w-100 btn-height-profile"
                            onClick={() => onUploadResumeHandler()}
                          >
                            <label id="candidate-img">                 
                              <p className="file-btn">
                                Upload resume{" "}
                                {resumes?.length === 0 && (
                                  <img src="/image/caution-icon.png" alt="" />
                                )}
                              </p>
                            </label>
                          </button>
                          <button
                            className="btn btn-outline-black w-100 p-0 btn-height-profile"
                            onClick={() => navigate("/my-resumes")}                       
                          >
                            My resumes
                          </button>

                          <button
                            className="btn btn-outline-black w-100 p-0 btn-height-profile"
                            onClick={() => changePasswordHandler()}                           
                          >
                            Change password
                          </button>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
                {!editable && (
                  <>
                    <div className="profile-card">
                      <ProfileSection
                        sectionType="goals"
                        editMethod={sectionEdit}
                        heading="Career goals"
                        description={baseInfo.bio}
                        defaultText="This section provides an opportunity to share more about your career goals."
                        tooltip="My career goal is to advance into a leadership role within the software development industry, where I can leverage my technical skills and mentor junior developers to drive impactful projects."
                      />
                    </div>
                    <div className="profile-card">
                      <ProfileSection
                        sectionType="motivation"
                        editMethod={sectionEdit}
                        heading="What’s motivating you to look for a new job?"
                        description={baseInfo.my_motivations}
                        defaultText="Discuss what factors are driving your search for a new opportunity, whether it’s career growth, a desire for new challenges, or a change in company culture."
                        tooltip="I’m seeking a new job to broaden my skill set and take on more responsibilities. I'm eager to tackle new challenges and contribute to a company that values innovation and collaboration."
                      />
                    </div>
                    <div className="profile-card">
                      <ProfileSection
                        sectionType="interest"
                        editMethod={sectionEdit}
                        heading="My key skills & interests"
                        description={baseInfo.my_interests}
                        defaultText="Identify your core competencies and passions that align with roles you’re pursuing."
                        tooltip="My key strengths lie in problem-solving, attention to detail, and adaptability. I'm particularly interested in projects that involve data analysis and optimization, where I can apply my analytical skills to drive measurable results."
                      />
                    </div>
                    <div className="profile-card">
                      <ProfileSection
                        sectionType="not_intrested"
                        editMethod={sectionEdit}
                        heading="Things I’m not interested in"
                        description={baseInfo.not_interested}
                        defaultText="Outline any tasks or roles that don’t align with your skills, preferences, or career goals."
                        tooltip="While I have experience in full-stack development, my passion lies more in backend development. I'm not interested in roles that primarily focus on frontend development or UI/UX design."
                      />
                    </div>
                    <div className="profile-card">
                      <ProfileAmenity
                        loader={loader}
                        baseInfo={baseInfo}
                        jobTitles={jobTitles}
                        tags={tags}
                        editable={editable}
                        companyLogo={companyLogo}
                        editHandler={editHandler}
                        companyLogoModelHandler={companyLogoModelHandler}
                        submitHandler={submitHandler}
                        salaryRange={salaryRange}
                        workPreferences={workPreferences}
                      />
                    </div>
                  </>
                )}

                <div className="d-md-none d-block">
                  {!editable ? (
                    <div className="d-flex gap-2 flex-wrap profile-action-btn">
                      <button
                        className="btn btn-outline-black w-100 btn-height-profile"
                        onClick={() => onCreateResumeHandler()}                      
                      >
                        Create resume
                      </button>

                      <button
                        className="btn btn-outline-black w-100"
                        onClick={() => onUploadResumeHandler()}
                      >
                        <label id="candidate-img">                 
                              <p className="file-btn">
                                Upload resume{" "}
                                {resumes?.length === 0 && (
                                  <img src="/image/caution-icon.png" alt="" />
                                )}
                              </p>
                        </label>
                      </button>
                      <button
                        className="btn btn-outline-black w-100 btn-height-profile"
                        onClick={() => navigate("/my-resumes")}                       
                      >
                        My resumes
                      </button>
                      <button
                        className="btn btn-outline-black w-100"
                        onClick={() => changePasswordHandler()}
                      >
                        Change password
                      </button>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </section>
          <WorldConnect />
          {companyLogoModel && (
            <UploadImageModel
              companyLogoHandler={companyLogoHandler}
              companyLogoModelHandler={companyLogoModelHandler}
            />
          )}

          {toggleResumeModel && (
            <FileUploadModel
              modelHandler={modelResumeHandler}
              resumeFileHandler={resumeFileHandler}
            />
          )}

          {toggleCustomResumeModel && (
            <ContentModal size="lg" closeModal={modelCustomResumeHandler}>
              <ChooseResumeModel
                customResume={customResume}
                modelHandler={modelCustomResumeHandler}
                onDeleteCustomResume={onDeleteCustomResume}
              />
            </ContentModal>
          )}
          {loaderAI && (
            <ContentModal size="lg" closeModal={loaderAIHandler}>
              <ResumeProgressModel
                heading="Just a moment please, while we are uploading your resume."
                content="A quick dance while you wait for polycareer’s AI to upload your resume."
                modelHandler={() => loaderAIHandler(false)}
              />
            </ContentModal>
          )}
        </>
      );
    }
  }
};

export default CanProfile;
