import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import ReactHtmlParser from "react-html-parser";
import { setSelectJobId } from "../../../../redux/slices/JobDetailSlice";
import { setSelectCompanyId } from "../../../../redux/slices/companyDetailSlice";
import getDays from "../../../../utils/getDays";

const TopMatchLeft = ({ selectedJobId, slug, hashId, JobData }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onDetailJobHandler = () => {
    if(slug && hashId){
    navigate(`/jobs/${slug}/?currentJobId=${hashId}`);
    }
  };

  const onDetailCompanyHandler = (hashed_id) => {
    if(hashed_id){
      navigate(`/company-detail/?currentCompanyId=${hashed_id}`);
    }
  };

  return (
    <>
      <div className="short-content-left job-col-left py-3">
        <div className="job-title-left">
          <div
            className={`job-services-col-left  ${JobData?.is_promoted === 1
              ? "job-services-col-left-w-2"
              : "job-services-col-left-w"
              }`}
          >
            <a onClick={() => onDetailCompanyHandler(JobData?.company?.hashed_id)}>
              <img  src={
                        JobData?.company?.logo ?
                           JobData?.company?.logo                      
                            : '/image/avtar-company.png'                        
                      } alt="fitenss" />
              <p className="job-companyName">{JobData?.company?.name}</p>
            </a>

          </div>
          {JobData?.is_promoted === 1 && (
            <Link href="#">
              <button className="promoted-job-btn">Promoted * </button>
            </Link>
          )}
        </div>
        <Link href="#">
          <h3>{JobData?.full_title ? JobData?.full_title : JobData?.name}</h3>
        </Link>

        <p className="job-type-name">{JobData?.jobType?.length > 0 && JobData?.jobType[0]?.name}</p>

        <p className="job-type-heading">{JobData?.job_types?.length > 0 && JobData?.job_types[0]?.name}</p>
        <br />
        <p className="job-discription-left">
          {JobData?.description?.length >= 65 ? (
            <>{ReactHtmlParser(JobData?.description?.substr(0, 65) + "...")}</>
          ) : (
            <>{ReactHtmlParser(JobData?.description)}</>
          )}{" "}
        </p>
        <button onClick={() => onDetailJobHandler()} className="view-job-btn">View job</button>
      </div>
    </>
  );
};

export default TopMatchLeft;
