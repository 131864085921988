import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import ContentModal from '../../../Common/ContentModal/ContentModal';
import APIPvtDB from '../../../../apiPvtD';
import { scriptOnlyHTMLTagRegex } from '../../../../constants';

const AddNewNotesModal = ({ closeModal, enterMsg, editNote, getCommentsDetail }) => {
  const formik = useFormik({
    initialValues: {
      note: '',
    },
    validationSchema: Yup.object({
      note: Yup.string()
        .trim()
        .matches(scriptOnlyHTMLTagRegex, "Please enter valid note")
        .required('Please enter the note')
        .test('word-count', 'Maximum 100 words allowed', (value) => {
          if (!value) return true;
          const words = value.trim().split(/\s+/);
          return words.length <= 100;
        })
    }),
    onSubmit: async (values) => {
      if (enterMsg && !editNote) {
        enterMsg(values.note);
      } else if (editNote) {
        await updateComment(values.note);
      }
    },
  });

  const updateComment = async (comment) => {
    try {
      const formData = {
        id: editNote.id,
        comments: comment,
      };
      await APIPvtDB.post(`/update-note`, formData)
        .then((response) => {
          if (response.data.success) {
            getCommentsDetail();
            closeModal();
          }
        })
        .catch((error) => {
          console.error(error);
        });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (editNote) {
      formik.setFieldValue('note', editNote.comments);
    }
  }, [editNote]);

  return (
    <ContentModal closeModal={closeModal}>
      <form onSubmit={formik.handleSubmit}>
        <div className="modal-body new-notes-box">
          <h3 className="mt-0">Create note</h3>
          <div className="crea-list-m text-start w-100">
            <div className="label-field">
              <label className='text-left'>Notes</label>
              <textarea
                className={`form-control ${formik.touched.note && formik.errors.note ? 'is-invalid' : ''}`}
                rows="5"
                name="note"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.note}
                placeholder="Type your note here..."
              />
              {formik.touched.note && formik.errors.note ? (
                <div className="invalid-feedback">{formik.errors.note}</div>
              ) : null}
            </div>
            <p className='text-right'>{`${formik.values.note.trim().split(/\s+/).filter((word) => word).length} words`}</p>
          </div>
        </div>
        <div className="modal-footer">
          <button className="btn btn-black" type="button" onClick={closeModal}>
            Cancel
          </button>
          <button className="btn btn-outline-black" type="submit">
            Save
          </button>
        </div>
      </form>
    </ContentModal>
  );
};

export default AddNewNotesModal;
