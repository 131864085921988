import React, { useEffect, useState } from 'react'
import './index.css'
import DndComponent from './DndComponent';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Form from 'react-bootstrap/Form';
import API from '../../../../api';
import { toast } from 'react-toastify';
import ContentModal from '../../../../components/Common/ContentModal/ContentModal';
import ShareListModel from '../../../../components/Common/ShareListModel/ShareListModel';

const CandidateJourney = () => {
  const location = useLocation();
  const { user } = useSelector((state) => state.auth);
  const [isStepCreated, setIsStepCreated] = useState(true);
  const [jobTitle, setJobTitle] = useState('');
  const [jobId, setJobId] = useState('');
  const [jobData, setJobData] = useState();
  const [searchValue, setSearchValue] = useState("");
  const [overallMatch, setOverallMatch] = useState("");
  const [overallMatchSaved, setOverallMatchSaved] = useState(null);
  const [experience, setExperience] = useState("");
  const [experienceSaved, setExperienceSaved] = useState(null);
  const [hiringStatus, setHiringStatus] = useState("");
  const [hiringStatusSaved, setHiringStatusSaved] = useState(null);
  const [isHiringStatus, setIsHiringStatus] = useState(null);
  const [toggleAddStepModel, setToggleAddStepModel] = useState(false);
  const [movingState, setMovingState] = useState(false);
  const [defaultValues, setDefaultValues] = useState('');
  const [stageName, setStageName] = useState(null);
  const [checked, setChecked] = useState({});
  const navigate = useNavigate();
  const handleCreateInterviewSteps = () => {
    navigate(`/interview-steps?job_id=${jobId}`);
  }

  const getCandidateIntJourney = async (id) => {
    try {
      // &overall_match=${overallMatchSaved.label}_80&experience=${experienceSaved ? experienceSaved.value : ''}
      await API.get(`/job-board/get-applicants-job-interview-steps?user_id=${user.id}&job_id=${id}&hiring_status_id=${hiringStatusSaved ? hiringStatusSaved.id : ''}&experience=${experienceSaved ? experienceSaved.value : ''}&overall_match=${overallMatchSaved ? overallMatchSaved.value : ''}&search=${searchValue}`).then((res) => {
        if(res?.data?.data?.length >1) {
          setJobTitle(res.data.job_detail.full_title)
          setIsStepCreated(false)
          setOverallMatch(res.data.filters.overall_match[0].attribute_values)
          setExperience(res.data.filters.experience[0].attribute_values)
          setHiringStatus(res.data.filters.hiringStatus)
          if(res.data.job_detail.hiring_status_id !== 0){
            const getValueOfID =  res.data.filters.hiringStatus.find((item) => {
              if(item.id === res.data.job_detail.hiring_status_id) {
                return item.name
              }
            })
            setIsHiringStatus(getValueOfID.name)
          }

          const result = res.data.data.flatMap(cand => 
            cand.applicants.map(candi => ({
              [`task-${candi.candidate_id}`]: {
                id: `task-${candi.candidate_id}`,
                name: `${candi.first_name} ${candi.last_name}`,
                experience: `${candi.overall_experience}`,
                phone: `${candi.phone}`,
                match: `${candi.overall_match}`,
                resume_id: `${candi.resume_id}`,
                email: `${candi.email}`,
                title: res.data.job_detail.full_title,
                date_posted: res.data.job_detail.date_posted,
                pre_screening_status: candi.pre_screening_status ? candi.pre_screening_status : ''
              }
            }) )
          )
          const tasks = Object.assign({}, ...result);

          const columnsRes = res.data.data.reduce((acc, cand) => {
            const taskIds = cand.applicants.map(task => `task-${task.candidate_id}`);
            acc[`column-${cand.interview_step_id}`]= {
              id: `column-${cand.interview_step_id}`,
              title: `${cand.interview_step_name}`,
              taskIds: taskIds,
              interview_step_id: cand.interview_step_id
            };
            return acc
          }, {})
          const columnOrder = res.data.data.map(step => `column-${step.interview_step_id}`);

          let dndData = {
            tasks: tasks,
            columns: columnsRes,
            columnOrder: columnOrder,
          }
          // console.log(dndData)
          setJobData(dndData)
        }
        if(res.data.result === false) {
          toast.error(res.data?.message.error, {
            autoClose: 2000,
            closeOnClick: false,
          });
          navigate('/jobs-posted');
        }
      })
    } catch (error) {
      if (error) {
        console.log(error);
      }
    }
  }

  const handleSearch =(e)=>{
     setSearchValue(e.target.value)
  }

  const updateTickets = async(data, preScreening) =>{
    console.log(data);
    let candId = getValue(data.draggableId)
    let stepId = getValue(data.destination.droppableId)
    let formData = {
      user_id: user.id,
      candidate_id: candId,
      target_step_id: stepId,
      job_id: jobId,
    }
    if(preScreening) {
      formData.send_questions= preScreening
    }
    try {
      await API.post(`/job-board/change-candidate-interview-step`, formData)
        .then((response) => {
          if(response.data.result) { 
            getCandidateIntJourney(jobId)
            toast.success(response.data.message.success, {
            autoClose: 2000,
            closeOnClick: false,
          })}
          else{
            setMovingState(!movingState)
            toast.error(response.data.message.error, {
              autoClose: 2000,
              closeOnClick: false,
            })
          }

        })
        .catch((error) => {
          if (error) {
            console.log(error)
          }
        });
    } catch (error) {
      console.log(error);
    }
  }
  const updateColumns = async(data) =>{
    console.log(data);
    let formData = {
      user_id: user.id,
      interview_steps: data,
      job_id: jobId,
    }
    try {
      await API.post(`/job-board/change-job-interview-steps-sequence`, formData)
        .then((response) => {
          if(response.data.result) { 
            getCandidateIntJourney(jobId)
            toast.success(response.data.message.success, {
            autoClose: 2000,
            closeOnClick: false,
          })}
          else{
            // setMovingState(!movingState)
            toast.error(response.data.message.error, {
              autoClose: 2000,
              closeOnClick: false,
            })
          }
        })
        .catch((error) => {
          if (error) {
            console.log(error)
          }
        });
    } catch (error) {
      console.log(error);
    }
  }
  
  const deleteProcess = async(data) =>{
    try {
      await API.post(`/job-board/remove-job-interview-step?user_id=${user.id}&job_id=${jobId}&interview_step_id=${data.interview_step_id}`)
        .then((response) => {
          if(response.data.result) { 
            getCandidateIntJourney(jobId)
            
            toast.success(response.data.message.success, {
            autoClose: 2000,
            closeOnClick: false,
          })}
          else{
            // setMovingState(!movingState)
            toast.error(response.data.message.error, {
              autoClose: 2000,
              closeOnClick: false,
            })
          }

        })
        .catch((error) => {
          if (error) {
            console.log(error)
          }
        });
    } catch (error) {
      console.log(error);
    }
  }
  
  const getValue =(str) =>{
    const match = str.match(/\d+/);
    return match ? parseInt(match[0], 10) : null;
  }

  const closeModal = () => {
    setToggleAddStepModel(!toggleAddStepModel);
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const jobId = searchParams.get('job_id');
    setJobId(jobId)
    getCandidateIntJourney(jobId)
  }, [searchValue, overallMatchSaved, experienceSaved,hiringStatusSaved, movingState])

  const overallMatchSave = (data) => {
    setOverallMatchSaved(data)
  }

  const experienceSave = (data) => {
    setExperienceSaved(data)
  }

  const hiringStatusSave = async (data) => {
    // setHiringStatusSaved(data)
    const formData = {
      hiring_status_id: data.id,
      job_id: Number(jobId)
    }
    try {
      await API.post(`/job-board/update-hiring-status`, formData)
        .then((response) => {
          if(response.data.result) {
            toast.success(response.data.message.success, {
              autoClose: 2000,
              closeOnClick: false,
            })
            getCandidateIntJourney(Number(jobId))
          }
        })
        .catch((error) => {
          if (error) {
            console.log(error)
          }
        });
    } catch (error) {
      console.log(error);
    }

  }

  const addNewProcess = async () => {
    try {
      await API.get(`/job-board/get-job-interview-steps?user_id=${user.id}&job_id=${jobId}`)
        .then((response) => {
          setDefaultValues(response.data.data.availableInterviewSteps)

        })
        .catch((error) => {
          if (error) {
            console.log(error)
          }
        });
    } catch (error) {
      console.log(error);
    }
  }
  const saveNewSteps = async () => {
    let updatedItems = Object.keys(checked).map(key => {
      return { id: key, label: checked[key], img: null };
    });
    console.log(stageName)
    if(stageName !== null) {
      let newStep = {
        id: '',
        label: stageName 
      }  
      updatedItems.push(newStep)
    }

    let formData = {
      response: {
        interview_steps: updatedItems
      },
      "job_id": jobId,
      "user_id": user.id
    }

    try {
      await API.post(`/job-board/save-job-interview-steps`, formData).then((res) => {
        if(res.data.result){
          getCandidateIntJourney(jobId)
          closeModal()
          toast.success(res.data.message.success, {
            autoClose: 2000,
            closeOnClick: false,
          })
        }
        else{
          closeModal()
          getCandidateIntJourney(jobId)
          toast.error(res.data.message.error, {
            autoClose: 2000,
            closeOnClick: false,
          })
        }
      })
    } catch (error) {
      if (error) {
        console.log(error);
      }
    }
  }

  const handleCheckboxChange = (event, checkboxValue, id) => {
    const { checked: isChecked } = event.target;
  
    if (!isChecked) {
      const newChecked = { ...checked };
      delete newChecked[id];  
      setChecked(newChecked); 
    } else {
      setChecked({ ...checked, [id]: checkboxValue });
    }
  };

  const addNewHire = () =>{
    addNewProcess()
    setChecked({})
    setStageName(null)
    setToggleAddStepModel(!toggleAddStepModel)
  }

  return (
    <div className="page-space bg-clr-admin pt-3">
      <div className="page-title-heading align-items-center">
        <div className='flex-start-center'>
          <img src='/image/form-back-arrow.png' alt='back' className='me-2 cursor-pointer' onClick={() => navigate(-1)} />
          <div className='flex-start-center'>
            <h2 className='m-0'>Hiring process</h2>
            <div className='job-position ms-2'>
              <h3 className='m-0'>({jobTitle})</h3>
            </div>
          </div>
        </div>
        {
          !isStepCreated && ( <button className='btn btn-black hiring-btn' onClick={addNewHire}>Add new hiring step +</button>)
        }
       
        {
          isStepCreated && (<button className='btn btn-black hiring-btn' onClick={handleCreateInterviewSteps}>Add new hiring step +</button>)
        }
      </div>{" "}
      <div className="filter-search-bar mb-2">
        <div className="two-drop-role">
          <div className="col-left-adminfilter">
            <p className="filter-label-admin">Overall match</p>
            <div className="admin-status-filter">
              <div
                id="dropdown-boxId2"
                className="company-form-dropdownBox1"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <div className="text-filter-admin">
                  <p className={` ${"text-filter-select"}`}>
                    {overallMatchSaved ? overallMatchSaved.label : 'Overall match'}
                  </p>
                  {overallMatchSaved ? <img src="/image/cross-port.png" alt="" onClick={() => setOverallMatchSaved('')} /> : <img src="/image/admin-d.png" alt="" />}
                </div>
              </div>
              {overallMatch?.length > 0 && (
                <div className="promotion-status-dropdown">
                  <ul className="list-job-title-company">
                    {overallMatch?.map((item, index) => (
                      <li
                        key={index}
                        onClick={() => overallMatchSave(item)}
                      >
                        {item.label}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          </div>

          <div className="col-left-adminfilter">
            <p className="filter-label-admin">Experience</p>
            <div className="admin-status-filter">
              <div
                id="dropdown-boxId2"
                className="company-form-dropdownBox1"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <div className="text-filter-admin">
                <p className={` ${"text-filter-select"}`}>
                    {experienceSaved ? experienceSaved.label : 'Experience'}
                  </p>
                  {experienceSaved ? <img src="/image/cross-port.png" alt="" onClick={() => setExperienceSaved('')} /> : <img src="/image/admin-d.png" alt="" />}

                </div>
              </div>


              {experience?.length > 0 && (
                <div className="promotion-status-dropdown">
                  <ul className="list-job-title-company">
                    {experience?.map((item, index) => (
                      <li
                        key={index}
                        onClick={() => experienceSave(item)}
                      >
                        {item.label}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          </div>
          
          {/* <div className="col-left-adminfilter">
            <p className="filter-label-admin">Hiring status</p>
            <div className="admin-status-filter">
              <div
                id="dropdown-boxId2"
                className="company-form-dropdownBox1"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <div className="text-filter-admin">
                <p className={` ${"text-filter-select"}`}>
                    {isHiringStatus ? isHiringStatus : 'Hiring status'}
                  </p>
                  {hiringStatusSaved ? <img src="/image/cross-port.png" alt="" onClick={() => setHiringStatusSaved('')} /> : <img src="/image/admin-d.png" alt="" />}

                </div>
              </div>


              {hiringStatus?.length > 0 && (
                <div className="promotion-status-dropdown">
                  <ul className="list-job-title-company">
                    {hiringStatus?.map((item, index) => (
                      <li
                        key={index}
                        onClick={() => hiringStatusSave(item)}
                      >
                        {item.name}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          </div> */}
        </div>

        <div className="col-right-searcgadmin">
          <div className="search-admin">
            <img src="/image/job-search.png" alt="search" />
            <label for='jon-search-box' className="visibility-hidden opacity-0 position-absolute">jon-search-box</label>
            <input
              type="text"
              name="search"
              id="jon-search-box"
              placeholder="Search"
              className="form-font-f"
              onChange={(e) => {handleSearch(e)}}
            />
          </div>
        </div>
      </div>
      <div className='cand-card-list'>
        {/* <DndComponent initialData={initialData} /> */}
        {
          isStepCreated ? (<><div class="divider pt-3 mt-3"></div><div className='no-interview text-center h-100'><p>Please create hiring steps to streamline your hiring process.</p></div></>) :
          (<div className='drag-sec'> 
            {/* <DndComponent data={data} setData={setData} /> */}
            <DndComponent 
              data={jobData} 
              setData={setJobData} 
              updateTickets={updateTickets} 
              deleteProcess={deleteProcess} 
              updateColumns={updateColumns}
            />
          </div>)
        }

      </div>
      {toggleAddStepModel && (
        <ContentModal
        closeModal={closeModal}
        size='lg'
          >
              <div className="modal-body">
                <h3 className="mt-0">Add new hiring step</h3>
                <div className="crea-list-m text-start w-100">
                  <div className="label-field">
                    <label for="">Step name </label>
                    <div className="share-list-feild">
                      <div
                        id="dropdown-boxId2"
                        className="company-form-dropdownBox1 custom-tag-input"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <div className="text-filter-admin">
                          <input
                            type="text "
                            className="form-font-list-share"
                            name="campanyUser"
                            autoComplete='off'
                            onChange={(e) => {
                              setStageName(e.target.value);
                            }}
                            placeholder="Step name"
                          />

                          <img
                            src="/image/admin-d.png"
                            alt=""
                            className="hide-tick"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="fd-options mb-3 justify-content-start w-100">
                  {defaultValues.length > 0 &&
                    defaultValues?.map((checkbox) =>
                      <Form.Check key={checkbox.id}
                        inline
                        label={checkbox.name}
                        name="group1"
                        type='checkbox'
                        id={`checkbox-${checkbox.id}`}
                        onChange={(event) => handleCheckboxChange(event, checkbox.name, checkbox.id)} />
                    )
                  }
                </div>
              </div>
              <div className="modal-footer">
                <button
                  className="btn btn-black "
                  type="button"
                  onClick={()=>closeModal()}
                >
                  Cancel
                </button>
                <button className="btn btn-outline-black" onClick={saveNewSteps}>
                  Save
                </button>
              </div>
         
        </ContentModal>

      )}
    </div>
    
  )
}

export default CandidateJourney