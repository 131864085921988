import React, { useEffect, useState } from 'react'
import "./index.css"
import { useLocation, useNavigate } from 'react-router-dom'
import WorldConnect from '../../components/Common/WorldConnect/WorldConnect';
import API from '../../api';
import APIPvtDB from '../../apiPvtD';
import { useSelector } from 'react-redux';

const Faq = () => {
  const router = useNavigate();
  const location = useLocation();
  const [topicName, setTopicName] =useState('');
  const [fullOption, setFullOption] =useState(false);
  const [faqData, setFaqData] =useState([]);
  const { user } =useSelector((state) => state.auth);
  const topic = [
    {id: 0, title: 'General', 
      questions: [
        {id: 1, question: 'Where is my data stored?', 
          answer: 'All user data is stored on Amazon Web Services in North America'
        },
        {id: 2, question: 'What AI models are you using for AI Analysis', 
          answer: 'We use ChatGPT for AI analysis and our membership on ChatGPT does not make your data public or uses it for training its models.'
        },
        {id: 3, question: 'Does ChatGPT uses my data for training its models?', 
          answer: 'Our ChatGPT subscription does not share your data with ChatGPT and OpenAI does not use this information for training its models. For more information please read Enterprise privacy at OpenAI for further details.'
        },
      ]
    },
    {id: 1, title: 'Employers FAQ', questions: [
      {id: 1, question: 'Where is my data stored?Employers FAQ', 
        answer: 'All user data is stored on Amazon Web Services in North America'
      },
      {id: 2, question: 'What AI models are you using for AI Analysis', 
        answer: 'We use ChatGPT for AI analysis and our membership on ChatGPT does not make your data public or uses it for training its models.'
      },
      {id: 3, question: 'Does ChatGPT uses my data for training its models?', 
        answer: 'Our ChatGPT subscription does not share your data with ChatGPT and OpenAI does not use this information for training its models. For more information please read Enterprise privacy at OpenAI for further details.'
      },
    ]},
    {id: 2, title: 'Candidates FAQ', questions: [
      {id: 1, question: 'Where is my data stored?Candidates FAQ', 
        answer: 'All user data is stored on Amazon Web Services in North America'
      },
      {id: 2, question: 'What AI models are you using for AI Analysis', 
        answer: 'We use ChatGPT for AI analysis and our membership on ChatGPT does not make your data public or uses it for training its models.'
      },
      {id: 3, question: 'Does ChatGPT uses my data for training its models?', 
        answer: 'Our ChatGPT subscription does not share your data with ChatGPT and OpenAI does not use this information for training its models. For more information please read Enterprise privacy at OpenAI for further details.'
      },
    ]},
    {id: 3, title: 'Policy statement', questions: [
      {id: 1, question: 'Where is my data stored?Policy statement', 
        answer: 'All user data is stored on Amazon Web Services in North America'
      },
      {id: 2, question: 'What AI models are you using for AI Analysis', 
        answer: 'We use ChatGPT for AI analysis and our membership on ChatGPT does not make your data public or uses it for training its models.'
      },
      {id: 3, question: 'Does ChatGPT uses my data for training its models?', 
        answer: 'Our ChatGPT subscription does not share your data with ChatGPT and OpenAI does not use this information for training its models. For more information please read Enterprise privacy at OpenAI for further details.'
      },
    ]},
    {id: 4, title: 'Terms and conditions', questions: [
      {id: 1, question: 'Where is my data stored?Terms and conditions', 
        answer: 'All user data is stored on Amazon Web Services in North America'
      },
      {id: 2, question: 'What AI models are you using for AI Analysis', 
        answer: 'We use ChatGPT for AI analysis and our membership on ChatGPT does not make your data public or uses it for training its models.'
      },
      {id: 3, question: 'Does ChatGPT uses my data for training its models?', 
        answer: 'Our ChatGPT subscription does not share your data with ChatGPT and OpenAI does not use this information for training its models. For more information please read Enterprise privacy at OpenAI for further details.'
      },
    ]},
  ];
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    setTopicName(searchParams.get("topic"))
    if(!searchParams.has('topic')) {
      searchParams.set('topic',"General FAQs");
      router(`${location.pathname}?${searchParams.toString()}`, {replace: true});
    }
  }, [location, router])

  async function FaqPost() {
    try {
      await APIPvtDB.get(`/faq-categories`)
        .then((response) => {
          if (response?.data?.success === true) {
            setFaqData(response.data);
          }
        })
        .catch((error) => {
          if (error) {
            console.log(error);
          }
        });
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(()=>{
    FaqPost();
  },[])

  return (
    <>
      <main>
        <div className="job-heading mt-5">
          <div className="container-site">
            <h2>FAQ</h2>
          </div>
        </div>
        <div className='container container-site'>
          <div className='faq-section'>
            <div className={`${fullOption ? 'active' : ''} topic-sec`}>
              {
                faqData?.data?.map((val, id) => {
                  return (
                    <div key={id} className={`topic-item ${val.name.toLowerCase() === topicName ? 'active': '' }`} 
                    onClick={() => router(`${location.pathname}?topic=${val.name.toLowerCase()}`)}>
                      {val.name}
                    </div>
                  )
                })
              }
            </div>
            { user ? <><div className='py-3 d-md-none d-block text-center w-100'>
              <button className='btn btn-black fw-400 px-5 py-3' onClick={()=>setFullOption(!fullOption)}>See {fullOption ? 'less' : 'more'}</button>
            </div></> : ""}
            <div className='faq-question'>
              <div className='faq-title'>
                <h2>{topicName === "general faqs" ? "General FAQs" : topicName}</h2>
              </div>
              
              {
                location.search.includes("topic") &&
                (() => {
                  const queryParams = new URLSearchParams(location.search);
                  const topicParam = queryParams.get("topic");

                  // Find the topic object
                  const topicData = faqData?.data?.find(
                    (item) => item.name.toLowerCase() === topicParam?.toLowerCase()
                  );

                  // Ensure topicData and questions exist before mapping
                  return topicData && topicData?.description ? (
                    <div dangerouslySetInnerHTML={{ __html: topicData?.description }} />
                  ) : topicData?.faqs ? (
                    topicData?.faqs?.map((question, id) => (
                      <div key={id} className="question-item">
                        {
                          question?.heading && <h2>{question?.heading}</h2> 
                        }
                        <h3>{question?.question}</h3>
                        <p>{question?.answer}</p>
                      </div>
                    ))
                  ) : (
                    <p>No questions available for this topic.</p>
                  );
                })()
              }
            </div>
          </div>
        </div>
      </main>
      <div>
        <WorldConnect />
      </div>
    </>
  )
}

export default Faq