import { useState } from "react";
import { useFormik } from "formik";
import { useNavigate, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import OutsideClickHandler from "react-outside-click-handler";
import API from "../../../api";
import {
  setActiveTab,
  setLoginModel,
} from "../../../redux/slices/AuthSlice";
import "./ContactusModal.css";
import {
  COUNTRY_ISO_CODE,
  emailRegex
} from "../../../constants";

const ContactUsModal = ({ setContactModal, showSubmitModal }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { countryISOCode } = useSelector((state) => state.home);
  const { openLoginModel } = useSelector((state) => state.auth);
  const [loader, setLoader] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [passwordShown, setPasswordShown] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: "",
      firstName: "",
    },
    /*validationscheme*/
    validationSchema: Yup.object({
      email: Yup.string()
        .matches(
          emailRegex,
          "Please enter valid email address"
        )
        .max(100,"Email cannot exceed 100 characters")
        .required("Please enter email"),
      firstName: Yup.string().required("Please enter first name"),
    }),
    onSubmit: async (data, { resetForm }) => {
      console.log(data);
      setContactModal(false);
      if (showSubmitModal) {
        showSubmitModal()
      }
    },
  });

  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <p className="error-msg">{formik.errors[name]}</p>
      )
    );
  };

  const onSignUpHandler = () => {
    dispatch(setActiveTab("candidate"));
    dispatch(setLoginModel(false));
  };

  return (
    <section className={`model-comment-popup `}>
      <div className="popup-bg-job-cont">
        <OutsideClickHandler
          onOutsideClick={() => {
            dispatch(setLoginModel(false));
            setContactModal(false);
          }}
        >
          <div className="popup-job-sec">
            <div className="model-left-right-job">
              <div className="col-left-model-cont"></div>
              <div className="col-right-model-cont">
                <div
                  className="close-img-resume"
                  onClick={() => {
                    setContactModal(false);
                  }}
                >
                  <img
                    src="/image/cross-btn.png"
                    alt=""
                    className="cross-btn"
                  />
                </div>
                <div className="model-logo-job-sec">
                  <img
                    className="logo-img contact-logo"
                    src="/image/polycareers-north-macedonia.png"
                    alt=""
                  />
                </div>
                <div className="divider divider-mg divider-mble"></div>
                <div className="content-f-cont">
                  <h5>Contact us</h5>
                </div>
                <div className="model-signin-cont">
                  <form onSubmit={formik.handleSubmit}>
                    <div className="candidate-username-signup-form ">
                      <div
                        className={`first-name first-full-width ${isFormFieldValid("firstName") && "error-input"
                          }`}
                      >
                        <br />
                        <input
                          className="form-font-f"
                          type="text"
                          placeholder="Name"
                          name="firstName"
                          value={formik.values.firstName}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        {getFormErrorMessage("firstName")}
                      </div>
                    </div>
                    <div
                      className={`signup-email f-feild ${isFormFieldValid("email") && "error-input"
                        }`}
                    >
                      <br />
                      <input
                        className="form-font-f"
                        type="email"
                        name="email"
                        id=""
                        placeholder="Email"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      {getFormErrorMessage("email")}
                    </div>
                    <div className={`signup-phone message-field`}>
                      <div
                        className={`merge-feild  ${isFormFieldValid("phoneNo") && "error-input"
                          }`}
                      >
                        <textarea
                          id=""
                          className="form-font-f contact-message"
                          placeholder="Message"
                          name="phoneNo"
                          value={formik.values.phoneNo}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          rows={6} // You can adjust the number of visible rows
                          cols={50} // You can adjust the number of visible columns
                        />
                      </div>
                    </div>
                    {/* <div className="contact-send-btn" onClick={() => { setIsSent(true) }}>
                        <p className="signIn-btn-head">Send</p>
                      </div> */}
                    <div className="text-end">
                      <button
                        type="text"
                        className="btn btn-black mt-3 px-5 py-3"
                      >
                        Send
                      </button>
                    </div>
                  </form>
                  <div className="footer-div">
                    <div className="address-sec">
                      <img
                        src="/image/location-icon.png"
                        alt=""
                        className="cross-btn"
                      />
                      <div>
                        <p className="font-museo">
                          <span className="bluish-clr addr-head">
                            Address
                          </span>{" "}
                          <br />
                          {countryISOCode === COUNTRY_ISO_CODE.CA ? (
                            <>
                              1920 Yonge Street, <br />
                              Toronto, ON M5E 1S7
                            </>
                          ) : (
                            <>
                              202/1 Orce Nikolov, First Floor <br /> Skopje,
                              Macedonia
                            </>
                          )}
                        </p>
                      </div>
                    </div>
                    <div className="email-sec">
                      <img
                        src="/image/email-icon.png"
                        alt=""
                        className="cross-btn"
                      />
                      <p className="font-museo bluish-clr">
                        Email
                        <br />
                        <a
                          href={
                            countryISOCode === COUNTRY_ISO_CODE.CA
                              ? "mailto:info@polycareers.com"
                              : "mailto:info@polycareers.com.mk"
                          }
                        >
                          {countryISOCode === COUNTRY_ISO_CODE.CA
                            ? "info@polycareers.com"
                            : "info@polycareers.com.mk"}
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </OutsideClickHandler>
      </div>
    </section>
  );
};

export default ContactUsModal;
