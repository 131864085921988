import React, { useState } from "react";
import { useSelector } from "react-redux";
import capitalizeFirstLetter from "../../../utils/capitalizeFirstLetter";
import { ADMIN } from "../../../constants";

const JobCoreQuestion = ({ label, data }) => {
  const { isEmailVerify, user } = useSelector((state) => state.auth);
  const [mainCollapseOpen, setMainCollapseOpen] = useState(true);
  const [questionCollapseStates, setQuestionCollapseStates] = useState(
    Array(data.length).fill(true)
  );

  const toggleMainCollapse = () => {
    setMainCollapseOpen(!mainCollapseOpen);
  };

  const toggleQuestionCollapse = (sectionIndex) => {
    const updatedStates = [...questionCollapseStates];
    updatedStates[sectionIndex] = !updatedStates[sectionIndex];
    setQuestionCollapseStates(updatedStates);
  };

  if (data?.length === 0) return null;

  return (
    <div className="accordion" id="accordionExample3">
      <div className="accordion-item p-0">
        <h2 className="accordion-header" id="headingThree">
          <button
            className={`accordion-button q-tabs m-0 ${mainCollapseOpen ? "" : "collapsed"
              }`}
            type="button"
            onClick={() => toggleMainCollapse()}
          >
            {label}
          </button>
        </h2>
        <div
          id="collapseThree"
          className={`accordion-collapse collapse ${mainCollapseOpen ? "show" : ""
            }`}
          aria-labelledby="headingThree"
          data-bs-parent="#accordionExample"
        >
          {data?.map((item, sectionIndex) => (
            <div className="accordion-body" key={sectionIndex}>
              <div
                className="accordion"
                id={`sub-accordionExample2-${sectionIndex}`}
              >
                <div className="accordion-item border-0">
                  <h2
                    className="accordion-header"
                    id={`sub-headingOne-${sectionIndex}`}
                  >
                    <button
                      className={`accordion-button bg-clr-q collapsed `}
                      type="button"
                      onClick={() => toggleQuestionCollapse(sectionIndex)}
                    >
                      {capitalizeFirstLetter(item?.name)}
                    </button>
                  </h2>
                  {item?.questions?.map((item2, questionIndex) => (
                    <>
                      <div
                        id={`sub-collapseOne-${sectionIndex}-${questionIndex}`}
                        className={`accordion-collapse collapse ${questionCollapseStates[sectionIndex] ? "" : "show"
                          }`}
                        aria-labelledby={`sub-headingOne-${sectionIndex}`}
                        data-bs-parent={`#sub-accordionExample2-${sectionIndex}`}
                        key={questionIndex}
                      >
                        <div className="accordion-body side-space">
                          <div className="single-question">
                            <p className="question-key">{item2?.question}</p>
                             {/* Hide the answer temporary */}
                            {/* <div className="d-none">
                            {isEmailVerify && ADMIN.includes(user?.user_type) && (
                              <div className="answer-key">
                                <p>{item2?.answer}</p>
                              </div>
                            )}
                            </div> */}
                          </div>
                        </div>
                        {((item?.questions.length - 1) > questionIndex) ? <hr className="m-0" /> : ''}
                      </div>
                    </>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default JobCoreQuestion;
