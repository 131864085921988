import React from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

const CanDetailCircularBar = ({ colorBar, perValue }) => {
  /* React Circular Progress Bar*/
  const currentPerValue =
    perValue !== undefined && perValue !== null ? perValue : 0;
  const progressValue = parseInt(currentPerValue, 10);

  return (
    <div
     className="can-eval-progress-bar"
    >
      <CircularProgressbar
        className="can-eval-circular-bar"
        minValue="0"
        strokeWidth={12}
        value={progressValue}
        text={`${progressValue}%`}
        counterClockwise={false}
        styles={buildStyles({
          strokeLinecap: "round",
          pathTransitionDuration: 0.5,
          pathColor: colorBar,
          textColor: colorBar,
          trailColor: "#d6d6d6",
          textSize: "22px"
        })}
      />
    </div>
  );
};

export default CanDetailCircularBar;
