import { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { RESUME_ITEM } from "../../../../constants";
import "./CanResumeCredForm.css";

const CanResumeCredForm = ({
  prod,
  index,
  totalCertifications,
  monthDropDown,
  yearDropDown,
  onCredChangeHandler,
  onCredSaveHandler,
  openDeleteItemHandler,
}) => {
  const uniqueId = Math.random();
  const [loader, setLoader] = useState(false);

  const formik = useFormik({
    initialValues: {
      start_date: {
        month: "",
        year: "",
      },
      end_date: {
        month: "",
        year: "",
      },
      is_correct: false,
    },
    /*validationscheme*/
    validationSchema: Yup.object({
      name: Yup.string().required("Please enter Certification Name"),
      start_date: Yup.object().shape({
        month: Yup.string(),
        year: Yup.string(),
      }),
      end_date: Yup.object().shape({
        month: Yup.string(),
        year: Yup.string(),
      }),
    }),

    onSubmit: async (data, { resetForm, setErrors }) => {
      setLoader(true);
      onCredSaveHandler();

      const timer = setTimeout(() => {
        setLoader(false);
      }, 2000);

      return () => clearTimeout(timer);
    },
  });

  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <p className="error-msg">{formik.errors[name]}</p>
      )
    );
  };

  const isFormNFieldValid = (name) => {
    const [parent, child] = name.split(".");
    return !!(
      formik.touched[parent]?.[child] && formik.errors[parent]?.[child]
    );
  };

  const getFormNErrorMessage = (name) => {
    const [parent, child] = name.split(".");
    return (
      isFormNFieldValid(name) && (
        <p className="error-msg">{formik.errors[parent]?.[child]}</p>
      )
    );
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };
  
  const onCustomChangeHandler = async (e) => {
    const { name, value } = e?.target;
    let newFormData = { ...formik?.values };

    if (name.includes(".")) {
      const [parent, child] = name.split(".");

      await formik.setFieldValue(parent, {
        ...formik.values[parent],
        [child]: value,
      });

      newFormData = {
        ...formik?.values,
        [parent]: {
          ...formik.values[parent],
          [child]: value,
        },
      };
    } else {
      await formik.setFieldValue(name, value);

      newFormData = {
        ...formik?.values,
        [name]: value,
      };
    }
    // Update is_correct flag based on conditions
    const isStartDatePresent =
      newFormData?.start_date?.month !== "" &&
      newFormData?.start_date?.year !== "";
    const isEndDatePresent =
      newFormData?.end_date?.month !== "" && newFormData?.end_date?.year !== "";

    const isCorrect = isStartDatePresent && isEndDatePresent;

    newFormData = {
      ...newFormData,
      is_correct: isCorrect,
    };

    await formik.setFieldValue("is_correct", isCorrect);

    onCredChangeHandler(newFormData, index);
  };

  const onCustomDateClickHandler = async (name, value) => {
    let newFormData = { ...formik?.values };
    if (name.includes(".")) {
      const [parent, child] = name.split(".");

      await formik.setFieldValue(parent, {
        ...formik.values[parent],
        [child]: value,
      });

      newFormData = {
        ...formik?.values,
        [parent]: {
          ...formik.values[parent],
          [child]: value,
        },
      };

      // Update is_correct flag based on conditions
      const isStartDatePresent =
        newFormData?.start_date?.month !== "" &&
        newFormData?.start_date?.year !== "";
      const isEndDatePresent =
        newFormData?.end_date?.month !== "" &&
        newFormData?.end_date?.year !== "";

      const isCorrect = isStartDatePresent && isEndDatePresent;

      newFormData = {
        ...newFormData,
        is_correct: isCorrect,
      };

      await formik.setFieldValue("is_correct", isCorrect);

      onCredChangeHandler(newFormData, index);
    }
  };

  const getProData = async () => {
    await formik.setFieldValue("name", prod?.name || "");
    await formik.setFieldValue(
      "start_date",
      prod?.start_date || { month: "", year: "" }
    );
    await formik.setFieldValue(
      "end_date",
      prod?.end_date || { month: "", year: "" }
    );
    await formik.setFieldValue("is_correct", prod?.is_correct || false);
  };

  useEffect(() => {
    formik?.resetForm();
    getProData();
  }, [totalCertifications]);

  return (
    <div
      id={`collapseOne-${uniqueId}`}
      className="accordion-collapse collapse show"
      aria-labelledby="headingOne"
      data-bs-parent="#accordionExample"
    >
      <div className="accordion-body educ-res-right">
        <div className="accordion" id={`sub-accordionExample-${uniqueId}`}>
          <div className={`accordion-item experince-res-ver-right`}>
            <h2
              className="accordion-header sub-header"
              id={`sub-heading-${uniqueId}`}
            >
              <button
                className="accordion-button data-tab-res-right"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target={`#sub-collapseanswer-cred-${uniqueId}`}
                aria-expanded="false"
                aria-controls={`collapse-${uniqueId}`}
              >
                <div className="job-title-resume-form">
                  <div className="compay-name-job-resume-right">
                    <p className="right-resume-certificate">
                      {formik?.values?.name !== ""
                        ? formik?.values?.name
                        : "Certification Name"}
                    </p>{" "}
                  </div>
                  <div className="date-job-res m-0 gap-2">
                    <p>
                      {formik?.values?.start_date?.month === "" &&
                        formik?.values?.start_date?.year === ""
                        ? "Start Date"
                        : `${formik?.values?.start_date?.month?.substring(
                          0,
                          3
                        )} ${formik?.values?.start_date?.year}`}{" "}
                      {formik?.values?.end_date?.month === "" &&
                        formik?.values?.end_date?.year === ""
                        ? "- End Date"
                        : `- ${formik?.values?.end_date?.month?.substring(
                          0,
                          3
                        )} ${formik?.values?.end_date?.year}`}
                    </p>
                    <img
                      src="/image/edit-tab.png"
                      className="edit-res"
                      alt=""
                    />
                    <img
                      className="del-res-skill"
                      src="/image/delet-res.png"
                      alt=""
                      onClick={(e) => {
                        e.preventDefault();
                        openDeleteItemHandler(index, RESUME_ITEM.CERTIFICATION, true);
                      }}
                    />
                  </div>
                </div>
              </button>
            </h2>
            <div
              id={`sub-collapseanswer-cred-${uniqueId}`}
              className="accordion-collapse collapse"
              aria-labelledby={`sub-heading-${uniqueId}`}
              data-bs-parent={`#sub-accordionExample-${uniqueId}`}
            >
              <div className="accordion-body side-space">
                <h2
                  className="accordion-header sub-header"
                  id={`sub-heading-${uniqueId}`}
                >
                  <button
                    className="accordion-button resume-exper-right-tb"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#sub-collapseOne"
                    aria-expanded="true"
                    aria-controls={`collapseOne-${uniqueId}`}
                  >
                    <form onSubmit={formik.handleSubmit} onKeyDown={handleKeyDown}>
                      <div
                        className={`address-res f-feild-res ${isFormFieldValid("name") && "error-input"
                          }`}
                      >
                        <label for="lname">Certification Name</label>
                        <br />
                        <input
                          type="text"
                          placeholder="Certification Name"
                          className="feild-stl-res form-font-f"
                          name="name"
                          value={formik?.values?.name}
                          onChange={onCustomChangeHandler}
                          onBlur={formik.handleBlur}
                        />
                        {getFormErrorMessage("name")}
                      </div>
                      <div className="resume-personal-form f-feild-res">
                        <div className="first-name-res">
                          <label for="fname">Start Date</label>
                          <br />
                          <div className="date-range-date-end">
                            <div
                              className={`resume-right-f-date ${isFormNFieldValid("start_date.month") &&
                                "error-input"
                                }`}
                            >
                              <div class="month">
                                <div
                                  id="fresume-year"
                                  class="company-form-dropdownBox-post"
                                  data-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                >
                                  {formik?.values?.start_date?.month !== ""
                                    ? formik?.values?.start_date?.month
                                    : "Month"}
                                  <div class="icon-hide-res">
                                    {isFormNFieldValid("start_date.month") ? (
                                      <img src="/image/warning.png" alt="" />
                                    ) : (
                                      <img
                                        src="/image/salary-year.png"
                                        alt=""
                                      />
                                    )}
                                  </div>
                                </div>

                                {monthDropDown?.length > 0 && (
                                  <div className="list-search-dropdown-month-resume">
                                    <ul className="list-job-title-year-res">
                                      {monthDropDown?.map((j, i) => (
                                        <li
                                          key={i}
                                          className="icon-cursor job-title-size"
                                          onClick={() => {
                                            onCustomDateClickHandler(
                                              "start_date.month",
                                              j?.label
                                            );
                                          }}
                                        >
                                          {j?.label}
                                        </li>
                                      ))}
                                    </ul>
                                  </div>
                                )}
                              </div>
                            </div>

                            <div
                              className={`resume-right-f-date ${isFormNFieldValid("start_date.year") &&
                                "error-input"
                                }`}
                            >
                              <div class="Year-res-v">
                                <div
                                  id="fresume-year"
                                  class="company-form-dropdownBox-post"
                                  data-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                >
                                  {formik?.values?.start_date?.year !== ""
                                    ? formik?.values?.start_date?.year
                                    : "Year"}
                                  <div class="icon-hide-res">
                                    {isFormNFieldValid("start_date.year") ? (
                                      <img src="/image/warning.png" alt="" />
                                    ) : (
                                      <img
                                        src="/image/salary-year.png"
                                        alt=""
                                      />
                                    )}
                                  </div>
                                </div>

                                {yearDropDown?.length > 0 && (
                                  <div className="list-search-dropdown-year-resume">
                                    <ul className="list-job-title-year-res">
                                      {yearDropDown?.map((j, i) => (
                                        <li
                                          key={i}
                                          className="icon-cursor job-title-size"
                                          onClick={() => {
                                            onCustomDateClickHandler(
                                              "start_date.year",
                                              j?.label
                                            );
                                          }}
                                        >
                                          {j?.label}
                                        </li>
                                      ))}
                                    </ul>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                          {getFormNErrorMessage("start_date.month")
                            ? getFormNErrorMessage("start_date.month")
                            : getFormNErrorMessage("start_date.year")}
                        </div>
                        <div class="last-name-res">
                          <div class="end-date-res-ver">
                            <div class="labe-end-date">
                              <label for="lname">End Date</label>
                              <br />
                            </div>
                          </div>
                          <div class="date-range-date-end">
                            <div
                              className={`resume-right-f-date ${isFormNFieldValid("end_date.month") &&
                                "error-input"
                                }`}
                            >
                              <div class="month">
                                <div
                                  id="fresume-year"
                                  class="company-form-dropdownBox-post"
                                  data-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                >
                                  {formik?.values?.end_date?.month !== ""
                                    ? formik?.values?.end_date?.month
                                    : "Month"}
                                  <div class="icon-hide-res">
                                    {isFormNFieldValid("end_date.month") ? (
                                      <img src="/image/warning.png" alt="" />
                                    ) : (
                                      <img
                                        src="/image/salary-year.png"
                                        alt=""
                                      />
                                    )}
                                  </div>
                                </div>

                                {monthDropDown?.length > 0 && (
                                  <div className="list-search-dropdown-month-resume">
                                    <ul className="list-job-title-year-res">
                                      {monthDropDown?.map((j, i) => (
                                        <li
                                          key={i}
                                          className="icon-cursor job-title-size"
                                          onClick={() => {
                                            onCustomDateClickHandler(
                                              "end_date.month",
                                              j?.label
                                            );
                                          }}
                                        >
                                          {j?.label}
                                        </li>
                                      ))}
                                    </ul>
                                  </div>
                                )}
                              </div>
                            </div>
                            <div
                              className={`resume-right-f-date ${isFormNFieldValid("end_date.year") &&
                                "error-input"
                                }`}
                            >
                              <div class="Year-res-v">
                                <div
                                  id="fresume-year"
                                  class="company-form-dropdownBox-post"
                                  data-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                >
                                  {formik?.values?.end_date?.year !== ""
                                    ? formik?.values?.end_date?.year
                                    : "Year"}
                                  <div class="icon-hide-res">
                                    {isFormNFieldValid("end_date.year") ? (
                                      <img src="/image/warning.png" alt="" />
                                    ) : (
                                      <img
                                        src="/image/salary-year.png"
                                        alt=""
                                      />
                                    )}
                                  </div>
                                </div>

                                {yearDropDown?.length > 0 && (
                                  <div className="list-search-dropdown-year-resume">
                                    <ul className="list-job-title-year-res">
                                      {yearDropDown?.map((j, i) => (
                                        <li
                                          key={i}
                                          className="icon-cursor job-title-size"
                                          onClick={() => {
                                            onCustomDateClickHandler(
                                              "end_date.year",
                                              j?.label
                                            );
                                          }}
                                        >
                                          {j?.label}
                                        </li>
                                      ))}
                                    </ul>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                          {getFormNErrorMessage("end_date.month")
                            ? getFormNErrorMessage("end_date.month")
                            : getFormNErrorMessage("end_date.year")}
                        </div>
                      </div>
                    </form>
                  </button>
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CanResumeCredForm;
