import './CompanyDescription.css';
import Button from 'react-bootstrap/Button';
import { useNavigate } from 'react-router-dom';


const CompanyDescription = ({ companyData }) => {
  const navigate = useNavigate();
  return (
    <section className="company-detail-sec pt-0">
      <div className="container container-site py-2">
        <Button variant="link" className="back-to-home add top-0 position-relative" onClick={() => navigate(-1)}>
        <span className="visibility-hidden opacity-0 position-absolute">Go back</span>
          <img src="/image/form-back-arrow.png" alt="" />
        </Button>
      </div>
      <div className="job-heading company-detail-heading company-details-heading m-0 mb-md-0 mb-3">
        <div className="container container-site position-relative">
          <h2 className='p-0 m-0'>Company detail</h2>
        </div>
      </div>
      <div className="container container-site">
        <div className="company-logo-left company-mbl-bg hide-desktop-vw">
          {/* <Button variant="link" className="top-1 back-to-home add d-md-none d-block resp-mob p-0 m-0" onClick={() => navigate(-1)}>
            <img src="/image/form-back-arrow.png" alt="" />
          </Button> */}
          <div className='logo-border'>
            <img
              className="company-logo-mbl"
              src={
                companyData?.logo ?
                   companyData?.logo
                  : "/image/avtar-company.png"
              }
              alt=""
            />
          </div>
          <h6>{companyData?.name}</h6>
        </div>
        {/* <div className="divider-opcity hide-page-title"></div> */}
        <div className="company-content-logo">
          <div className="company-logo-left company-mbl-bg hide-page-title">
            <div className='logo-border'>
              <img
                className="company-logo-mbl"
                src={
                  companyData?.logo ?
                     companyData?.logo
                    : "/image/avtar-company.png"
                }
                alt=""
              />
            </div>
            <h6>Amazon</h6>
          </div>
          <div className="company-content-right">
            {companyData?.name && <h6>{companyData?.name}</h6>}
            {companyData?.description && <p>{companyData?.description}</p>}
          </div>
        </div>
      </div>
    </section>
  );
};

export default CompanyDescription;
