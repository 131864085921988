import React, { useState } from "react";
import { useSelector } from "react-redux";
import { ADMIN } from "../../../constants";

const JobOtherQuestion = ({ label, data }) => {
  const { isEmailVerify, user } = useSelector((state) => state.auth);

  const [mainCollapseOpen, setMainCollapseOpen] = useState(true);

  if (data?.length === 0) return null;

  return (
    <div className="question-tabs">
      <div className="accordion" id="accordionExample">
        <div className="accordion-item p-0">
          <h2 className="accordion-header" id="headingOne">
            <button
              className={`accordion-button q-tabs m-0 ${mainCollapseOpen ? "" : "collapsed"
                }`}
              type="button"
              onClick={() => setMainCollapseOpen(!mainCollapseOpen)}
            >
              {label}
            </button>
          </h2>
          <div
            id="collapseOne"
            className={`accordion-collapse collapse ${mainCollapseOpen ? "show" : ""
              }`}
            aria-labelledby="headingOne"
            data-bs-parent="#accordionExample"
          >
            {data?.length > 0 &&
              data?.map((item, index) => (
                <>
                  <div className="single-question">
                    <p className="question-key">{item?.question}</p>
                      {/* Hide the answer temporary */}
                    {/* {isEmailVerify && ADMIN.includes(user?.user_type) && (
                      <div className="answer-key d-none">
                        <p>{item?.answer}</p>
                      </div>
                    )} */}
                  </div>                 
                </>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobOtherQuestion;
