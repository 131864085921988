import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useLocation } from "react-router-dom";
import OutsideClickHandler from "react-outside-click-handler";
import capitalizeFirstLetter from "../../../utils/capitalizeFirstLetter";
import {
  setActiveSideBar,
  setdefaultPermissions,
} from "../../../redux/slices/AdminDashboardSlice";
import {
  clearLoginUser,
  setActiveTab,
  setInboxCount,
  setLoginModel,
  setPostJobId,
  setPostJobMode,
  setResumeDetail,
} from "../../../redux/slices/AuthSlice";
import { setInboxUser } from "../../../redux/slices/InboxSlice";
import { setTotalBulkResumeCredit } from "../../../redux/slices/buyBulkAnalysisSlice";
import { ADMIN, USER_TYPE } from "../../../constants";
import "./AdminHeader.css";
import {
  getAvailableJobPostsDataAsync,
  getProductPricesDataAsync,
  purchaseJObDataAsync,
  setBuyJobsModelModel,
} from "../../../redux/slices/JobDecisionSlice";
import JobPurchasedModel from "../../Common/JobPurchasedModel/JobPurchasedModel";
import ContentModal from "../../Common/ContentModal/ContentModal";
import ConfettiModal from "../../Common/ConfettiModal/ConfettiModal";
import useAblyHook from "../../../utils/useAblyHook";

const AdminHeader = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { isEmailVerify, user, userName, inboxCount } = useSelector(
    (state) => state.auth
  );
  const { buyJobsModel } = useSelector((state) => state.jobDecision);
  const { activeSideBar } = useSelector((state) => state.adminDashboard);
  const { countryISOCode } = useSelector((state) => state.home);

  // Use the custom hook for Ably
  const { channel, error } = useAblyHook(`message-notification.${user?.id}`);

  const [hideMenu, setHideMenu] = useState(true);
  const [notifyDropDown, setNotifyDropDown] = useState(false);

  /* Buy Job */
  const [productPrice, setProductPrice] = useState("");
  const [jobPurchased, setJobPurchased] = useState(false);
  const [buyJobsQuantity, setBuyJobsQuantity] = useState(1);
  const [jobPurchasedMore, setJobPurchasedMore] = useState("");

  const onSystemHubHandler = () => {
    dispatch(
      setActiveSideBar({
        id: "6",
        name: "My job posts & more",
        value: "My job posts & more",
      })
    );
    navigate("/jobs-posted");
  };

  const onInboxNav = () => {
    if (ADMIN.includes(user?.user_type)) {
      navigate("/employer-inbox");
    } else {
      navigate("/candidate-inbox");
    }
    setNotifyDropDown(false);
  };

  const onProfileNav = () => {
    if (ADMIN.includes(user?.user_type)) {
      navigate("/employer-profile");
    } else {
      navigate("/candidate-profile");
    }
  };

  /* Buy Job*/
  const handleJobModel = () => {
    dispatch(setBuyJobsModelModel(!buyJobsModel));
  };

  const handlePurchasedJobs = () => {
    initializeCheckout();
  };

  const handleJobConfirmationModel = () => {
    setJobPurchased(!jobPurchased);
    navigate("/employer-dashboard");
  };

  const initializeCheckout = async () => {
    dispatch(
      purchaseJObDataAsync({
        user_id: user?.id,
        product_name: "purchase_job",
        unit_amount: productPrice?.product?.price,
        quantity: buyJobsQuantity,
        success_url: "employer-dashboard",
        cancel_url: "employer-dashboard",
      })
    )
      .then((response) => {
        const res = response?.payload;
        if (res?.result === true) {
          const url = res?.checkout_url;
          const newWindow = window.open(url, "_self", "noopener,noreferrer");
          if (newWindow) {
            newWindow.opener = null; // Prevent new tab from having access to current tab
            newWindow.location.href = url; // Navigate the new tab to the specified URL
          } else {
            // Fallback if opening in new tab fails
            navigate(url); // Navigate within the current tab if new tab failed to open
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const postJobClickHanlder = async () => {
    if (isEmailVerify === false) {
      dispatch(setLoginModel(true));
    } else {
      dispatch(getAvailableJobPostsDataAsync())
        .then((response) => {
          const res = response?.payload;
          if (res?.result === true) {
            if (
              res?.availableJobPosts === 0 ||
              res?.availableJobPosts === null
            ) {
              dispatch(setBuyJobsModelModel(true));
              getProductPrice();
            } else {
              dispatch(setActiveTab("employer"));
              dispatch(setPostJobMode("add"));
              dispatch(setPostJobId(null));
              navigate("/post-job");
            }
          }
        })
        .catch((error) => {
          dispatch(setBuyJobsModelModel(false));
          console.log(error);
        });
    }
  };

  const onLogOutHandler = () => {
    dispatch(clearLoginUser());
    dispatch(setResumeDetail({ resumeId: null, resumeType: 1 }));
    dispatch(setdefaultPermissions({}));
    dispatch(
      setActiveSideBar({
        id: "2",
        name: "Department",
        value: "Department",
      })
    );
    dispatch(setInboxUser({}));
    dispatch(setTotalBulkResumeCredit(0));
    localStorage.removeItem("token");
  };

  const getProductPrice = async () => {
    dispatch(getProductPricesDataAsync())
      .then((response) => {
        const res = response?.payload;
        if (res?.result === true) {
          setProductPrice(res);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (channel) {
      const handleChannelMessage = (message) => {
        if (message?.data?.data) {
          dispatch(setInboxCount(message?.data?.data));
        }
      };
      channel.subscribe("new-notification", handleChannelMessage);

      return () => {
        channel.unsubscribe("new-notification", handleChannelMessage);
      };
    }
  }, [channel]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const paramValue = queryParams.get("paymentStatus");
    setJobPurchasedMore(paramValue);
    if (paramValue) {
      dispatch(setBuyJobsModelModel(false));
      setJobPurchased(true);
    }
  }, []);

  return (
    <>
      <div className="header-sidebar">
        <div className="row-header">
          <div className="col-left-sidebar">
            <div className="text-header-sidebar">
              <p onClick={() => onSystemHubHandler()}>System hub /</p>
              <span className="active">{activeSideBar?.value}</span>
            </div>
            {isEmailVerify === true && ADMIN.includes(user?.user_type) && (
              <div
                className="find-job-btn-candidate-dashbord"
                onClick={() => postJobClickHanlder()}
              >             
                  <button>Post a job</button>               
              </div>
            )}
          </div>
          <div className="col-right-sidebar">
            <div className="department-name-img icon-cursor">
              <a href="#">
                <div className="candidate-wishlist-block icon-cursor">
                  <a onClick={() => onInboxNav()}>
                    <img src="/image/message.png" alt="message" />
                    {inboxCount > 0 && (
                      <div className="whislist-notofication">
                        <p className="conter-text-notification conter-text-notification-admin">
                          {" "}
                          {inboxCount}
                        </p>
                      </div>
                    )}
                  </a>
                </div>
                <img
                  src="/image/profile.png"
                  alt=""
                  onClick={() => onProfileNav()}
                />
                <p className="department-name" onClick={()=>navigate("/employer-profile")}>
                  Hi, {userName !== "" ? capitalizeFirstLetter(userName) : ""}
                </p>
                <OutsideClickHandler
                  onOutsideClick={() => {
                    setHideMenu(true);
                  }}
                >
                  <div
                    className="toggle-img-admin"
                    onClick={() => setHideMenu(!hideMenu)}
                  >
                    <img src="/image/toogle-btn.png" alt="" />
                  </div>
                </OutsideClickHandler>
              </a>
            </div>
            {user?.user_type === USER_TYPE.SUPER_ADMIN && (
              <div className="all-flag-sidebar">
                <div className="headder-flag-sidebar">
                  <div className="flag-list active">
                    <img src="/image/flag-1.png" alt="" />
                    <div className="flag-caption active">
                      <p>{countryISOCode}</p>
                    </div>
                  </div>
                </div>
                <div className="headder-flag-sidebar">
                  <div className="flag-list">
                    <img src="/image/flag-2.png" alt="" />
                  </div>
                  <div className="flag-caption">
                    <p>{countryISOCode}</p>
                  </div>
                </div>
                <div class="headder-flag-sidebar">
                  <div class="flag-list">
                    <img src="/image/flag-3.png" alt="" />
                  </div>
                  <div class="flag-caption">
                    <p>{countryISOCode}</p>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div
          className={`dropdown-dashbord-admin ${hideMenu && "hide-show-click"}`}
          onClick={() => setHideMenu(true)}
        >
          <ul className="dropdown-menu-dashbord">
            {user?.user_type !== USER_TYPE.SUPER_ADMIN && (
              <>
                <Link
                  to="/employer-dashboard"
                  onClick={() =>
                    dispatch(
                      setActiveSideBar({
                        id: "2",
                        name: "Department",
                        value: "Department",
                      })
                    )
                  }
                >
                  <li className="menu-dropdown">
                    <img src="/image/dash-m.png" alt="" />
                    Dashboard
                  </li>
                </Link>
                <Link to="/employer-profile">
                  <li className="menu-dropdown">
                    <img src="/image/profile-m.png" alt="" />
                    My profile
                  </li>
                </Link>
              </>
            )}

            <Link to="/" onClick={() => onLogOutHandler()}>
              <li className="menu-dropdown">
                <img src="/image/log-m.png" alt="" />
                Logout
              </li>
            </Link>
          </ul>
        </div>
      </div>
      {buyJobsModel && (
        <JobPurchasedModel
          handleJobModel={handleJobModel}
          productPrice={productPrice}
          setBuyJobsQuantity={setBuyJobsQuantity}
          handlePurchasedJobs={handlePurchasedJobs}
        />
      )}

      {jobPurchased && (
        <ContentModal closeModal={handleJobConfirmationModel}>
          <ConfettiModal
            closeModal={handleJobConfirmationModel}
            title={
              jobPurchasedMore === "paid" ? (
                <p>You've successfully subscribed.</p>
              ) : (
                <p>You've successfully purchased new jobs.</p>
              )
            }
          />
        </ContentModal>
      )}
    </>
  );
};

export default AdminHeader;
