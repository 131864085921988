import { useNavigate, Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import {
  depDropdownAsync,
  setActiveSideBar,
} from "../../../../redux/slices/AdminDashboardSlice";
import * as Yup from "yup";
import APIPvt from "../../../../apiPvt";
import FormError from "../../../Common/Admin/FormError/FormError";
import { depNameRegex } from "../../../../constants";

const AdminCreateDepartment = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { formMode, selectedDep } = useSelector(
    (state) => state.adminDashboard
  );
  const [loader, setLoader] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [statusDropDown, setStatusDropDown] = useState([]);

  const formik = useFormik({
    initialValues: {
      dep: "",
      status: "",
      statusId: null,
    },
    /*validationscheme*/
    validationSchema: Yup.object({
      dep: Yup.string()
        .matches(depNameRegex, "Please enter a valid department")
        .max(100,"Department cannot exceed 100 characters")
        .required("Please enter department"),
    }),
    onSubmit: async (data, { resetForm }) => {
      const { dep, statusId } = data;
      onCreateDep(dep, statusId);
    },
  });

  const navHandler = () => {
    navigate(-1);
  };

  const onStatusClick = async (label, value) => {
    await formik.setFieldValue("status", label);
    await formik.setFieldValue("statusId", value);
  };

  const onCancelClick = () => {
    dispatch(
      setActiveSideBar({
        id: "23",
        name: "Department",
        value: "Department",
      })
    );
    navigate("/admin-department");
  };

  const onCreateDep = async (dep, statusId) => {
    try {
      const formData = new FormData();
      formData.append("name", dep?.trim());
      statusId !== null && formData.append("status", statusId);
      setLoader(true);

      let baseURL = ``;
      if (formMode === "add") baseURL = `/department`;
      else if (formMode === "edit")
        baseURL = `/update-department/${selectedDep?.id}`;
      await APIPvt.post(baseURL, formData)
        .then((response) => {
          if (response?.status === 200 || response?.status === 201) {
            const res = response?.data;

            if (res?.success === true) {
              if (formMode === "add") {
                dispatch(
                  setActiveSideBar({
                    id: "2",
                    name: "Roles",
                    value: "Create Role",
                  })
                );
              } else if (formMode === "edit") {
                dispatch(
                  setActiveSideBar({
                    id: "23",
                    name: "Department",
                    value: "Department",
                  })
                );
                navigate("/admin-department");
              }
            } else {
              setErrorMsg(res?.message);
              setTimeout(() => {
                setErrorMsg("");
              }, 5000);
            }
            setLoader(false);
          }
        })
        .catch((error) => {
          if (error) {
            const { response } = error;
            const { data } = response;
            setErrorMsg(data?.message);
            setTimeout(() => {
              setErrorMsg("");
            }, 5000);
            console.log(error);
          }
          setLoader(false);
        });
    } catch (error) {
      setLoader(false);
      console.log(error);
    }
  };

  const getEditDepData = async () => {
    try {
      let params = `?`;
      if (selectedDep?.id !== null) {
        params += `department_id=${selectedDep?.id}&`;
      }
      await APIPvt.get(`/edit-department${params}`)
        .then(async (response) => {
          if (response?.status === 200 || response?.success === true) {
            let res = response?.data?.data;
            await formik.setFieldValue("dep", res?.name);
            await formik.setFieldValue(
              "status",
              res?.status === 1 ? "Active" : "Inactive"
            );
            await formik.setFieldValue("statusId", res?.status);
          }
        })
        .catch((error) => {
          if (error) {
            console.log(error);
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  const depStatusDropdownHandler = () => {
    dispatch(depDropdownAsync())
      .then((response) => {
        const res = response?.payload;
        if (res?.success === true) {
          setStatusDropDown(res?.data?.status);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <p className="error-msg">{formik.errors[name]}</p>
      )
    );
  };

  useEffect(() => {
    if (formMode === "edit") getEditDepData();
  }, [selectedDep]);

  useEffect(() => {
    depStatusDropdownHandler();
  }, []);

  return (
    <>
      <div className="form-heading-prom">
        {formMode === "edit" && (
          <img
            src="/image/form-back-arrow.png"
            alt=""
            onClick={() => navHandler()}
          />
        )}
        <h2>{formMode === "edit" ? "Edit " : "Create"} department</h2>
      </div>
      <div className="department-form-box">
        <form onSubmit={formik.handleSubmit}>
          <div className="admin-from-step">
            <div
              className={`f-admin feild-f-admin ${
                isFormFieldValid("dep") && "error-input-admin"
              }`}
            >
              <label for="">Department *</label>
              <br />
              <input
                type="text"
                name="dep"
                id="dept-f"
                placeholder="Department"
                className="form-font-admin"
                value={formik.values.dep}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {getFormErrorMessage("dep")}
            </div>
            <div className={`f-admin feild-f-admin`}>
              <label for="">Status</label>
              <div className={`admin-status-filter-form`}>
                <div
                  id="dropdown-boxId2"
                  className="company-form-dropdownBox1"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <div className="text-filter-admin">
                    <p
                      className={` ${
                        (formik?.values?.status !== "" && "text-filter-select",
                        formik?.values?.status === "" && "dropdown-opty")
                      }`}
                    >
                      {" "}
                      {formik?.values?.status === ""
                        ? "Status"
                        : formik?.values?.status}
                    </p>
                    <img src="/image/admin-d.png" alt="" />
                  </div>
                </div>

                {statusDropDown?.length > 0 && (
                  <div className="admin-status-dropdown-frm">
                    <ul className="list-job-title-company">
                      {statusDropDown?.map((item, index) => (
                        <li
                          key={index}
                          onClick={() => {
                            onStatusClick(item?.label, item?.value);
                          }}
                        >
                          {item?.label}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            </div>
            <div className="f-admin feild-f-admin"></div>
          </div>
          <div className="admin-form-btn">
            {" "}
            <button className="cancel-admin" type="button" onClick={() => onCancelClick()}>
              Cancel
            </button>{" "}
            <button className="next-admin" type="submit">
              {loader ? (
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : formMode === "add" ? (
                "Next"
              ) : (
                "Edit & Save"
              )}
            </button>
          </div>
        </form>
        <FormError errorMsg={errorMsg} />
      </div>
    </>
  );
};

export default AdminCreateDepartment;
