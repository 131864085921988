import React, { useEffect, useState } from "react";
import "./InterviewQuestions.css";
import RadioGroup from "../../../../components/Buttons/RadioButton";
import API from "../../../../api";
import ActionModal from "../../../../components/Common/ActionModal/ActionModal";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const InterviewQuestions = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search);
  const isEdit = searchParams.get("edit");
  const jID = searchParams.get("job_id");
  const [options, setOptions] = useState([]);
  const [catQuestion, setCatQuestion] = useState([]);
  const [additionalInfo, setAdditionalInfo] = useState('');
  const [isAdditionalInfo, setIsAdditionalInfo] = useState(false);
  const [selectedQues, setSelectedQues] = useState([]);
  const [categoryName, setCategoryName] = useState('');
  const [categoryNameId, setCategoryNameId] = useState('');
  const [inputText, setInputText] = useState('');
  const [searchResult, setSearchResult] = useState('');
  const [searchResults, setSearchResults] = useState('');
  const [type, setType] = useState('');
  const [allCompanyUserHide, setCompanyUserHide] = useState(true);
  const [idealAnswerWarning, setIdealAnswerWarning] = useState(false);
  const [idealAnswerSuccess, setIdealAnswerSuccess] = useState(false);
  const [isCustom, setIsCustom] = useState(false);
  const [editMode, setEditMode] = useState({});
  const [editText, setEditText] = useState({});
  const { user } = useSelector(
    (state) => state.auth
  );
  const [formData, setFormData] = useState({
    question_text: "",
    ideal_answer: "Yes",
    is_required: false,
  });

  useEffect(() => {
    getQuestionsType();
  }, []);

  const getQuestionsType = async () => {
    try {
      await API.get(`/job-board/get-question-types`).then((res) => {
        if (res?.data.result) {
          const questionTypes = res.data.data;
          setOptions(questionTypes);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const getQuestionsTypeById = async (id) => {
    try {
      await API.get(`/job-board/get-questions-by-category?question_type_id=${id}`).then((res) => {
        if (res?.data.result) {
          setCatQuestion(res.data.questions);
          if(res.data.additional_info) {
            setIsAdditionalInfo(true);
            setAdditionalInfo(res.data.additional_info);
          } else {
            setIsAdditionalInfo(false);
            setAdditionalInfo('');
          }
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const getValue = (data) => {
    setCategoryName(data.name);
    setCategoryNameId(data.id);
    getQuestionsTypeById(data.id);
    if(data.name==='Custom') {
      setIsCustom(true)
    } else {
      setIsCustom(false)
    }
  };

  const handleChange = (e, data) => {
    // console.log(e, data)
    setSelectedQues((prevSelectedQues) => {
      const existingCategoryIndex = prevSelectedQues.findIndex(
        (category) => category.question_type_id === data.question_type_id
      );
  
      if (existingCategoryIndex > -1) {
        // Create a deep copy of the existing category to avoid direct mutation
        const updatedCategory = {
          ...prevSelectedQues[existingCategoryIndex],
          questions: [...prevSelectedQues[existingCategoryIndex].questions],
        };
  
        const isQuestionSelected = updatedCategory.questions.some(
          (question) => question.id === data.id
        );
  
        if (isQuestionSelected) {
          // Remove the question if it's already selected
          updatedCategory.questions = updatedCategory.questions.filter(
            (question) => question.id !== data.id
          );
  
          // If no questions remain, remove the entire category
          if (updatedCategory.questions.length === 0) {
            return prevSelectedQues.filter(
              (category) => category.question_type_id !== data.question_type_id
            );
          }
        } else {
          // Add the question to the category
          updatedCategory.questions.push({ ...data, is_required: 0, is_system: 1, is_modified: 0, fillable_part_value: null });
        }
  
        // Return the new state with the updated category
        return prevSelectedQues.map((category, index) =>
          index === existingCategoryIndex ? updatedCategory : category
        );
      } else {
        // If the category doesn't exist, add it with the selected question
        return [
          ...prevSelectedQues,
          {
            question_type_id: data.question_type_id,
            question_type: data.question_type,
            questions: [{ ...data, is_required: 0, is_system: 1,fillable_part_value: null  }],
          },
        ];
      }
    });
  };

  const handleMusthave = (data, e)=> {
    console.log(data, e.target.checked)
    setSelectedQues((prevSelectedQues) => {
      return prevSelectedQues.map((category) => {
        if (category.question_type_id === data.question_type_id) {
          const updatedQuestions = category.questions.map((question) => {
            if (question.id === data.id) {
              return { ...question, is_required: e.target.checked ? 1 : 0 };
            }
            return question;
          });
          return { ...category, questions: updatedQuestions };
        }
        return category;
      });
    });
  }
  
  const handleIdealAnswer = (answer, data)=> {
    setSelectedQues((prevSelectedQues) => {
      return prevSelectedQues.map((category) => {
        if (category.question_type_id === data.question_type_id) {
          const updatedQuestions = category.questions.map((question) => {
            if (question.id === data.id) {
              return { ...question, ideal_answer: answer };
            }
            return question;
          });
          return { ...category, questions: updatedQuestions };
        }
        return category;
      });
    });
  }
  
  const handleSubmit = ()=> {
    const missingIdealAnswer = selectedQues.some((category) => 
      category.questions.some((question) => !question.hasOwnProperty('ideal_answer'))
    );

    if (missingIdealAnswer) {
      // alert("Some questions are missing the 'ideal_answer' key. Please provide all required information before submitting.");
      setIdealAnswerWarning(true)
      return;
    } else {
      const updatedSelectedQues = selectedQues.map((category) => {
        const updatedQuestions = category.questions.map((question) => {
          const { id, answer_options, order, question_type, question_type_id, question_id, ...remainingQuestion } = question;
          return {...remainingQuestion, question_id: isEdit ? question_id : id};
        });
    
        return {
          ...category,
          questions: updatedQuestions,
        };
      });
      saveResult(updatedSelectedQues)
    }
  }
  
  const handleCustomSubmit = async ()=> {
    const searchParams = new URLSearchParams(location.search);
    const jID = searchParams.get("job_id");
    // console.log(formData)
    let customForm = {
      job_id: jID,
      user_id: user.id,
      question_type_id:18,
      question_text: formData.question_text,
      ideal_answer: formData.ideal_answer,
      is_system: 0,
      is_required: formData.is_required ? 1 : 0
    };
    try {
      await API.post(`/job-board/save-custom-screening-questions`, customForm).then((res) => {
        if (res?.data.result) {
          toast.success(res.data.message.success, {
            autoClose: 2000,
            closeOnClick: false,
          });
          clearFormData()
          getQuestionsTypeById(18)
        }
      });
    } catch (error) {
      console.log(error);
    }
  }

  const clearFormData = () => {
    setFormData({
      question_text: "",
      ideal_answer: "",
      is_required: false,
    });
  };

  const handleMusthaveCustom = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: type === "checkbox" ? checked : value, // For checkbox, use `checked`, otherwise use `value`
    }));
  };

  const saveResult = async (data) => {
    const searchParams = new URLSearchParams(location.search);
    const jID = searchParams.get("job_id");
    let formData = {
      response: {
        screening_questions: data
      },
      job_id: jID,
      user_id: user.id
    };
    try {
      await API.post(`/job-board/save-job-screening-questions`, formData).then((res) => {
        if (res?.data.result) {
          setIdealAnswerSuccess(true)
        }
      });
    } catch (error) {
      console.log(error);
    }
  }

  const closeSuccess = () => {
    setIdealAnswerSuccess(false)
   // navigate(-1)
   navigate("/jobs-posted");
  }
  
  const saveDesiredAnswer = (e, ques) => { 
    const newValue = e.target.value;
    const updatedQuestions = selectedQues.map(category => {
      return{
        ...category,
        questions: category.questions.map(question => {
          if(question.param_input_type === 'search_input' && question.question_text === ques.question_text) {
            return {...question, fillable_part_value: newValue }
          }
          return question
        })
      }
    })
    setSelectedQues(updatedQuestions); 
  }
  
  const onResultClick = (e, ques) => {
    setCompanyUserHide(false)
    const matchObj = selectedQues.find((item) => item.question_type === ques.question_type)
    if(matchObj) {
      matchObj.questions.forEach((question) => {
        // Check if question_text matches
        if (question.question_text === ques.question_text) {
          // Insert the new key-value pair
          question.fillable_part_value = e;
        }
      });
    } 
    // setSearchResult(e)
  }

  const saveInputAnswer = (e, ques) => {
    const matchObj = selectedQues.find((item) => item.question_type === ques.question_type)
    if(matchObj) {
      matchObj.questions.forEach((question) => {
        // Check if question_text matches
        if (question.question_text === ques.question_text) {
          // Insert the new key-value pair
          question.fillable_part_value = e;
        }
      });
    } 
  }

  const editQuestion = (id, text) =>{
    setEditMode((prevModes) => ({
      ...prevModes,
      [id]: !prevModes[id] // Toggle the edit mode for the selected question
    }));
    setEditText((prevTexts) => ({
      ...prevTexts,
      [id]: text // Set the current text to be edited for this question
    }));
  }

  const cancelEditQues = (id) =>{
    setEditMode((prevModes) => ({
      ...prevModes,
      [id]: false // Close edit mode without saving changes
    }));
    setEditText((prevTexts) => ({
      ...prevTexts,
      [id]: false // Reset the text to the original question text
    }));
  }

  const handleChangeQuestion = (e, id) => {
    // setEditText(e.target.value);
    setEditText((prevTexts) => ({
      ...prevTexts,
      [id]: e.target.value
    }));
  }
  const handleSubmitUpdate = (data) =>{
    setSelectedQues((prevSelectedQues) => {
      return prevSelectedQues.map((category) => {
        if (category.question_type_id === data.question_type_id) {
          const updatedQuestions = category.questions.map((question) => {
            if (question.id === data.id) {
              return { ...question, question_text: editText[data.id], is_modified: 1 };
            }
            return question;
          });
          return { ...category, questions: updatedQuestions };
        }
        return category;
      });
    });

    // setSelectedQues((prevQuestions) =>
    //   prevQuestions.map((question) =>
    //     question.id === id ? { ...question, text: editText[id] } : question
    //   )
    // );
    console.log(selectedQues)
    setEditMode((prevModes) => ({
      ...prevModes,
      [data.id]: false // Close edit mode for this question
    }));
  }

  const getSavedQuestions = () => {
    API.get(`/job-board/edit-job-questions?currentJobId=${jID}`)
    .then((response) => {
      setSelectedQues(response.data.questions)
    })
    .catch((error) => {
      if (error) {
        console.log(error);
      }
    });
  }

  useEffect(() => {
    if(isEdit) {
      getSavedQuestions()
    }
  }, [])

  useEffect(() => {
    const getData = setTimeout(() => {
      try {
        if (searchResult) {
          API.get(`/job-board/get-question-fillable-part?type=${type}&query_key=${searchResult}`)
            .then((response) => {
              setCompanyUserHide(true)
              setSearchResults(response.data)
            })
            .catch((error) => {
              if (error) {
                console.log(error);
              }
            });
        }
      } catch (error) {
        console.log(error);
      }
    }, 1000)

    return () => clearTimeout(getData)
  }, [searchResult])
  

  return (
    <>
    {console.log(selectedQues)}
      <div className="page-space bg-clr-admin">
        <div className='flex-start-center page-title-heading'>
          <img src='/image/form-back-arrow.png' alt="back" className='me-2 cursor-pointer' onClick={() => navigate(-1)} />
          <h2 className='m-0'>Pre-screening questions</h2>
        </div>
        <div className="q-body">
          <div className="container-prop pb-4">
            <h1>Create your pre-screening questions {""}</h1>
            <div className="radio-container">
              <RadioGroup options={options} name="category" getValue={getValue} />
            </div>

            {
              isCustom && (
                <>
                  <div className="q-a">
                    <div className="single-qa mb-3" >
                      <div className="q-head">
                        <div className="flex-start-center">
                          <div className="form-check flex-start-center ms-3 ps-0">
                            <label className="form-check-label">
                              Question
                            </label>
                          </div>
                        </div>
                        <div className="chk d-none">
                          <input
                            type="checkbox"
                            id="html"
                            name="is_required"
                            value="HTML"
                            // checked={formData.is_required}
                            onChange={handleMusthaveCustom}
                          />
                          <label htmlFor="html">Must have</label>
                        </div>
                      </div>

                      <div className="qa-body">
                        <div className="mt-2 mb-3">
                          <textarea className="form-control" onChange={handleMusthaveCustom} 
                          value={formData.question_text} name='question_text'
                          placeholder="Try asking a question like, “will you be able to bring your own device?”">

                          </textarea>
                        </div>
                        <div className="d-none">
                          <div>
                            <label htmlFor='answerIs'>Response type:</label>
                            <select class="form-select" id='answerIs' aria-label="Default select example">
                              <option value='yes'>Yes/No</option>
                            </select>
                          </div>
                          <div className="ms-3">
                            <label htmlFor='answerIs'>Ideal Answer</label>
                            <select class="form-select" id='answerIs' aria-label="Default select example" 
                            value={formData.ideal_answer} name='ideal_answer'
                            onChange={handleMusthaveCustom} >
                              <option value='Yes'>Yes</option>
                              <option value='No'>No</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="q-a">
                    <div className="row justify-content-end mb-4">
                      <div className="col-md-5">
                        <div className="row">
                          <div className="col-md-6">
                            <button className="btn btn-outline-black btn-lg w-100 fw-normal" onClick={()=>navigate(-1)}>
                              Cancel
                            </button>
                          </div>
                          <div className="col-md-6">
                            <button className="btn btn-black btn-lg w-100 fw-normal" onClick={handleCustomSubmit}>
                              Save
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )
            }

            {isAdditionalInfo ? <div className="px-5 py-4 mb-3">
              <div className="card question-claim">
                <div className="card-body">
                  <div className="d-flex align-items-center gap-3">
                    <img src='/image/exclaimation-circle.png' />
                    <p className="m-0 text-justify">
                      {
                        additionalInfo ? additionalInfo : `
                          In Canada, employers can conduct background checks for job candidates as long as they follow privacy laws and are directly related to the role. It’s important to get consent from candidates and to explain what the checks involve and why they’re necessary. Any information collectedshould be relevant, accurate, and kept confidential to stay compliant with legal standards. If you have any questions or need more details, reach out to your HR representative or legal counsel.
                        `
                      }   
                    </p> 
                  </div>
                </div>
              </div>
            </div> : ''}

            {catQuestion.map((val, id) => {
              const isChecked = selectedQues.some(
                (category) =>
                  category.question_type_id === val.question_type_id &&
                  category.questions.some((question) => question.id === val.id)
              );
              return(
                <div className="q-a" key={id}>
                  <div className="single-qa">
                    <div className="q-head">
                      <div className="flex-start-center">
                        <strong>{id + 1}. {" "}</strong>
                        <div className="form-check flex-start-center ms-3">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            name={categoryName}
                            id={`qus-${val.id}`}
                            checked={isChecked}
                            onChange={(e) => handleChange(e, val)}
                          />
                          <label className="form-check-label ms-2" htmlFor={`qus-${val.id}`}>
                            {val?.question_text}
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="qa-body d-none">
                      {
                        val.response_type === 'radio' ? <>
                          {val?.answer_options.map((ans, idx) => (
                            <p className="m-0 py-1" key={idx}>{ans.option_text}</p>
                          ))} 
                        </> : <div className="w-25">
                          <label htmlFor='answerIs'>View answer</label>
                          <select class="form-select" id='answerIs' aria-label="Default select example">
                            {val?.answer_options.map((ans, idx) => (
                              <option value={ans.option_text} key={idx}>{ans.option_text}</option>
                            ))}
                            
                          </select>
                        </div>
                      }
                    </div>
                  </div>
                </div>
              )
            })}
          </div>

          {selectedQues.length > 0 && ( 
            <>
              <div className="mt-5">
                <h3 className="m-0 mb-3"><strong>Selected Questions</strong></h3>
              </div>
            
              <div className="container-prop mt-2 pt-2">
              
                  
              {selectedQues.map((category, idx) => {
                  return(
                    <div className="q-a py-3" key={idx}>
                      {category.questions.length > 0 ?  <>
                      <h3 className="cat-tile"><strong>{category.question_type}:</strong></h3>
                      {category.questions.map((question, ids) => {
                      return(
                        <>
                        {editMode[question.id] ? (<div className="single-qa mb-3 selected-ques">
                          <div className="p-4">
                            <textarea
                              type="text"
                              value={editText[question.id]}
                              className="form-control"
                              onChange={(e) => handleChangeQuestion(e, question.id)}
                              rows='3'
                            ></textarea>
                          </div>
                          <div className="mt-2">
                            <div className="q-a">
                              <div className="row justify-content-end mb-4">
                                <div className="col-md-5">
                                  <div className="row">
                                    <div className="col-md-6">
                                      <button className="btn btn-outline-black btn-lg w-100 fw-normal" onClick={()=>cancelEditQues(question.id)}>
                                        Cancel
                                      </button>
                                    </div>
                                    <div className="col-md-6">
                                      <button className="btn btn-black btn-lg w-100 fw-normal" onClick={() => handleSubmitUpdate(question)}>
                                        Edit & Save
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>) : 
                        <div className="single-qa mb-3 selected-ques" key={ids}>
                          <div className="q-head">
                            <div className="flex-start-center">
                              <strong>{ids + 1}. {" "}</strong>
                              <div className="form-check flex-start-center ms-3">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  name={categoryName}
                                  id={`qus-${question.id}`}
                                  // defaultChecked='true'
                                  checked={true}

                                  onChange={(e) => handleChange(e, question)}
                                />
                                <label className="form-check-label ms-2" htmlFor={`qus-${question.id}`}>
                                  {question.question_text}
                                </label>
                              </div>
                            </div>
                            <div className="d-flex align-items-center gap-3">
                              <div className="chk">
                                <input
                                  type="checkbox"
                                  id="html"
                                  name="fav_language"
                                  value="HTML"
                                  defaultChecked={question.is_required}
                                  onChange={(e) => handleMusthave(question, e)}
                                />
                                {/* {console.log(question)} */}
                                <label className="p-0">Must have</label>
                              </div>
                              <button className="btn btn-transparent p-0" onClick={() => editQuestion(question.id, question.question_text)}>
                                <img src='/image/edit-ep-inbox.png' alt="edit" />
                              </button>
                            </div>
                          </div>

                          <div className="qa-body with-ans">
                            <div className="col-md-5 p-0">
                              {
                                question.param_input_type === 'text_input' ? <>
                                  <input type="text" className="form-control" placeholder="Enter here" onChange={(e)=> saveInputAnswer(e.target.value, question)} />
                                </> : question.param_input_type === 'search_input' ? <>
                                <div className={`share-list-feild p-0`}>
                                  <div
                                    id="dropdown-boxId2"
                                    className="company-form-dropdownBox1 custom-tag-input max-w-100"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                  >
                                    <div className="text-filter-admin">
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Search here"
                                        name="fillable_part_value"
                                        value={question.fillable_part_value}  
                                        onChange={(e) => {
                                          saveDesiredAnswer(e, question);
                                          setSearchResult(e.target.value);
                                          setType(question.custom_param)
                                        }}
                                      />
                                      <img className="s-img" src="/image/search-gray.png" alt="src-icon"/>
                                    </div>
                                  </div>                                
                                  {searchResults?.length > 0 && (
                                    <div
                                      className={`admin-status-dropdown-frm ${
                                        allCompanyUserHide && "job-dropdown-hide"
                                      }`}
                                    >
                                      <ul className="list-share-drop">
                                        {searchResults?.map((j, i) => (
                                          <li key={i} onClick={() => onResultClick(j, question)}>
                                            {j}
                                          </li>
                                        ))}
                                      </ul>
                                    </div>
                                  )}
                                </div>
                                </> : ''
                              }
                            </div>
                            <div>
                              <label htmlFor='answerIs' className="mb-2">Ideal Answer:</label>
                              <select className="form-select custom-select" id='answerIs' aria-label="Default select example" 
                              onChange={(e)=>handleIdealAnswer(e.target.value, question)} value={question.ideal_answer != undefined ? question.ideal_answer : 'DEFAULT' }>
                                <option value="DEFAULT" disabled selected>Ideal answer</option>
                                {question?.answer_options?.map((ans, idx) => {
                                  return(
                                    <option selected={question.ideal_answer === ans.option_text ? 'true' : 'false'} value={ans.option_text} key={idx}>{ans.option_text}</option>
                                  )  
                                })}
                                
                              </select>
                            </div>
                          </div>
                        </div>
                          }
                      </>
                      
                      )
                      })}</>
                      : '' }
                    </div>
                  );
                })}
               
              </div>
              {
                  selectedQues.length ? (
                    <div className="q-a mt-2">
                      <div className="row justify-content-end">
                        <div className="col-md-5">
                          <div className="row">
                            <div className="col-md-6">
                              <button className="btn btn-outline-black btn-lg w-100 fw-normal" onClick={()=>navigate(-1)}>
                                Cancel
                              </button>
                            </div>
                            <div className="col-md-6">
                              <button className="btn btn-black btn-lg w-100 fw-normal" onClick={handleSubmit}>
                                Save
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : ''
                }
            </>
          )}
        </div>
      </div>
      {idealAnswerWarning && (
        <ActionModal
          heading="Verify"
          content="Please select your ideal answers."
          closeModal={() => setIdealAnswerWarning(false)}
          modalArt='/image/warning-wishlist.png'
        ></ActionModal>
      )}
      {idealAnswerSuccess && (
        <ActionModal
          heading="Success"
          content="Your pre-screening questions are ready to be sent."
          closeModal={closeSuccess}
          modalArt='/image/success-modal.png'
        ></ActionModal>
      )}
    </>
  );
};

export default InterviewQuestions;
