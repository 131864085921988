import { useEffect, useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import "./index.css";

const SelectableResume = ({
  submitData,
  setSubmitData,
  resume,
  setResumeError,
}) => {
  const isResumeSelected =
    submitData.id !== null
      ? resume?.id === submitData?.id
      : resume.is_default === 1;

  return (
    <div className="apply-row-border align-items-center d-flex mb-2">
      <div className="p-3 border-end">
        <img src="/image/PDF.png" alt="" width={20} height={27} />
      </div>
      <div className="p-2 d-flex justify-content-between flex-grow-1 align-items-center">
        <div>
          <p className="resume-title">
            {resume?.resume_display_name || "Resume"}
          </p>
          <p className="resume-date text-secondary mt-1">
            Last Updated on {new Date(resume?.updated_at).toDateString()}
          </p>
        </div>
        <div className="apply-row-border rounded-circle radio-container-teal">
          <div
            className={`rounded-circle apply-now-radio-btn ${
              isResumeSelected ? "bg-success-teal" : ""
            }`}           
            onClick={() => {
              setSubmitData({ ...submitData, id: resume?.id });
              setResumeError("");
            }}
          />
        </div>
      </div>
    </div>
  );
};

const Steps = ({ submitData, setSubmitData, containerClassName }) => {
  const { user } = useSelector((state) => state.auth);

  const [includeProfile, setIncludeProfile] = useState(false);
  const [includeSalaryPreference, setIncludeSalaryPreference] = useState(false);
  const [profileError, setProfileError] = useState("");

  const includeProfileHandler = (e) => {
    const profileCheckValue = e.target.checked;
    setIncludeProfile(profileCheckValue);
    setSubmitData({ ...submitData, profile: profileCheckValue });
  };

  const includeSalaryPreferenceHandler = (e) => {
    const salaryCheckValue = e.target.checked;

    if (
      salaryCheckValue && (
      user?.expected_salary === "0.00" ||
      user?.expected_salary === null ||
      user?.expected_salary === "0")
    ) {
      setProfileError("Set your salary preference in your profile");
      setTimeout(() => setProfileError(""), 5000);
      setIncludeSalaryPreference(false);
      return;
    }

    setIncludeSalaryPreference(salaryCheckValue);
    setSubmitData({ ...submitData, salary: salaryCheckValue });
    setProfileError("");
  };

  return (
    <div className={containerClassName}>
      <div className="mb-3">
        <div className="d-flex justify-content-between">
          <div className="apply-on-job-modal-step section-title">
            2. Include your profile?
          </div>
          <div className="form-check form-switch ms-auto d-flex flex-row-reverse">
            <input
              className="form-check-input"
              type="checkbox"
              id="flexSwitchCheckDefault"
              onChange={includeProfileHandler}
              value={includeProfile}
              checked={includeProfile}
            />{" "}
            <label
              className={`form-check-label label-switch me-5 ${
                includeProfile ? "label-teal" : ""
              }`}
              htmlFor="flexSwitchCheckDefault"
            >
              {includeProfile ? "On" : "Off"}
            </label>
          </div>
        </div>
        <p className="w-75 apply-on-job-modal-step-description ms-data mt-2">
          Boost your application by sharing your complete profile with
          employers, Toggle on to include it.
        </p>
      </div>
      <div className="mb-3">
        <div className="d-flex justify-content-between">
          <div className="apply-on-job-modal-step section-title ms-data">
            Salary preference?
          </div>
          <div className="form-check form-switch ms-auto d-flex flex-row-reverse">
            <input
              className="form-check-input"
              type="checkbox"
              id="flexSwitchCheckDefault"
              onChange={includeSalaryPreferenceHandler}
              value={includeSalaryPreference}
              checked={includeSalaryPreference}
            />
            <label
              className={`form-check-label label-switch me-5 ${
                includeSalaryPreference ? "label-teal" : ""
              }`}
              htmlFor="flexSwitchCheckDefault"
            >
              {includeSalaryPreference ? "On" : "Off"}
            </label>
          </div>
        </div>
        <p className="w-75 apply-on-job-modal-step-description ms-data mt-2">
          Let employers know your salary expectations. Toggle on to share.
        </p>
        {profileError && <p className="ms-data error-msg">{profileError}</p>}
      </div>
      <div className="">
        <p className="apply-on-job-modal-step section-title">
          3. Ready to submit?
        </p>
        <p className="w-75 apply-on-job-modal-step-description ms-data mt-2">
          Quickly double check the information above and hit “Submit” when
          you’re all set!
        </p>
      </div>
    </div>
  );
};

export const ApplyJobSteps = ({
  setSubmitData,
  submitData,
  handleSubmit,
  applyingToJob,
  show,
  onHide,
}) => {
  const { user } = useSelector((state) => state.auth);
  const { resumes } = useSelector((state) => {
    return state.auth;
  });
  const [loadingResumes, setLoadingResumes] = useState(true);
  const [resumeError, setResumeError] = useState("");

  const onSUbmitHandler = () => {
    if (submitData?.id === null || applyingToJob) {
      setResumeError("Select your resume");
      setTimeout(() => {
        setResumeError("");
      }, 5000);
    } else {
     handleSubmit(submitData?.id, submitData?.profile, submitData?.salary);
    }
  };

  const getJobDetail = async () => {
    const hasDefaultResume = resumes?.some((item) => item?.is_default === 1);
    if (hasDefaultResume) {
      setSubmitData({
        ...submitData,
        id: resumes?.find((item) => item?.is_default === 1).id,
      });
    }
    setLoadingResumes(false);
  };

  useEffect(() => {
    if (resumes) {
      getJobDetail();
    }
  }, [resumes]);

  return (
    <div
      className="modal showd-block position-static"  
    >
      <Modal
        show={show}
        onHide={onHide}
        size="lg"
        contentClassName="apply-modal"
      >
        <Modal.Header className="apply-modal-header">
          <div className="flex-grow-1">
            <img src="/image/polycareers-north-macedonia.png" width={160} />
            <p className="text-center mt-3 apply-job-modal-title">
              Submit your application in 3 easy steps
            </p>
          </div>
          <img
            src="/image/close-icon.png"
            className="close-icon-button pointer"
            onClick={onHide}
          />
        </Modal.Header>
        <Modal.Body className="">
          <div className="mb-3 apply-modal-body d-flex flex-column align-self-center mx-auto">
            <p className="apply-on-job-modal-step mb-3 section-title">
              1. Select your resume
            </p>
            <div className="resume-container pb-2">
              {loadingResumes ? (
                <div className="d-flex justify-content-center align-items-center flex-column">
                  <Spinner />
                  <p className="mt-3">Loading Resumes</p>
                </div>
              ) : (
                resumes?.map((resume) => (
                  <SelectableResume
                    setSubmitData={setSubmitData}
                    submitData={submitData}
                    resume={resume}
                    key={resume?.id}
                    setResumeError={setResumeError}
                  />
                ))
              )}
            </div>
            {resumeError !== "" && <p className="error-msg">{resumeError}</p>}
          </div>

          <Steps
            setSubmitData={setSubmitData}
            submitData={submitData}
            containerClassName="apply-modal-body mx-auto"
          />
        </Modal.Body>
        <Modal.Footer className="border-0 justify-content-center">
          <div className="apply-modal-footer w-100 d-flex justify-content-end pb-3">
            <button
              type="button"
              className="btn btn-dark btn-lg px-5 bg-dark"
              onClick={() => {
                onSUbmitHandler();
              }}
            >
              Submit{" "}
              {applyingToJob && (
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                />
              )}
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
