import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  FacebookIcon,
  LinkedinIcon,
  TwitterIcon,
} from "react-share";
import JobDetailSec from "./JobDetailSec";
import JobDetailRightSec from "./JobDetailRightSec";
import "./JobDescription.css";
import MetaTags from "../../../utils/MetaTags";
import { JOB_GEN_METHOD } from "../../../constants";

const JobDescription = ({ jobDetail, qrCodeData, shareUrl }) => {
  const location = useLocation();

  let jobDesDetail = Object.assign({}, jobDetail[0]);

  const [recruiterNotes, setRecruiterNotes] = useState([""]);
  const [mustSkills, setMustSkills] = useState([""]);
  const [niceToHave, setNiceToHave] = useState([""]);
  const [interviewProcess, setInterviewProcess] = useState([""]);

  useEffect(() => {
    let rec = jobDetail[0]?.recruiter_notes;
    let skill = jobDetail[0]?.must_skills;
    let nice = jobDetail[0]?.nice_to_have;
    let interview = jobDetail[0]?.hiringSteps;
    setRecruiterNotes(rec);
    setMustSkills(skill);
    setNiceToHave(nice);
    setInterviewProcess(interview);
  }, [jobDetail]);


  return (
    <>
      {" "}
      {console.log(jobDetail)}
      <section className="job-description-sec">
        <div className="container container-site">
          <div className="space-inner-row">
            <div className="Job-Opportunities-row p-0">
              <div
                className="Job-Opportunities-row-company p-0"              
              >
                <div className="job-company-details">
                  {jobDesDetail?.description !== "" && (
                    <div className="job-heading-detail-sec">
                      <h5 className="job-heading-detail-summary">
                        {jobDesDetail?.generation_method === JOB_GEN_METHOD.SELF_DESC ? "Job description" : "Job summary"}                        
                      </h5>
                      <div className="view-job-detail-space-1">
                      {ReactHtmlParser(jobDesDetail?.description)}
                      </div>                      
                    </div>
                  )}

                  {jobDesDetail?.generation_method !== JOB_GEN_METHOD.SELF_DESC && 
                  <>                  
                    {jobDesDetail?.responsibilities?.length > 0 && (
                    <JobDetailSec
                      label="Responsibilities"
                      description={jobDesDetail?.responsibilities}
                    />
                  )}

                  {jobDesDetail?.must_skills?.length > 0 && (
                      <JobDetailSec
                        label="Must-have skills"
                        description={jobDesDetail?.must_skills}
                      />
                  )}                                 
                    
                  {jobDesDetail?.nice_to_have?.length > 0 && (
                      <JobDetailSec
                        label="Nice to have"
                        description={jobDesDetail?.nice_to_have}
                      />
                  )}
                 
                  {jobDesDetail?.skills?.length > 0 && (
                    <JobDetailSec
                      label="Qualifications"
                      description={jobDesDetail?.skills}
                    />
                  )}                             
                  </>
                }
                 
                  <span className="mbl-mice-sec">
                    {jobDesDetail?.hiringSteps?.length > 0 && (
                      <div className="job-heading-detail-sec w-100">
                        <h5>Interview stages</h5>
                        <div className="skill-list-j">
                          <ul className="ints-steps">
                            {jobDesDetail?.hiringSteps?.map((val, id) => {
                              return (
                                <li className="ms-3" key={id}>
                                  {val?.name}
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      </div>
                    )}
                  </span>
                  {jobDesDetail?.generation_method !== JOB_GEN_METHOD.SELF_DESC &&
                  <>
                    {jobDesDetail?.benefits?.length > 0 && (
                    <JobDetailSec
                      label="Benefits"
                      description={jobDesDetail?.benefits}
                    />
                  )}

                  {jobDesDetail?.working_hrs?.length > 0 && (
                    <JobDetailSec
                      label="Working hours"
                      description={jobDesDetail?.working_hrs}
                    />
                  )}           
                  </>
                  }                      
                </div>
                <div className="company-job-announcements">
                  <div className="job-announcements-col">
                    <div className="job-company-right">
                      {jobDesDetail?.jobCities?.length > 0 && (
                        <div className="job-location-title">
                          <p className="company-j-info-l">Job location </p>
                          <ul className="job-location-list">
                            {jobDesDetail?.jobCities.map((item, i) => (
                              <li>{item?.name}</li>
                            ))}
                          </ul>
                          <div className="company-right-col"></div>
                        </div>
                      )}
                      {jobDesDetail?.generation_method !== JOB_GEN_METHOD.SELF_DESC && 
                      <>
                       {jobDesDetail?.reportingPerson?.length > 0 && (
                        <>
                          {" "}
                          <JobDetailRightSec
                            label="Reporting to"
                            value={jobDesDetail?.reportingPerson}
                          />
                          <div className="company-right-col"></div>
                        </>
                      )}

                      {jobDesDetail?.workMethodology?.length > 0 && (
                        <JobDetailRightSec
                          label="Work methodology"
                          value={jobDesDetail?.workMethodology}
                        />
                      )}
                      </>
                      }                                                            
                      {jobDesDetail?.tags?.length > 0 && (
                        <>
                          <div className="company-right-col"></div>
                          <div className="job-skills-detail-col">
                            {jobDesDetail?.tags.map((i) => (
                              <button>{i?.name?.trim()}</button>
                            ))}
                          </div>
                        </>
                      )}                    
                      <span className="Interview-desktop-sec">
                        {jobDesDetail?.hiringSteps?.length > 0 && (
                          <>
                            <div className="company-right-col"></div>
                            <div className="j-com-dev-info">
                              <p className="company-j-info-l">
                                Interview stages
                              </p>
                              <div className="skill-list-j">
                                <ul className="ints-steps">
                                  {jobDesDetail?.hiringSteps?.map((val, id) => {
                                    return <li key={id}>{val?.name}</li>;
                                  })}
                                </ul>
                              </div>
                            </div>
                          </>
                        )}
                      </span>
                      {qrCodeData !== null && (
                        <>
                          <div className="company-right-col"></div>
                          <div className="j-com-dev-info qr-code-img">
                            <p className="company-j-info-l ">Share this job</p>
                            <img src={qrCodeData} alt="" />
                          </div>
                        </>
                      )}
                       {jobDesDetail?.recruiter_notes !== "" && (
                        <div className="recruite-note-box">
                          <h5>Recruiter notes</h5>
                          <p className="pre-line-text">
                            {jobDesDetail?.recruiter_notes && ReactHtmlParser(jobDesDetail?.recruiter_notes)}
                          </p>
                        </div>
                    )}
                      {/* <div className="social-media-share">
                        <FacebookShareButton
                          url={shareUrl}
                          quote={jobDesDetail?.full_title}
                        >
                          <FacebookIcon size={32} round />
                        </FacebookShareButton>
                        <TwitterShareButton
                          url={shareUrl}
                          title={jobDesDetail?.full_title}
                        >
                          <TwitterIcon size={32} round />
                        </TwitterShareButton>
                        <LinkedinShareButton
                          url={shareUrl}
                          title={jobDesDetail?.full_title}
                        >
                          <LinkedinIcon size={32} round />
                        </LinkedinShareButton>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default JobDescription;
