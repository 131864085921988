import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { jobTitleRegex, RESUME_ITEM, scriptOnlyHTMLTagRegex } from "../../../../constants";
import { regenerateResumeListAIAsync } from "../../../../redux/slices/verifyResumeSlice";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./CanResumeExpForm.css";

const modules = {
  toolbar: [
    ["bold", "italic", "underline"],
    [
      // { list: "unordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false,
  },
};

const formats = ["list"];

// Define a handler for the custom formats to make them readonly
const customFormats = {
  header: { readonly: true },
  list: { readonly: true },
  link: { readonly: true },
};

const CanResumeExpForm = ({
  prod,
  index,
  totalExperience,
  monthDropDown,
  yearDropDown,
  onExpChangeHandler,
  onExpSaveHandler,
  openDeleteItemHandler,
}) => {
  const uniqueId = Math.random();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const [loader, setLoader] = useState(false);
  const [showAISuggestion, setShowAISuggestion] = useState(false);
  const [loadingShowAISuggestion, setLoadingShowAISuggestion] = useState(false);
  const [suggestedResponsibilities, setSuggestedResponsibilities] = useState(
    []
  );
  const [checkedResponsibilities, setCheckedResponsibilities] = useState([]);
  const [toggleAllSuggestion, setToggleAllSuggestion] = useState(false);

  const formik = useFormik({
    initialValues: {
      company_name: "",
      job_title: "",
      city: "",
      country: "",
      start_date: {
        month: "",
        year: "",
      },
      end_date: {
        month: "",
        year: "",
      },
      roles_n_responsibilities: "",
      years_of_experience: "",
      is_present: false,
      is_correct: false,
      is_remote: false,
      achievements: [],
    },
    /*validationscheme*/
    validationSchema: Yup.object({
      company_name: Yup.string().required("Please Enter Employer Name"),
      job_title: Yup.string()
        .required("Please enter job title")
        .max(100,"JOb title cannot exceed 100 characters")
        .matches(jobTitleRegex, "Please enter valid job title"),
      start_date: Yup.object().shape({
        month: Yup.string(),
        year: Yup.string(),
      }),
      end_date: Yup.object().shape({
        month: Yup.string(),
        year: Yup.string(),
      }),
      roles_n_responsibilities: Yup.string()
          .matches(scriptOnlyHTMLTagRegex, "Please enter valid roles and responsibilities")
          .required("Please enter roles and responsibilities"),    
    }),

    onSubmit: async (data, { resetForm, setErrors }) => {
      setLoader(true);

      if (checkedResponsibilities?.length > 0) {
        const allResponsibilities = checkedResponsibilities
          ?.map((responsibility) => `<li>${responsibility}</li>`)
          .join("");

        const currentValue = formik?.values?.roles_n_responsibilities || "";
        let updatedValue = currentValue?.trim();

        // Check if the current value contains a <ul> tag
        if (updatedValue?.includes("<ul>") && updatedValue?.includes("<li>")) {
          updatedValue = currentValue?.replace(
            "</ul>",
            `${allResponsibilities}</ul>`
          );
        } else {
          updatedValue = `${currentValue}<ul>${allResponsibilities}</ul>`;
        }

        await formik.setFieldValue("roles_n_responsibilities", updatedValue);

        let newFormData = { ...formik?.values };
        newFormData = {
          ...newFormData,
          roles_n_responsibilities: updatedValue,
        };
        await onExpSaveHandler(newFormData, index);
        setToggleAllSuggestion(false);
      } else {
        await onExpSaveHandler();
      }

      const timer = setTimeout(() => {
        setLoader(false);
        setSuggestedResponsibilities([]);
        setCheckedResponsibilities([]);
        setShowAISuggestion(false);
      }, 2000);

      return () => clearTimeout(timer);
    },
  });

  const regenerateResumeAI = async (selectedExp) => {
    const { job_title, roles_n_responsibilities, is_present } = selectedExp;
    if (user?.id !== null) {
      setLoadingShowAISuggestion(true);
      setSuggestedResponsibilities([]);
      dispatch(
        regenerateResumeListAIAsync({
          userId: user?.id,
          jobTitle: job_title,
          sectionName: "experience",
          sectionValue: roles_n_responsibilities,
          promptInput: "",
          aiGen: suggestedResponsibilities,
          isPresent: is_present,
        })
      )
        .then(async (response) => {
          setLoadingShowAISuggestion(false);
          const res = response?.payload;
          if (res?.result === true) {
            const { value } = res?.response;
            if (value?.length > 0) setSuggestedResponsibilities(value);
          } else {
            setLoadingShowAISuggestion(false);
            toast.error(res?.message?.error, {
              autoClose: 2000,
              closeOnClick: false,
            });
          }
        })
        .catch((error) => {
          const { response } = error;
          const { data } = response;
          toast.error(data?.error, {
            autoClose: 2000,
            closeOnClick: false,
          });
          setLoadingShowAISuggestion(false);
          console.log(error);
        });
    }
  };

  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <p className="error-msg">{formik.errors[name]}</p>
      )
    );
  };

  const isFormNFieldValid = (name) => {
    const [parent, child] = name.split(".");
    return !!(
      formik.touched[parent]?.[child] && formik.errors[parent]?.[child]
    );
  };

  const getFormNErrorMessage = (name) => {
    const [parent, child] = name.split(".");
    return (
      isFormNFieldValid(name) && (
        <p className="error-msg">{formik.errors[parent]?.[child]}</p>
      )
    );
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };
  
  const handleCheckboxChange = async (i, p) => {
    // Toggle checked status
    const isChecked = checkedResponsibilities?.includes(p);
    const updatedCheckedResponsibilities = isChecked
      ? checkedResponsibilities.filter((item) => item !== p)
      : [...checkedResponsibilities, p];
    setCheckedResponsibilities(updatedCheckedResponsibilities);
  };

  const handleAllCheckboxChange = async (e) => {
    // Toggle checked status

    const isChecked = e.target.checked;
    const updatedCheckedResponsibilities = isChecked
      ? [...suggestedResponsibilities]
      : [];
    setCheckedResponsibilities(updatedCheckedResponsibilities);
  };

  const onCustomChangeHandler = async (e) => {
    const { name, value } = e?.target;
    let newFormData = { ...formik?.values };

    if (name.includes(".")) {
      const [parent, child] = name.split(".");

      await formik.setFieldValue(parent, {
        ...formik.values[parent],
        [child]: value,
      });

      newFormData = {
        ...formik?.values,
        [parent]: {
          ...formik.values[parent],
          [child]: value,
        },
      };
    } else {
      if (name === "is_present" && value) {
        await formik.setFieldValue(name, value);
        await formik.setFieldValue("end_date", { month: "", year: "" });

        newFormData = {
          ...formik?.values,
          end_date: { month: "", year: "" },
          [name]: value,
        };
      } else {
        await formik.setFieldValue(name, value);

        newFormData = {
          ...formik?.values,
          [name]: value,
        };
      }
    }
    // Update is_correct flag based on conditions
    const isStartDatePresent =
      newFormData?.start_date?.month !== "" &&
      newFormData?.start_date?.year !== "";
    const isEndDatePresent =
      newFormData?.end_date?.month !== "" && newFormData?.end_date?.year !== "";
    const isPresent = newFormData?.is_present;

    const isCorrect =
      (isStartDatePresent && isEndDatePresent) ||
      (isStartDatePresent && isPresent);

    newFormData = {
      ...newFormData,
      is_correct: isCorrect,
    };

    await formik.setFieldValue("is_correct", isCorrect);

    onExpChangeHandler(newFormData, index);
  };

  const onCustomDateClickHandler = async (name, value) => {
    let newFormData = { ...formik?.values };
    if (name.includes(".")) {
      const [parent, child] = name.split(".");

      await formik.setFieldValue(parent, {
        ...formik.values[parent],
        [child]: value,
      });

      newFormData = {
        ...formik?.values,
        [parent]: {
          ...formik.values[parent],
          [child]: value,
        },
      };

      // Update is_correct flag based on conditions
      // Update is_correct flag based on conditions
      const isStartDatePresent =
        newFormData?.start_date?.month !== "" &&
        newFormData?.start_date?.year !== "";
      const isEndDatePresent =
        newFormData?.end_date?.month !== "" &&
        newFormData?.end_date?.year !== "";
      const isPresent = newFormData?.is_present;

      const isCorrect =
        (isStartDatePresent && isEndDatePresent) ||
        (isStartDatePresent && isPresent);

      newFormData = {
        ...newFormData,
        is_correct: isCorrect,
      };

      await formik.setFieldValue("is_correct", isCorrect);

      onExpChangeHandler(newFormData, index);
    }
  };

  const getExpData = async () => {
    await formik.setFieldValue("company_name", prod?.company_name || "");
    await formik.setFieldValue("job_title", prod?.job_title || "");
    await formik.setFieldValue("city", prod?.city || "");
    await formik.setFieldValue("country", prod?.country || "");
    await formik.setFieldValue(
      "start_date",
      prod?.start_date || { month: "", year: "" }
    );
    await formik.setFieldValue(
      "end_date",
      prod?.end_date || { month: "", year: "" }
    );

    await formik.setFieldValue(
      "roles_n_responsibilities",
      typeof prod?.roles_n_responsibilities === "string"
        ? prod?.roles_n_responsibilities
        : ""
    );

    await formik.setFieldValue("is_present", prod?.is_present || false);
    await formik.setFieldValue("is_correct", prod?.is_correct || false);

    await formik.setFieldValue("is_remote", prod?.is_remote || false);
    await formik.setFieldValue(
      "years_of_experience",
      prod?.years_of_experience || ""
    );
    await formik.setFieldValue("achievements", prod?.achievements || []);
  };

  useEffect(() => {
    getExpData();
  }, [totalExperience]);

  return (
    <div
      id={`collapseOne-${uniqueId}`}
      className="accordion-collapse collapse show"
      aria-labelledby="headingOne"
      data-bs-parent="#accordionExample"
    >
      <div className="accordion-body educ-res-right">
        <div className="accordion" id={`sub-accordionExample-${uniqueId}`}>
          <div
            className={`accordion-item experince-res-ver-right ${
              formik?.values?.is_correct === false && "border-box-clr-res"
            }`}
          >
            <h2
              className="accordion-header sub-header"
              id={`sub-heading-${uniqueId}`}
            >
              <button
                className="accordion-button data-tab-res-right"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target={`#sub-collapseanswer-exp-${uniqueId}`}
                aria-expanded="false"
                aria-controls={`collapse-${uniqueId}`}
                onClick={(e) => e.preventDefault()}
              >
                <div className="job-title-resume-form">
                  <div className="compay-name-job-resume-right">
                    <p className="right-resume-cmp">
                      {formik?.values?.company_name !== ""
                        ? formik?.values?.company_name
                        : "Employer"}
                    </p>
                    <p className="job-title-res-right">
                      {formik?.values?.job_title !== ""
                        ? formik?.values?.job_title
                        : "Job Title"}
                    </p>
                  </div>
                  <div className="date-job-res gap-2 m-0">
                    <p>
                      {formik?.values?.start_date?.month === "" &&
                      formik?.values?.start_date?.year === ""
                        ? "Start Date"
                        : `${formik?.values?.start_date?.month?.substring(
                            0,
                            3
                          )} ${formik?.values?.start_date?.year}`}{" "}
                      {formik?.values?.is_present
                        ? "- Present"
                        : formik?.values?.end_date?.month === "" &&
                          formik?.values?.end_date?.year === ""
                        ? "- End Date"
                        : `- ${formik?.values?.end_date?.month?.substring(
                            0,
                            3
                          )} ${formik?.values?.end_date?.year}`}
                    </p>
                    <img
                      src="/image/edit-tab.png"
                      className="edit-res"
                      alt=""
                    />
                    <img
                      className="del-res"
                      src="/image/delet-res.png"
                      alt=""
                      onClick={(e) => {
                        e.stopPropagation();
                        openDeleteItemHandler(
                          index,
                          RESUME_ITEM.EXPERIENCE,
                          true
                        );
                      }}
                    />
                  </div>
                </div>
              </button>
            </h2>
            <div
              id={`sub-collapseanswer-exp-${uniqueId}`}
              className="accordion-collapse collapse"
              aria-labelledby={`sub-heading-${uniqueId}`}
              data-bs-parent={`#sub-accordionExample-${uniqueId}`}
            >
              <div className="accordion-body side-space">
                <h2
                  className="accordion-header sub-header"
                  id={`sub-heading-${uniqueId}`}
                >
                  <button
                    className="accordion-button resume-exper-right-tb"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#sub-collapseOne"
                    aria-expanded="true"
                    aria-controls={`collapseOne-${uniqueId}`}
                  >
                    <form onSubmit={formik.handleSubmit} onKeyDown={handleKeyDown}>
                      <div className="resume-personal-form f-feild-res">
                        <div
                          className={`first-name-res ${
                            isFormFieldValid("company_name") && "error-input"
                          }`}
                        >
                          <label for="fname">Employer</label>
                          <br />
                          <input
                            type="text"
                            placeholder="Employer"
                            className="feild-stl-res form-font-f"
                            name="company_name"
                            value={formik?.values?.company_name}
                            onChange={onCustomChangeHandler}
                            onBlur={formik.handleBlur}
                          />
                          {getFormErrorMessage("company_name")}
                        </div>
                        <div
                          className={`last-name-res ${
                            isFormFieldValid("job_title") && "error-input"
                          }`}
                        >
                          <label for="lname">Job Title</label>
                          <br />
                          <input
                            type="text"
                            placeholder="Job Title"
                            className="feild-stl-res form-font-f"
                            name="job_title"
                            value={formik.values.job_title}
                            onChange={onCustomChangeHandler}
                            onBlur={formik.handleBlur}
                          />
                          {getFormErrorMessage("job_title")}
                        </div>
                      </div>
                      <div className="resume-personal-form f-feild-res">
                        <div
                          className={`first-name-res ${
                            isFormFieldValid("city") && "error-input"
                          }`}
                        >
                          <label for="fname">City/State</label>
                          <br />
                          <input
                            type="text"
                            placeholder="City/State"
                            className="feild-stl-res form-font-f"
                            name="city"
                            value={formik.values.city}
                            onChange={onCustomChangeHandler}
                            onBlur={formik.handleBlur}
                          />
                          {getFormErrorMessage("city")}
                        </div>
                        <div
                          className={`last-name-res ${
                            isFormFieldValid("country") && "error-input"
                          }`}
                        >
                          <label for="lname">Country</label>
                          <br />
                          <input
                            type="text"
                            placeholder="Country"
                            className="feild-stl-res form-font-f"
                            name="country"
                            value={formik.values.country}
                            onChange={onCustomChangeHandler}
                            onBlur={formik.handleBlur}
                          />
                          {getFormErrorMessage("country")}
                        </div>
                      </div>

                      <div className="resume-personal-form f-feild-res">
                        <div className="first-name-res">
                          <label for="fname">Start Date</label>
                          <br />
                          <div className="date-range-date-end">
                            <div
                              className={`resume-right-f-date ${
                                isFormNFieldValid("start_date.month") &&
                                "error-input"
                              }`}
                            >
                              <div class="month">
                                <div
                                  id="fresume-year"
                                  class="company-form-dropdownBox-post"
                                  data-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                >
                                  {formik?.values?.start_date?.month !== ""
                                    ? formik?.values?.start_date?.month
                                    : "Month"}
                                  <div class="icon-hide-res">
                                    {isFormNFieldValid("start_date.month") ? (
                                      <img src="/image/warning.png" alt="" />
                                    ) : (
                                      <img
                                        src="/image/salary-year.png"
                                        alt=""
                                      />
                                    )}
                                  </div>
                                </div>
                                {monthDropDown?.length > 0 && (
                                  <div className="list-search-dropdown-month-resume">
                                    <ul className="list-job-title-year-res">
                                      {monthDropDown?.map((j, i) => (
                                        <li
                                          key={i}
                                          className="icon-cursor job-title-size"
                                          onClick={() => {
                                            onCustomDateClickHandler(
                                              "start_date.month",
                                              j?.label
                                            );
                                          }}
                                        >
                                          {j?.label}
                                        </li>
                                      ))}
                                    </ul>
                                  </div>
                                )}
                              </div>
                            </div>

                            <div
                              className={`resume-right-f-date ${
                                isFormNFieldValid("start_date.year") &&
                                "error-input"
                              }`}
                            >
                              <div class="Year-res-v">
                                <div
                                  id="fresume-year"
                                  class="company-form-dropdownBox-post"
                                  data-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                >
                                  {formik?.values?.start_date?.year !== ""
                                    ? formik?.values?.start_date?.year
                                    : "Year"}
                                  <div class="icon-hide-res">
                                    {isFormNFieldValid("start_date.year") ? (
                                      <img src="/image/warning.png" alt="" />
                                    ) : (
                                      <img
                                        src="/image/salary-year.png"
                                        alt=""
                                      />
                                    )}
                                  </div>
                                </div>

                                {yearDropDown?.length > 0 && (
                                  <div className="list-search-dropdown-year-resume">
                                    <ul className="list-job-title-year-res">
                                      {yearDropDown?.map((j, i) => (
                                        <li
                                          key={i}
                                          className="icon-cursor job-title-size"
                                          onClick={() => {
                                            onCustomDateClickHandler(
                                              "start_date.year",
                                              j?.label
                                            );
                                          }}
                                        >
                                          {j?.label}
                                        </li>
                                      ))}
                                    </ul>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                          {getFormNErrorMessage("start_date.month")
                            ? getFormNErrorMessage("start_date.month")
                            : getFormNErrorMessage("start_date.year")}
                        </div>
                        <div class="last-name-res">
                          <div class="end-date-res-ver">
                            <div class="labe-end-date">
                              <label for="lname">End Date</label>
                              <br />
                            </div>
                            <div class="resume-personal-form-chek">
                              <div class="last-name-res">
                                <div class="present-chek">
                                  <input
                                    className="icon-cursor"
                                    type="checkbox"
                                    name="is_present"
                                    id="job-res"
                                    checked={formik?.values?.is_present}
                                    onChange={(e) => {
                                      onCustomChangeHandler({
                                        target: {
                                          name: "is_present",
                                          value: e?.target?.checked,
                                        },
                                      });
                                    }}
                                    onBlur={formik.handleBlur}
                                  />
                                  Present
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="date-range-date-end">
                            <div
                              className={`resume-right-f-date ${
                                isFormNFieldValid("end_date.month") &&
                                "error-input"
                              }`}
                            >
                              <div class="month">
                                <div
                                  id="fresume-year"
                                  className={`company-form-dropdownBox-post " ${
                                    formik?.values?.is_present && "disabl-res-f"
                                  }`}
                                  data-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                >
                                  {formik?.values?.end_date?.month !== ""
                                    ? formik?.values?.end_date?.month
                                    : "Month"}
                                  <div class="icon-hide-res">
                                    {isFormNFieldValid("end_date.month") ? (
                                      <img src="/image/warning.png" alt="" />
                                    ) : (
                                      <img
                                        src="/image/salary-year.png"
                                        alt=""
                                      />
                                    )}
                                  </div>
                                </div>

                                {formik?.values?.is_present === false &&
                                  monthDropDown?.length > 0 && (
                                    <div className="list-search-dropdown-month-resume">
                                      <ul className="list-job-title-year-res">
                                        {monthDropDown?.map((j, i) => (
                                          <li
                                            key={i}
                                            className="icon-cursor job-title-size"
                                            onClick={() => {
                                              onCustomDateClickHandler(
                                                "end_date.month",
                                                j?.label
                                              );
                                            }}
                                          >
                                            {j?.label}
                                          </li>
                                        ))}
                                      </ul>
                                    </div>
                                  )}
                              </div>
                            </div>
                            <div
                              className={`resume-right-f-date ${
                                isFormNFieldValid("end_date.year") &&
                                "error-input"
                              }`}
                            >
                              <div class="Year-res-v">
                                <div
                                  id="fresume-year"
                                  className={`company-form-dropdownBox-post " ${
                                    formik?.values?.is_present && "disabl-res-f"
                                  }`}
                                  data-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                >
                                  {formik?.values?.end_date?.year !== ""
                                    ? formik?.values?.end_date?.year
                                    : "Year"}
                                  <div class="icon-hide-res">
                                    {isFormNFieldValid("end_date.year") ? (
                                      <img src="/image/warning.png" alt="" />
                                    ) : (
                                      <img
                                        src="/image/salary-year.png"
                                        alt=""
                                      />
                                    )}
                                  </div>
                                </div>
                                {formik?.values?.is_present === false &&
                                  yearDropDown?.length > 0 && (
                                    <div className="list-search-dropdown-year-resume">
                                      <ul className="list-job-title-year-res">
                                        {yearDropDown?.map((j, i) => (
                                          <li
                                            key={i}
                                            className="icon-cursor job-title-size"
                                            onClick={() => {
                                              onCustomDateClickHandler(
                                                "end_date.year",
                                                j?.label
                                              );
                                            }}
                                          >
                                            {j?.label}
                                          </li>
                                        ))}
                                      </ul>
                                    </div>
                                  )}
                              </div>
                            </div>
                          </div>
                          {getFormNErrorMessage("end_date.month")
                            ? getFormNErrorMessage("end_date.month")
                            : getFormNErrorMessage("end_date.year")}
                        </div>
                      </div>
                      <div
                        className={`address-res f-feild-res ${
                          isFormFieldValid("roles_n_responsibilities") &&
                          "error-input"
                        }`}
                      >
                        <div className="lbl-ai-mdl-btn">
                          <label for="lname">
                            {" "}
                            Description of Responsibilities
                          </label>
                          <br />
                          <a
                            href="#"
                            className="btn btn-black"
                            onClick={(e) => {
                              e.preventDefault(); 
                              const isDisabled =
                                suggestedResponsibilities?.length > 0 ||
                                loadingShowAISuggestion;
                              if (!isDisabled) {
                                setShowAISuggestion(true);
                                setLoadingShowAISuggestion(true);
                                regenerateResumeAI(formik?.values);
                              }
                            }}
                          >
                            AI Suggestions{" "}
                            <img src="/image/ai-suggestion-res.png" alt="" />
                          </a>
                        </div>
                        <ReactQuill
                          theme="snow"
                          className={`${isFormFieldValid(
                            "roles_n_responsibilities"
                          )} && "error-input"`}
                          placeholder="Type here"
                          value={formik?.values?.roles_n_responsibilities ?? ""}
                          name="roles_n_responsibilities"
                          modules={modules}
                          formats={formats}
                          onChange={(data) => {
                            formik.setFieldValue(
                              "roles_n_responsibilities",
                              data
                            );
                            formik.handleChange("roles_n_responsibilities");
                            const newFormData = {
                              ...formik?.values,
                              roles_n_responsibilities: data,
                            };
                            onExpChangeHandler(newFormData, index);
                          }}
                          onFocus={() => {
                            if (
                              formik?.values?.roles_n_responsibilities === ""
                            ) {
                              formik.setFieldValue(
                                "roles_n_responsibilities",
                                `<ul><li>${formik?.values?.roles_n_responsibilities}</li></ul>`
                              );
                            }
                          }}
                          onBlur={() => {
                            formik.handleBlur("roles_n_responsibilities");
                          }}
                        />
                        {getFormErrorMessage("roles_n_responsibilities")}
                        {showAISuggestion && (
                          <>
                            <div className="address-res f-feild-res">
                              <div className="btn-resume-magic">
                                <div className="resume-check-ai">
                                  <input
                                    type="checkbox"
                                    name="AI Suggestions"
                                    className="ai-suggestion-resume"
                                    checked={toggleAllSuggestion}
                                    onChange={(e) => {
                                      if (suggestedResponsibilities?.length > 0)
                                        handleAllCheckboxChange(e);
                                      setToggleAllSuggestion(
                                        !toggleAllSuggestion
                                      );
                                    }}
                                  />{" "}
                                
                                  <img
                                    src="/image/magic-stick-resume.png"
                                    alt="Image"
                                    className="img-fluid"                                
                                  />
                                </div>
                                <a
                                  href="#"
                                  className="btn btn-black"
                                  onClick={(e) => {
                                    e.preventDefault(); 
                                    setShowAISuggestion(true);
                                    setLoadingShowAISuggestion(true);
                                    regenerateResumeAI(formik?.values);
                                    setToggleAllSuggestion(false);
                                  }}
                                >
                                  {loadingShowAISuggestion ? (
                                    <div
                                     className="spinner-border"                                
                                      role="status"
                                      width={24}
                                      height={24}
                                    >
                                      <span className="visually-hidden">
                                        Loading...
                                      </span>
                                    </div>
                                  ) : (
                                    <img
                                      src="/image/reg-white.png"
                                      width={24}
                                      height={24}
                                    />
                                  )}
                                </a>
                              </div>

                              {suggestedResponsibilities?.length > 0 && (
                                <div className="check-box-resumme">
                                  {suggestedResponsibilities.map(
                                    (prod, index) => (
                                      <div
                                        key={index}
                                        className="check-box-f-res"
                                      >
                                        <input
                                          id={`checkbox_${index}`}
                                          type="checkbox"
                                          name="AI Suggestions"
                                          className="ai-resume-resume"
                                          checked={checkedResponsibilities?.includes(
                                            prod
                                          )}
                                          onChange={() =>
                                            handleCheckboxChange(index, prod)
                                          }
                                        />
                                        <label
                                          htmlFor={`checkbox_${index}`}
                                          className="checkbox-label-resume"
                                        >
                                          {prod}
                                        </label>
                                        <br />
                                      </div>
                                    )
                                  )}
                                </div>
                              )}
                            </div>
                            <div className="address-res f-feild-res">
                              <div className="two-can-sav-btn">
                                <button
                                  type="button"
                                  className="can-res-btn icon-cursor"
                                  onClick={() => {
                                    setShowAISuggestion(false);
                                    setSuggestedResponsibilities([]);
                                  }}
                                >
                                  Cancel
                                </button>
                                <button 
                                    className="save-res-btn icon-cursor"     
                                    type="button"
                                    onClick={()=> {
                                      formik.handleSubmit();
                                    }}
                                >
                                  {loader ? (
                                    <span
                                      className="spinner-border spinner-border-sm"
                                      role="status"
                                      aria-hidden="true"
                                    />
                                  ) : (
                                    "Append & Update"
                                  )}
                                </button>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </form>
                  </button>
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CanResumeExpForm;
