import React from 'react'
import { useSelector } from 'react-redux';

const PricingCardDemo = ({ item, index }) => {
  const { user } = useSelector(
    (state) => state.auth
  );
  return (
    <div className={`price-card card-demo ${item?.headerTitle ? 'with-header' : ''}`}>
      {
        item?.headerTitle ? <div className={`price-card-header ${item?.headerTitle === 'Premium spotlight' ? 'bg-white' : ''}`}>
        {
          item?.headerTitle === 'Premium spotlight' ? <img src='/image/rating.png' alt='rating' className='premiumRating' /> : ''
        }
        <p className="p-0">{item?.headerTitle}</p>
      </div> : ''
      }
      <div className="price-card-body">
        <div>
          <h3>{item.title}</h3>
          <div className="card-para mb-3">
            <p>{item.pricingDesc}</p>
          </div>
          <h2>${item.pricing}<small className='p-0 opacity-100'>{item.priveVal}</small></h2>
          {/* <h2><span>$</span>{item.pricing}<small>{item.priveVal}</small></h2> */}
          {/* <p className='pric-tit'><small>{item.pricingTitle}</small></p> */}
        </div>
        {/* item.buttonContent */}
        <button className="btn btn-primary-alternate text-none">{(user && index === 0) ? 'Current Plan' : (user && index === 3 || index === 2 ) ? "Buy now"  : 'Buy now'}</button>
        {/* <p>This includes:</p> */}
        <ul className='m-0'>
          {/* <p>{item.pricingHeading}</p> */}
          {item.pricingPoint.map((itm, ind) => {
            return (
              <div className='d-flex justify-items-center'>
                <li key={ind}>{itm}</li>
              </div>
             
            )
          })}
        </ul>
      </div>
    </div>
  )
}

export default PricingCardDemo