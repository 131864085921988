// import React, { useState } from 'react';
// import styled from 'styled-components';

// const RadioContainer = styled.div`
//   display: flex;
//   flex-wrap: wrap;
//   gap: 10px;
// `;

// const RadioButton = styled.label`
//   display: inline-block;
//   cursor: pointer;
//   font-size: 14px;
//   user-select: none;
//   transition: all 0.3s ease;
//   color: #2B2B2B;

//   &:hover {
//     border-color: #888;
//   }

//   input {
//     display: none;
//   }

//   input:checked + span {
//     border-color: #333;
//     background-color: #2b2b2b;
//     color: #fff;
//   }
// `;

// const RadioText = styled.span`
//   display: inline-block;
//   padding: 10px 20px;
//     border: 1px solid #2B2B2B;
//     border-radius: 100px;
// `;

// const RadioGroup = ({ options, name, getValue }) => {
//   const [selected, setSelected] = useState('');

//   const handleChange = (value) => {
//     setSelected(value.name);
//     if(getValue) {
//       getValue(value)
//     }
//   };

//   return (
//     <RadioContainer>
//       {options.map((option) => (
//         <RadioButton key={option.id}>
//           <input
//             type="radio"
//             name={option.name}
//             value={option?.name}
//             checked={selected === option.name}
//             onChange={() => handleChange(option)}
//           />
//           <RadioText>{option.name} +</RadioText>
//         </RadioButton>
//       ))}
//     </RadioContainer>
//   );
// };

// export default RadioGroup;
import React, { useState } from 'react';
import './RadioGroup.css';

const RadioGroup = ({ options, name, getValue }) => {
  const [selected, setSelected] = useState('');

  const handleChange = (value) => {
    setSelected(value.name);
    if (getValue) {
      getValue(value);
    }
  };

  return (
    <div className="radio-container">
      {options.map((option) => (
        <label key={option.id} className="radio-button">
          <input
            type="radio"
            name={option.name}
            value={option?.name}
            checked={selected === option.name}
            onChange={() => handleChange(option)}
          />
          <span className="radio-text">{option.name} +</span>
        </label>
      ))}
    </div>
  );
};

export default RadioGroup;
