import { useState } from "react";
import { Link } from "react-router-dom";
import JobRecordLeft from "../../Common/JobRecord/JobRecordLeft";
import JobRecordRight from "../../Common/JobRecord/JobRecordRight";
import JobNoRecordFound from "../../Common/JobNoRecordFound/JobNoRecordFound";
import JobCardMbl from "../../Common/JobCardMbl/JobCardMbl";

const SimilarJobs = ({ showMsg, similarJobs, onSearchChange }) => {
  const [count, setCount] = useState(10);
  const [openSearch, setOpenSearch] = useState(false);

  const countHandler = () => {
    if (count < similarJobs?.length) setCount((prevCount) => prevCount + 10);
  };

  if (showMsg === false && similarJobs?.length === 0) return null;

  return (
    <>
      {" "}
      <section className="new-job">
        <div className="job-heading">
          <div className="container container-site position-relative">
            <div className="smilar-job-heading">
              <h2>Similar jobs</h2>

              <div
                className={`job-search-div m-0 ${openSearch ? "job-searchBtn-open" : "job-search-div-mbl"
                  }`}
                onClick={() => {
                  setOpenSearch(true);
                }}
              >
                <img className="job-searchIcon job-searchIcon-mbl" src="/image/job-searchIcon.png" alt="job-searchBar" />
              </div>
            </div>



            <div
              className={`job-search-div ${openSearch ? "job-search-div-abs" : "job-search-open"
                }`}
            >
              <img src="/image/job-search.png" alt="" className="ms-1" />
              <label for='jon-search-box' className="visibility-hidden opacity-0 position-absolute">jon-search-box</label>
              <input
                type="text"
                name=""
                id="jon-search-box"
                placeholder="Search jobs"
                className="form-font-f"
                onChange={(e) => {
                  onSearchChange(e?.target?.value);
                }}
              />
            </div>
          </div>
        </div>
        <div className="container container-site mt-md-4 mt-3">
          {similarJobs?.length > 0 ? (
            similarJobs?.slice(0, count)?.map((prod, index) => (
              <>

                <div className="desktop-jobs ps-3">
                  <div className="Job-Opportunities-row">
                    <JobRecordLeft JobData={prod} index={index} />
                    <JobRecordRight
                      hashId={prod?.hashed_id}
                      slug={prod?.slug}
                      date={prod?.date}
                      selectedJobId={prod?.id}
                      jobCountry={prod?.country}
                      isApplied={prod?.is_applied}
                      wishList={prod?.whishlist}
                    />
                  </div>

                  <div className="divider-two"></div>

                </div>

                <div className="mbl-jobs py-1">


                  <JobCardMbl
                    hashId={prod?.hashed_id}
                    slug={prod?.slug}     
                    JobData={prod}
                    index={index}
                    date={prod?.date}
                    selectedJobId={prod?.id}
                    jobCountry={prod?.country}
                    isApplied={prod?.is_applied}
                    wishList={prod?.whishlist}
                  />
                </div>             
              </>
            ))
          ) : (
            <JobNoRecordFound />
          )}
        </div>
        {similarJobs?.length > 10 && (
          <div className="see-more-job" onClick={() => countHandler()}>
            <Link href="#">
              <button>More jobs</button>
            </Link>
          </div>
        )}
      </section>
    </>
  );
};

export default SimilarJobs;
