import { useEffect, useState, useRef } from "react";
import Ably from "ably";

const useAblyHook = (channelName) => {
  const [channel, setChannel] = useState(null);
  const [error, setError] = useState(null);
  const [isConnected, setIsConnected] = useState(false);
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const reconnectAttempts = useRef(0);
  const maxReconnectAttempts = 3; // Limit reconnection attempts
  const reconnectDelay = 5000; // Delay between reconnections in milliseconds

  const getAblyApiKey = () => {
    const url = window.location.href;
    if (url.includes("localhost")) {
      return process.env.REACT_APP_ABLY_API_KEY_QA;
    } else if (url.includes("dev")) {
      return process.env.REACT_APP_ABLY_API_KEY_DEV;
    } else if (url.includes("qa")) {
      return process.env.REACT_APP_ABLY_API_KEY_QA;
    } else if (url.includes("demo")) {
      return process.env.REACT_APP_ABLY_API_KEY_DEMO;
    }      
    else if (url.includes("polycareers")) {
      return process.env.REACT_APP_ABLY_API_KEY_PROD;  
    }  
    else {
      return process.env.REACT_APP_ABLY_API_KEY_QA;
    }
  };

  const connectionHandler = () => {
    const ABLY_API_KEY = getAblyApiKey();
    const ably = new Ably.Realtime({
      key: ABLY_API_KEY,
      log: { level: 0 },
      autoConnect: false, // Prevent auto-connect to control reconnection
      transports: ["web_socket"],
    });

    const ablyChannel = ably.channels.get(channelName);
    setChannel(ablyChannel);

    const handleError = (err) => {
      setError(err);
    };

    ably.connection.on("connected", () => {
      setIsConnected(true);
      reconnectAttempts.current = 0; // Reset attempts on successful connection
    });

    ably.connection.on("disconnected", () => {
      setIsConnected(false);
      if (reconnectAttempts.current < maxReconnectAttempts) {
        reconnectAttempts.current += 1;
        setTimeout(() => {
          if (isOnline) ably.connect(); // Retry connection
        }, reconnectDelay);
      } else {
        console.warn("Max reconnection attempts reached");
      }
    });

    ably.connection.on("suspended", () => {
      setIsConnected(false);
      console.warn("Connection suspended");
    });

    ably.connection.on("failed", (err) => {
      handleError(err);
      console.warn("Ably connection failed:", err.message);
    });

    ablyChannel.attach((err) => {
      if (err) {
        handleError(err);
      }
    });

    ably.connect(); // Initiate connection

    return () => {
      ablyChannel.detach((err) => {
        if (err) {
          console.warn("Channel detachment error:", err.message);
        }
      });
      ably.close();
    };
  };

  useEffect(() => {
    const handleOnline = () => {
      setIsOnline(true);
      reconnectAttempts.current = 0; // Reset attempts when back online
      connectionHandler();
    };

    const handleOffline = () => {
      setIsOnline(false);
      setIsConnected(false);
    };

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, [channelName]);

  useEffect(() => {
    if (isOnline) {
      connectionHandler();
    }
  }, [isOnline, channelName]);

  return { channel, error, isConnected };
};

export default useAblyHook;
