import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import API from "../../api";

const initialState = {
  activeTab: "candidate",
  openLoginModel: false,
  isEmailVerify: false,
  resumeDetail: {
    resumeId: null,
    resumeType: 1,
  },
  resumes: [],
  selectedCustomResume: {
    resumeId: null,
    resumeType: 2,
  },
  accessToken: "",
  tokenType: "Bearer",
  user: null,
  userName: "",
  userCountry: {
    id: null,
    name: "",
  },
  userOTPEmail:"",
  mode: "add",
  postJobId: null,
  favoriteJobCount: 0,
  inboxCount: 0,
  notifyCount: 0,
  expireTokenModel: false
};

const AuthSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    getLoginModel: (state) => {
      return state.openLoginModel;
    },
    setLoginModel: (state, action) => {
      state.openLoginModel = action.payload;
    },
    getActiveTab: (state) => {
      return state.activeTab;
    },
    setActiveTab: (state, action) => {
      state.activeTab = action.payload;
    },    
    setEmailVerify: (state, action) => {
      state.isEmailVerify = action.payload;
    },    
    setResumeDetail: (state, action) => {
      state.resumeDetail = action.payload;
    },
    setSelectedCustomResume: (state, action) => {
      state.selectedCustomResume = action.payload;
    },
    setLoginUser: (state, action) => {
      state.accessToken = action.payload?.access_token;
      state.user = action.payload?.user;
    },
    setLoginUserName: (state, action) => {
      state.userName = action.payload;
    },
    setLoginUserCountry: (state, action) => {
      state.userCountry = action.payload;
    },   
    getUserResumes: (state) => {
      return state.resumes;
    },
    setUserResumes: (state, action) => {
      state.resumes = action.payload;
    },
    setUserOTPEmail: (state, action) => {
      state.userOTPEmail = action.payload;
    },
    setUserSalary: (state, action) => {
      const { expected_salary, salary_range } = action.payload;
      state.user = {
        ...state.user,
        expected_salary,
        salary_range,
      };
    },
    setPostJobMode: (state, action) => {
      state.mode = action.payload;
    },
    setPostJobId: (state, action) => {
      state.postJobId = action.payload;
    },
    setFavoriteJobsCount: (state, action) => {
      state.favoriteJobCount = action.payload;
    },
    incrementFavoriteJobsCount: (state, action) => {
      state.favoriteJobCount = action.payload;
    },  
    setInboxCount: (state, action) => {
      state.inboxCount = action.payload;
    }, 
    setNotifyCount: (state, action) => {
      state.notifyCount = action.payload;
    },  
    setExpireTokenModel: (state, action) => {
      state.expireTokenModel = action.payload;
    }, 
    clearLoginUser: (state) => {
      state.accessToken = "";
      state.user = null;
      state.isEmailVerify = false;
      state.mode = "add";
      state.postJobId = null;
      state.inboxCount = 0;
      state.notifyCount = 0;
      state.resumes = [];
      state.userCountry = {
        id: null,
        name: "",
      };
      state.userOTPEmail = "";
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  openLoginModel,
  setLoginModel,
  getActiveTab,
  setActiveTab,
  setEmailVerify,
  setResumeDetail,
  setSelectedCustomResume,
  setLoginUser,
  setLoginUserName,
  setLoginUserCountry,
  setUserOTPEmail,
  setUserSalary,
  setPostJobMode,
  setPostJobId,
  setExpireTokenModel,
  clearLoginUser,
  setFavoriteJobsCount,
  incrementFavoriteJobsCount,
  setInboxCount,
  setNotifyCount,
  getUserResumes,
  setUserResumes,
} = AuthSlice.actions;

export default AuthSlice.reducer;

// asynchronous function with createAsyncThunk

export const resendVerifyEmailAsync = createAsyncThunk(
  "public/resendVerifyEmail",
  async ({ email }) => {
    const formData = new FormData();
    formData.append("email", email);
    try {
      const response = await API.post(
        "/auth/resend-verification-email",
        formData
      );
      if (response?.status === 200 || response?.status === 201) {
        return response?.data;
      } else {
        throw new Error("Unexpected response status");
      }
    } catch (error) {
      throw error;
    }
  }
);

export const getSearchSuggestionAsync = createAsyncThunk(
  "public/getSearchSuggestionAsync",
  async ({searchValue, searchType}) => {
    try {
      let params = ``;
      if (searchValue !== "") {
        params = `?query_key=${searchValue}&type=${searchType}`;
      }
      
      const response = await API.get(`/job-board/get-search-suggestions${params}`);

      if (response?.status === 200 || response?.status === 201) {
        return response?.data;
      } else {
        throw new Error("Unexpected response status");
      }
    } catch (error) {
      throw error;
    }
  }
);


export const getCountriesAsync = createAsyncThunk(
  "public/getCountries",
  async ({country_id}) => {
    try {
      let params = `?`;
      if (country_id !== null) {
        params += `country_id=${country_id}&`;
      }

      const response = await API.get(`/get-countries${params}`);

      if (response?.status === 200 || response?.status === 201) {
        return response?.data;
      } else {
        throw new Error("Unexpected response status");
      }
    } catch (error) {
      throw error;
    }
  }
);

export const getEmpCountriesAsync = createAsyncThunk(
  "public/getEmpCountries",
  async ({country_id}) => {
    try {
      let params = `?`;
      if (country_id !== null) {
        params += `country_id=${country_id}&`;
      }
      const response = await API.get(`/get-employer-country${params}`);

      if (response?.status === 200 || response?.status === 201) {
        return response?.data;
      } else {
        throw new Error("Unexpected response status");
      }
    } catch (error) {
      throw error;
    }
  }
);


export const getStateAsync = createAsyncThunk(
  "public/getState",
  async ({ id }) => {
    try {
      const response = await API.get(`/get-states?country_id=${id}`);

      if (response?.status === 200 || response?.status === 201) {
        return response?.data;
      } else {
        throw new Error("Unexpected response status");
      }
    } catch (error) {
      throw error;
    }
  }
);

export const getCitiesAsync = createAsyncThunk(
  "public/getcities",
  async ({ country_id, state_id }) => {
    let params = `?`;
    if (country_id !== null) {
      params += `country_id=${country_id}&`;
    }
    if (state_id !== null) {
      params += `state_id=${state_id}&`;
    }

    try {
      const response = await API.get(`/get-cities${params}`);

      if (response?.status === 200 || response?.status === 201) {
        return response?.data;
      } else {
        throw new Error("Unexpected response status");
      }
    } catch (error) {
      throw error;
    }
  }
);

export const getEmployerDropdownAsync = createAsyncThunk(
  "public/getEmployerDropdown",
  async () => {
    try {    
      const response = await API.get(`/auth/emp-signup-form/detail`);

      if (response?.status === 200 || response?.status === 201) {
        return response?.data;
      } else {
        throw new Error("Unexpected response status");
      }
    } catch (error) {
      throw error;
    }
  }
);


export const verifyUserOTPAsync = createAsyncThunk(
  "public/verifyUserOTP",
  async ({ email, otp, remember_device }) => {
    const formData = new FormData();
    email && formData.append("email", email);
    otp !== "" && formData.append("otp", otp);
    formData.append("remember_device", remember_device);
    
    try {
      const response = await API.post(`/auth/verify-otp`, formData);

      if (response?.status === 200 || response?.status === 201) {
        return response?.data;
      } else {
        throw new Error("Unexpected response status");
      }
    } catch (error) {
      throw error;
    }
  }
);

export const resendUserOTPAsync = createAsyncThunk(
  "public/resendUserOTP",
  async ({ email }) => {
    const formData = new FormData();
    formData.append("email", email);
    try {
      const response = await API.post(
        "/auth/resend-otp",
        formData
      );
      if (response?.status === 200 || response?.status === 201) {
        return response?.data;
      } else {
        throw new Error("Unexpected response status");
      }
    } catch (error) {
      throw error;
    }
  }
);

